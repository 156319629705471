import { AppEnv } from 'config';

export const environment = process.env.NODE_ENV;
export const SignatureSecret = process.env.REACT_APP_SIGNATURE_SECRET;
export const DeviceID = process.env.REACT_APP_DEVICE_ID;
export const FlwPubKey = process.env.REACT_APP_FLW_PUB_KEY;

export const ResponseStatus = {
  Success: 'success',
  Error: 'error',
};

export const AccountResolutionStatus = {
  CreateAccount: 'create_account',
  CreatePassword: 'create_password',
  Login: 'login',
  LoginWithoutOtp: 'login_without_forgot_password',
  CreateAccountWithoutOtp: 'create_account_and_skip_otp',
};

export const StorageKeys = {
  AppConfig: 'app_config',
  Learner: 'learner',
  Learners: 'learners',
  Tutor: 'tutor',
  AuthToken: 'auth_token',
  MockToken: 'mock_token',
  DeviceId: 'device_id',
};

export const ApiHeaders = {
  XCorrelationId: 'x-correlation-id',
  AuthToken: 'auth-token',
  Authorization: 'Authorization',
  DeviceId: 'device-uuid',
};

export const liveUrl = process.env.REACT_APP_LIVE_URL;

export const learnerAnalysisUrl = process.env.REACT_APP_LEARNING_ANALYSIS_URL;

export const sentryKey = process.env.REACT_APP_SENTRY_KEY;

export const videoButtonType = {
  library: 'library',
  lesson: 'lesson',
};

export const correct_answer_title = [
  'Well done!',
  'Cheers!',
  'Nice one!',
  'Great!',
  'Good job!',
  'Splendid!',
  'Awesome!',
  'Fabulous!',
  'Excellent!',
  'Nicely done!',
];

export const correct_answer_text = [
  'Keep working!',
  'Your potential is limitless.',
  'Greatness is in your DNA.',
  'Your performance is commendable.',
  'You’re getting better.',
  'Your development knows no ceiling.',
  'So far, so great.',
  'Outstanding work is done, cheers!',
  'No complaints at all.',
];

export const incorrect_answer_title = [
  'Do better!',
  'Try again!',
  'Work harder!',
  'Not quite!',
  'Hard luck!',
  'Go over it!',
  'Improve!',
  'Wrong!',
  'Think again!',
  'Work smarter!',
];

export const incorrect_answer_text = [
  'There’s room for improvement.',
  'Give it another go.',
  'Do not relent, back to work.',
  'Examine your errors, and try again.',
  'Apply yourself and you’ll get it.',
  'Pay more attention to errors.',
  'You can do better.',
  'Pay attention to detail.',
  'Try to spot your errors.',
  'Change your approach.',
];

export const remoteConfig = {
  debug_payment_scratch_card: { NG: true, GH: true, default: true },
  debug_payment_mobile_money_transfer: { NG: false, GH: true, default: false },
  debug_payment_option_okra: { NG: true, default: true },
  forced_update_countries: [
    { version: 43, country: 'NG' },
    { version: 41, country: 'GH' },
  ],
  debug_payment_option_mobile_money: { NG: false, GH: false, default: false },
  debug_payment_option_whatsapp: { NG: true, GH: true, default: true },
  debug_payment_option_call: { NG: true, GH: true, default: true },
  debug_payment_option_cash: { NG: true, GH: true, default: true },
  debug_payment_option_bank: { NG: true, GH: true, default: false },
  debug_payment_options_by_card: { NG: true, GH: true, default: true },
  debug_stream_with_internet_msg:
    'If you choose to continue,\nuLesson will stream your videos\nvia the internet.\nEach video is about 10 MB.',
};

export const ENV_DEV = 'development';
export const ENV_STAGING = 'staging';
export const ENV_UAT = 'uat';
export const ENV_PRODUCTION = 'production';

export const enableTrackingLibraries: boolean =
  environment === ENV_PRODUCTION && AppEnv?.toLowerCase() !== ENV_DEV;

export const LOCALE_NIGERA = 'NG';
export const LOCALE_GAMBIA = 'GM';

export const INVALID_OTP_ERROR_MESSAGE = 'Invalid OTP code. Check and try again.';
export const INVALID_PASSWORD_ERROR_MESSAGE = 'Invalid password or mobile number';
