export const TRACKING_LOGIN = 'ul_log_in';
export const TRACKING_LOGOUT = 'ul_log_out';

export const TRACKING_ACCOUNT_REGISTRATION = 'ul_account_registration';

export const TRACKING_CHAT_SESSION_STARTED = 'ul_chat_session_started';
export const TRACKING_CHAT_QUESTION_SENT = 'ul_chat_question_sent';
export const TRACKING_CHAT_SESSION_END = 'ul_chat_session_ended';

export const TRACKING_EXAM_PROGRESS = 'ul_exam_progress';
export const TRACKING_EXAM_STARTED = 'ul_exam_started';

export const TRACKING_QUEST_STARTED = 'ul_quest_started';
export const TRACKING_QUEST_PROGRESS = 'ul_quest_progress';

export const TRACKING_PRACTICE_QUESTION = 'ul_question';
export const TRACKING_PRACTICE_STARTED = 'ul_practice_started';
export const TRACKING_PRACTICE_PROGRESS = 'ul_practice_progress';
export const TRACKING_PRACTICE_TEST_STARTED = 'ul_test_started';
export const TRACKING_PRACTICE_TEST_PROGRESS = 'ul_test_progress';

export const TRACKING_VIDEO_CAP_REACHED = 'ul_video_cap_reached';

export const TRACKING_PARENT_PHONE_NUMBER_SAVE = 'ul_parent_phone_number';

export const TRACKING_NAV_HOMEWORK_HELP = 'ul_homework_help';

export const TRACKING_CLASS_DATA_FETCH = 'ul_class';

export const TRACKING_LESSON_SEARCH = 'ul_lesson_search';
export const TRACKING_LESSON_START = 'ul_lesson_started';
export const TRACKING_LESSON_PROGRESS = 'ul_lesson_progress';
