import * as actionType from './types';
import firebase from 'firebase/app';
import 'firebase/remote-config';
import axiosInstance from '../Utils/axios-orders';
import { storeItem } from '../Functions';
import * as constant from '../Utils/constants';
import { captureException } from 'Utils/sentry';
const axios = axiosInstance();

const configPulled = ({ data, uuid }: { data: any; uuid: string }) => {
  return {
    type: actionType.CONFIG_PULLED,
    payload: {
      config: data,
      uuid,
    },
  };
};

export const getConfig = (deviceId: string) => async (dispatch: Function) => {
  axios.defaults.headers['device-uuid'] = deviceId;
  axios
    .get(`appconfig`)
    .then(response => {
      dispatch(configPulled({ data: response?.data?.data, uuid: deviceId }));
      storeItem(constant.StorageKeys.AppConfig, response.data.data);
    })
    .catch(error => captureException(error));
};

export const addBadges = (badges: Array<any>) => (dispatch: Function) => {
  dispatch({
    type: actionType.ADD_BADGES,
    payload: badges,
  });
};

export const getRemoteConfig = () => (dispatch: any) => {
  const rc = firebase.remoteConfig();
  rc.settings.minimumFetchIntervalMillis = 10000;
  rc.activate().then(async a => {
    await firebase.remoteConfig().fetch();
    const remoteConfig: any = rc.getAll();
    if (!remoteConfig) return;

    remoteConfig.getValue = (value: string, defaultValue?: any) => {
      try {
        const internalValue = remoteConfig?.[value]?._value;
        if (!internalValue) return defaultValue;
        return JSON.parse(internalValue);
      } catch (error) {
        return defaultValue;
      }
    };

    dispatch({
      type: actionType.REMOTE_CONFIG,
      payload: remoteConfig,
    });
  });
};
