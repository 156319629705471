import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import axios from 'axios';
import { deviceDetect } from 'react-device-detect';
import * as urls from 'Navigation/UrlStore.json';

const device = deviceDetect();

const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_KEY
  ? process.env.REACT_APP_SEGMENT_KEY
  : 'hErh4DE8P8jAIDbACyAn8l2xiaF3pt9s';

let location: any = {};
axios.get('https://geolocation-db.com/json/').then(res => {
  if (res.status === 200) {
    const data = res.data;
    location.latitude = data.latitude;
    location.longitude = data.longitude;
    location.country = data.country_name;
    location.city = data.city;
    location.ip_address = data.IPv4;
    location.state = data.state;
  }
});

export const segmentAnalytics = Analytics({
  app: 'uLesson Web App',
  plugins: [
    segmentPlugin({
      writeKey: SEGMENT_WRITE_KEY,
    }),
  ],
});

type AuthLearner = {
  id: number;
  firstname: string;
  lastname: string;
  gender: 'M' | 'F' | undefined;
  email: string;
  mobile: string;
  school_type: string;
  created_at: Date;
  country: {
    name: string;
  };
  active_subscription: {
    plan_name: string;
  };
  grade: {
    id: number;
  };
  dialing_code: string;
  subscriptions: Array<any>;
};
export const mapLarnerForTracking = (authLearner: AuthLearner) => {
  if (!authLearner?.id) return {};

  return {
    learner_id: authLearner?.id,
    first_name: authLearner?.firstname,
    last_name: authLearner?.lastname,
    gender: authLearner?.gender,
    phone_number: authLearner?.mobile,
    email_address: authLearner?.email,
    email: authLearner?.email,
    school_type: authLearner?.school_type,
    registration_date: authLearner?.created_at,
    cummulative_subscriptions:
      authLearner?.subscriptions?.filter((sub: any) => !sub?.free_subscription)?.length ?? 0,
    grade_id: authLearner?.grade?.id,
    country: authLearner?.country?.name,
    subscription_status: authLearner?.active_subscription?.plan_name,
    platform: 'Web',
  };
};

export const mapLearnerForCleverTap = (authLearner: AuthLearner) => {
  if (!authLearner?.id) return {};

  return {
    Name: `${authLearner?.firstname} ${authLearner?.lastname}`,
    Identity: authLearner?.id,
    Email: authLearner?.email,
    Phone: `+${authLearner?.dialing_code}${authLearner?.mobile}`,
    Gender: authLearner?.gender,

    'MSG-email': true,
    'MSG-push': true,
    'MSG-sms': true,
    'MSG-whatsapp': true,
  };
};

export const trackSegmentEvent = (eventName: string, payload?: any, learnerOverride?: any) => {
  const learner = JSON.parse(localStorage.getItem('learner') as string);
  const payloadData = !!payload ? payload : {};
  const data: any = { location, device };

  if (learner) data.learner = mapLarnerForTracking(learner);
  if (learnerOverride) data.learner = mapLarnerForTracking(learnerOverride);

  segmentAnalytics.track(eventName, payloadData, data).then(e => {});
};

export const trackIdentifyUser = async (learner: any) => {
  segmentAnalytics.identify(learner?.id?.toString(), mapLarnerForTracking(learner));

  if (typeof jest === 'undefined') {
    const { clevertap }: any = await import('./clevertap');
    clevertap.onUserLogin.push({
      Site: mapLearnerForCleverTap(learner),
    });
  }
};

export const trackPageView = (pageData: { url: string; title: string }) => {
  const learner = JSON.parse(localStorage.getItem('learner') as string) ?? {};
  segmentAnalytics.page(pageData, {
    learner: mapLarnerForTracking(learner),
    location,
    device,
    page_id: pageData?.url,
    page_name: pageData?.title,
  });
};

export const logoutSegmentUser = () => {
  segmentAnalytics.reset();
};

export const getPageTitle = (pathname: string) => {
  const allUrls = (urls as any).default;
  let pageName = '';

  Object.keys(allUrls).forEach(entry => {
    const entryValue = allUrls[entry];
    if (entryValue === pathname) {
      pageName = entry;
    }
  });

  const title = pageName?.replace(/([A-Z]+)/g, ' $1')?.replace(/^ /, '');
  return title ?? '';
};
