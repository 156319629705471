import {
  SEARCH_QUEST,
  CLEAR_CHAPTER_STATE,
  SET_REVISON_VIDEOS,
  SELECT_REVISION,
  TOGGLE_CHAPTER_TIMER,
} from './../Actions/chapter/actionTypes';
import { CLICK_TAB, SELECT_CHAPTER, SELECT_LESSON } from 'Actions/chapter/actionTypes';
import { IChapter, ILesson, IQuest } from 'Types/content';

export interface IChapterReducer {
  selectedChapter: IChapter | null;
  selectedIndex: number;
  selectedTabIndex: number;
  selectedLesson: ILesson | null;
  selectedQuest: IQuest | null;
  searchedQuest: IQuest | null;
  isQuestSearched: boolean;
  revisionVideos: ILesson[] | null;
  revisionSelected: boolean;
  chapterTimerIsVisible: boolean;
}

interface Action {
  type: string;
  payload: IChapterReducer;
}

const initialState: IChapterReducer = {
  selectedChapter: null,
  selectedIndex: 0,
  selectedTabIndex: 0,
  selectedLesson: null,
  selectedQuest: null,
  searchedQuest: null,
  isQuestSearched: false,
  revisionVideos: null,
  revisionSelected: false,
  chapterTimerIsVisible: false,
};

const chapterReducer = (state = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case SELECT_CHAPTER: {
      return {
        ...state,
        selectedChapter: payload.selectedChapter,
        selectedIndex: payload.selectedIndex,
        isQuestSearched: false,
        searchedQuest: null,
        revisionVideos: null,
        revisionSelected: false,
      };
    }
    case TOGGLE_CHAPTER_TIMER: {
      return {
        ...state,
        chapterTimerIsVisible: !state.chapterTimerIsVisible,
      };
    }
    case SELECT_LESSON: {
      return {
        ...state,
        selectedLesson: payload.selectedLesson,
        selectedQuest: payload.selectedQuest,
        revisionVideos: null,
      };
    }
    case SELECT_REVISION: {
      return {
        ...state,
        revisionSelected: true,
      };
    }
    case CLICK_TAB: {
      return {
        ...state,
        selectedTabIndex: payload.selectedTabIndex,
      };
    }
    case SEARCH_QUEST: {
      return {
        ...state,
        searchedQuest: payload.searchedQuest,
        isQuestSearched: true,
      };
    }
    case CLEAR_CHAPTER_STATE: {
      return {
        ...initialState,
      };
    }
    case SET_REVISON_VIDEOS: {
      return {
        ...state,
        revisionVideos: payload.revisionVideos,
      };
    }
    default:
      return state;
  }
};

export default chapterReducer;
