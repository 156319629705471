import * as actionTypes from 'Actions/theme/actionTypes';

interface Action {
  type: string;
  payload: any;
}

const initialState = {};

const themeReducer = (state = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_THEME: {
      return {
        ...payload,
      };
    }
    default:
      return state;
  }
};

export default themeReducer;
