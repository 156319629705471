import lunr from 'lunr';

export const indexDocuments = async (lessons: any, quests: any) => {
  const searchIndex = lunr(function (this: any) {
    this.ref('uniqueId');
    this.field('name');
    this.field('subjectId');
    const documents = [...lessons, ...quests];
    documents.forEach(function (this: any, { name, uniqueId, subjectId, type }) {
      this.add({ name, subjectId, uniqueId, type });
    }, this);
  });
  return JSON.stringify(searchIndex);
};

export const makePaschal = (string: string) => {
  return string.charAt(0).toUpperCase() + string.substring(1, string.length);
};
