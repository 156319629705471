import * as actionTypes from 'Actions/chat/actionTypes';

interface Action {
  type: string;
  payload: any;
}

interface IinitialState {
  loading: boolean;
  questions: Array<any>;
  joinModal: boolean;
  allLessons: Array<any>;
  liveSubjects: Array<any>;
  mySubjects: Array<any>;
  myLessons: Array<any>;
  promotedLessons: Array<any>;
  remindMeModalDetails: any;
  selectedLiveLesson: any;
  limitLiveLesson: boolean;
  joinNowModal: any;
  filterAllLessons: Array<any>;
  filterMyLessons: Array<any>;
  notifications: Array<any>;
  unread_count: number;
  current_page: number;
  last_page: number;
  codingSchoolOpen: boolean;
}

const initialState: IinitialState = {
  loading: false,
  questions: [],
  joinModal: false,
  allLessons: [],
  liveSubjects: [{ name: 'ALL SUBJECTS' }],
  mySubjects: [{ name: 'ALL SUBJECTS' }],
  myLessons: [],
  promotedLessons: [],
  remindMeModalDetails: null,
  selectedLiveLesson: null,
  limitLiveLesson: false,
  joinNowModal: null,
  filterAllLessons: [],
  filterMyLessons: [],
  notifications: [],
  unread_count: 0,
  current_page: 1,
  last_page: 1,
  codingSchoolOpen: false,
};

export default function (state = initialState, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.CHAT_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.CHAT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case actionTypes.GET_ALL_QUESTIONS: {
      return {
        ...state,
        questions: payload,
      };
    }
    case actionTypes.TOGGLE_JOIN_MODAL: {
      return {
        ...state,
        joinModal: payload,
      };
    }
    case actionTypes.GET_LIVE_LESSONS: {
      return {
        ...state,
        ...payload,
      };
    }
    case actionTypes.GET_MY_LESSONS: {
      return {
        ...state,
        ...payload,
      };
    }
    case actionTypes.GET_PROMOTED_LESSONS: {
      return {
        ...state,
        promotedLessons: payload,
      };
    }
    case actionTypes.TOGGLE_REMIND_MODAL: {
      return {
        ...state,
        remindMeModalDetails: payload,
      };
    }
    case actionTypes.SELECT_LIVE_LESSON: {
      return {
        ...state,
        loading: false,
        selectedLiveLesson: payload,
      };
    }
    case actionTypes.TOGGLE_LIMIT_LIVE_LESSON: {
      return {
        ...state,
        limitLiveLesson: !state.limitLiveLesson,
      };
    }
    case actionTypes.SET_JOIN_NOW: {
      return {
        ...state,
        joinNowModal: payload,
      };
    }
    case actionTypes.GET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: payload.notifications,
        unread_count: payload.unread_count,
        current_page: payload.current_page,
        last_page: payload.last_page,
      };
    }
    case actionTypes.GET_NOTIFICATIONS_PAGE: {
      return {
        ...state,
        notifications: payload.notifications,
        current_page: payload.current_page,
        last_page: payload.last_page,
      };
    }
    case actionTypes.SET_CODING_SCHOOL_MODAL: {
      return {
        ...state,
        codingSchoolOpen: payload,
      };
    }
    case actionTypes.LOG_OUT: {
      return {
        ...state,
        unread_count: 0,
      };
    }
    default:
      return state;
  }
}
