import React, { lazy, Suspense } from 'react';
import { View, ActivityIndicator, Dimensions } from 'react-native';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/messaging';
import {
  verifyToken,
  fetchGradeContent,
  setDimension,
  logOut,
  getConfig,
  getRemoteConfig,
  setJoinNowModal,
} from 'Actions';
import { RootState } from 'Reducers';
import * as urls from './UrlStore.json';
import { retrieveItem } from 'Functions';
import { colors } from 'Styles/abstracts/Colors';
import BrowserIncompatible from 'Components/Modals/BrowserIncompatible';
import LearningAnalysis from 'Pages/LearningAnalysis';
import { Feature } from 'flagged';
import { captureException } from 'Utils/sentry';
import { logMessage } from 'Utils/logger';
import { promptUser } from 'Utils/tracking';
import { FEATURE_FLAG_IDENTITY } from 'Config/FeatureFlags/constants';
import Anonymous from 'Layouts/Anonymous';

const SplashScreen = lazy(() => import('Pages/SplashScreen'));
const Phone = lazy(() => import('Pages/Phone'));
const PhoneRewrite = lazy(() => import('Components/Rewrite/Identity/Phone'));
const Login = lazy(() => import('Components/Login'));
const ConfirmOTP = lazy(() => import('Components/Login/ConfirmOTP'));
const CreateAccount = lazy(() => import('Pages/CreateProfile'));
// const CreateProfile = lazy(() => import('Pages/CreateProfile'));
const ResetPassword = lazy(() => import('Components/Login/ResetPassword'));
const ListChapters = lazy(() => import('Components/Subjects/ListChapters'));
const LessonList = lazy(() => import('Components/Subjects/LessonList'));
const Video = lazy(() => import('Pages/Video'));
const Contact = lazy(() => import('Pages/Contact'));
const Home = lazy(() => import('Pages/Home'));
const Profile = lazy(() => import('Pages/Profile'));
const EditProfile = lazy(() => import('Pages/EditProfile'));
const EditAvatar = lazy(() => import('Pages/EditAvatar'));
const Packages = lazy(() => import('Pages/Subscription/Packages'));
const PayMethod = lazy(() => import('Pages/Subscription/PayMethod'));
const BuyALearningPlan = lazy(() => import('Pages/Subscription/BuyALearningPlan'));
const SubscriptionDashboard = lazy(() => import('Pages/Subscription/Dashboard'));
const PayerDetails = lazy(() => import('Pages/Subscription/PayerDetails'));
const ScratchCard = lazy(() => import('Pages/Subscription/ScratchCard'));
const MomoSuccessful = lazy(() => import('Pages/Subscription/MomoSuccessful'));
const BankSuccessful = lazy(() => import('Pages/Subscription/BankSuccessful'));
const Successful = lazy(() => import('Pages/Subscription/Successful'));
const Failed = lazy(() => import('Pages/Subscription/Failed'));
const One2OneQuestion = lazy(() => import('Pages/OneToOne/Question'));
const One2OneChats = lazy(() => import('Pages/OneToOne/Chats'));
const PracticeTest = lazy(() => import('Pages/PracticeTest/Entry'));
const TestInstructions = lazy(() => import('Pages/PracticeTest/Instructions'));
const TestQuestions = lazy(() => import('Pages/PracticeTest/Questions'));
const Dashboard = lazy(() => import('Layouts/Dashboard'));
const CompleteQuest = lazy(() => import('Components/Subjects/CompleteQuest'));
const LessonQuiz = lazy(() => import('Components/Assessment/LessonQuiz'));
const RecentlyWatchedVideos = lazy(() => import('Components/Subjects/RecentlyWatchedVideos'));
const PracticeExams = lazy(() => import('Pages/PracticeExams/Entry'));
const ExamInstructions = lazy(() => import('Pages/PracticeExams/Instructions'));
const QuizSuccess = lazy(() => import('Components/Subjects/CompleteQuest/QuizSuccess'));
const Search = lazy(() => import('Pages/Search'));
const PracticeQuestions = lazy(() => import('Components/Assessment/PracticeQuestions'));
const PracticePreview = lazy(() => import('Components/Assessment/PracticePreview'));
const PracticeTests = lazy(() => import('Components/Assessment/PracticeTests'));
const Statistics = lazy(() => import('Pages/Statistics'));
const Highlights = lazy(() => import('Components/Assessment/Highlights'));
const AllLessons = lazy(() => import('Pages/LiveLessons/All'));
const MyLessons = lazy(() => import('Pages/LiveLessons/Me'));
const Live = lazy(() => import('Pages/LiveLessons/Live'));
const BadgeShowCase = lazy(() => import('Pages/Badge'));
const IQ = lazy(() => import('Pages/IQ'));
const NotFound = lazy(() => import('Pages/404'));
const Challenge = lazy(() => import('Pages/Challenge'));
const Notifications = lazy(() => import('Pages/Notifications'));
const PaymentWebView = lazy(() => import('Components/PaymentWebView'));
const MassTesting = lazy(() => import('Pages/MassTesting'));

declare global {
  interface Window {
    MSStream: any;
  }
}

interface RouteProps {
  authToken: string;
  isAuthenticated: boolean;
  setDimension: Function;
  isPremium: boolean;
  theme: any;
  verifyToken: () => any;
  fetchGradeContent: (a: string) => Promise<boolean>;
  logOut: () => void;
  getConfig: (a: string) => void;
  getRemoteConfig: () => void;
  setJoinNowModal: (d: any) => any;
}

interface RouteState {
  menuOpen: boolean;
  isBrowserIncompatibleOpen: boolean;
}

class Routes extends React.PureComponent<RouteProps, RouteState> {
  state = {
    menuOpen: false,
    isBrowserIncompatibleOpen: false,
  };

  async componentDidMount() {
    const { verifyToken, getConfig, logOut, getRemoteConfig } = this.props;
    const skipLogout = window.location?.pathname === '/phone';
    this.detectSafari();
    getRemoteConfig();

    firebase.messaging().onMessage(async (data: any) => {
      try {
        logMessage('FIREBASE_NOTIFICATIONS', data);
        this.props.setJoinNowModal(JSON.parse(data.data.live_lesson));
      } catch (error) {
        captureException(error);
      }
    });

    Dimensions.addEventListener('change', this.setDimension);
    const { verified, learner } = await verifyToken();

    if ((!verified || !learner?.grade?.id) && !skipLogout) {
      logOut();
    }

    const deviceId = await retrieveItem('device_id');
    if (deviceId) {
      getConfig(deviceId);
    }

    promptUser();
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', this.setDimension);
  }
  setDimension = (e: any) => {
    this.props.setDimension(e);
  };
  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  detectSafari = () => {
    // eslint-disable-next-line no-useless-escape
    const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (isSafari && !iOS) this.setState({ isBrowserIncompatibleOpen: true });
  };

  render() {
    const { menuOpen, isBrowserIncompatibleOpen } = this.state;
    const { authToken, isAuthenticated, isPremium, theme } = this.props;
    return (
      <Suspense
        fallback={
          <View
            style={{
              flex: 1,
              backgroundColor: colors.lightGray,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <ActivityIndicator
              color={theme && theme.color ? theme.color : colors.darkGray}
              size={80}
            />
          </View>
        }>
        {authToken && authToken !== '' && isAuthenticated ? (
          <>
            <Dashboard menuOpen={menuOpen} toggleMenu={this.toggleMenu}>
              <Switch>
                <Route
                  path={urls.Home}
                  render={(props: any) => <Home {...props} toggleMenu={this.toggleMenu} />}
                />
                <Route exact path={urls.Profile} component={Profile} />
                <Route exact path={urls.EditProfile} component={EditProfile} />
                <Route exact path={urls.EditAvatar} component={EditAvatar} />
                <Route path={urls.Chapters} component={ListChapters} />
                <Route path={urls.LessonListPage} component={LessonList} />
                <Route path={urls.VideoPageUrl} component={Video} />
                <Route path={urls.Contact} component={Contact} />
                <Route path={urls.Tests} component={PracticeTest} />
                <Route path={urls.TestInstructions} component={TestInstructions} />
                <Route path={urls.TestQuestions} component={TestQuestions} />
                <Route exact path={urls.CompleteQuest} component={CompleteQuest} />
                <Route exact path={urls.LessonsQuiz} component={LessonQuiz} />
                <Route exact path={urls.TestRecentlyWatchedUrl} component={RecentlyWatchedVideos} />
                <Route path={urls.PracticeExams} component={PracticeExams} />
                <Route path={urls.QuizSuccess} component={QuizSuccess} />
                <Route path={urls.Search} component={Search} />
                <Route exact path={urls.SelectedPractice} component={ExamInstructions} />
                <Route exact path={urls.Questions} component={PracticeQuestions} />
                <Route exact path={urls.PracticePreview} component={PracticePreview} />
                <Route exact path={urls.PracticeTests} component={PracticeTests} />
                <Route exact path={urls.Statistics} component={Statistics} />
                <Route exact path={urls.Highlight} component={Highlights} />
                <Route exact path={urls.LearningAnalysis} component={LearningAnalysis} />
                <Route exact path={urls.BadgeShowCase} component={BadgeShowCase} />
                <Route exact path={urls.IQ} component={IQ} />
                <Route
                  exact
                  path={urls.SubscriptionPackages}
                  component={!isPremium ? Packages : NotFound}
                />
                <Route
                  exact
                  path={urls.SubscriptionBuyALearningPlan}
                  component={!isPremium ? BuyALearningPlan : NotFound}
                />
                <Route
                  exact
                  path={urls.SubscriptionMethod}
                  component={!isPremium ? PayMethod : NotFound}
                />
                <Route
                  exact
                  path={urls.SubscriptionDetails}
                  component={!isPremium ? PayerDetails : NotFound}
                />
                <Route
                  exact
                  path={urls.SubscriptionScratchCard}
                  component={!isPremium ? ScratchCard : NotFound}
                />
                <Route
                  exact
                  path={urls.SubscriptionMomoSuccessful}
                  component={!isPremium ? MomoSuccessful : NotFound}
                />
                <Route
                  exact
                  path={urls.SubscriptionBankSuccessful}
                  component={!isPremium ? BankSuccessful : NotFound}
                />
                <Route exact path={urls.SubscriptionSuccessful} component={Successful} />
                <Route exact path={urls.SubscriptionFailed} component={Failed} />
                <Route exact path={urls.SubscriptionDashboard} component={SubscriptionDashboard} />
                {/* <Route exact path={urls.OneToOneQuestion} component={One2OneQuestion} /> */}
                {/* <Route exact path={urls.OneToOneChats} component={One2OneChats} /> */}
                <Route exact path={urls.AllLiveLessons} component={AllLessons} />
                <Route exact path={urls.MyLiveLessons} component={MyLessons} />
                <Route exact path={urls.Live} component={Live} />
                <Redirect path={urls.LiveLessons} to={urls.AllLiveLessons} />
                <Route exact path={urls.Challenge} component={Challenge} />
                <Route exact path={urls.Notifications} component={Notifications} />
                <Route exact path={urls.PaymentWebView} component={PaymentWebView} />
                <Route exact path={urls.MassTesting} component={MassTesting} />
                <Redirect exact path={urls.Login} to={urls.Home} />
                <Redirect exact path={urls.CreateAccount} to={urls.Home} />
                <Redirect exact path={urls.Root} to={urls.Home} />
                <Route component={NotFound} />
              </Switch>
            </Dashboard>
            <BrowserIncompatible isOpen={isBrowserIncompatibleOpen} />
          </>
        ) : (
          <Anonymous>
            <Switch>
              <Route exact path={urls.Root} component={SplashScreen} />

              <Route exact path={urls.Phone} component={Phone} />
              <Route exact path={urls.Login} component={Login} />
              <Route exact path={urls.ConfirmOTP} component={ConfirmOTP} />
              <Route exact path={urls.CreateAccount} component={CreateAccount} />
              <Route exact path={urls.ResetPassword} component={ResetPassword} />
              <Redirect to={urls.Phone} />
            </Switch>
          </Anonymous>
        )}
      </Suspense>
    );
  }
}

const mapStateToProps = ({ auth, theme }: RootState) => ({
  authToken: auth.authToken,
  isAuthenticated: auth.isAuthenticated,
  isPremium: auth.learner.premium,
  theme,
});

export default connect(mapStateToProps, {
  setDimension,
  verifyToken,
  fetchGradeContent,
  logOut,
  getConfig,
  getRemoteConfig,
  setJoinNowModal,
})(Routes);
