import * as actionTypes from 'Actions/subscribe/actionTypes';
import { planInterface } from 'Pages/Subscription/Packages';
import { learnerInterface } from 'Types/general';

interface Action {
  type: string;
  payload: any;
}

export interface ISubscription {
  id: number;
  plan_id: number;
  plan_name: string;
  grade_group_id: number;
  learner_id: number;
  preferred_delivery_day: Date | null;
  deliver_to_fullname: string | null;
  deliver_to_phone: string | null;
  delivery_instruction: string | null;
  shipping_fee: string;
  taxes: string;
  total: string;
  currency: string;
  start_at: Date;
  end_at: Date;
  coupon_code?: string;
  duration_in_days: number;
  amount_usd: string;
  amount_ngn: string;
  amount_ghs: string;
  activated_at: Date | null;
  free_subscription: boolean;
  transactions: [];
  expired: boolean;
  status: string;
  sd_cards: [];
  streaming_only: boolean;
  token: string;
  auto_renew: boolean;
  discount_meta: string | null;
  instalment_id: number | null;
}

export interface IPayment {
  agent_id: number | null;
  amount: string;
  currency: string;
  email: string;
  id: number | null;
  instalment: [];
  learner_id: number;
  metadata: {
    agent_id: number | null;
    app_env: string;
    dialing_code: string;
    grade_group_id: number;
    grade_id: number;
    instalment_id: number | null;
    learner_id: number;
    phone_number: string;
    subscription_id: number;
  };
  payment_gateway: string;
  payment_mode: string;
  reference: string;
  status: string;
  subscription_id: number;
  telesales_agent_id: number | null;
}

export interface IMomoTransfer {
  account_name: string;
  account_number: string;
  created_at: string;
  dialing_code: string;
  email: string;
  display_agent_label: boolean;
  id: number;
  mobile: string;
  provider: string;
  details: {
    account_name: string;
    account_number: string;
    provider: string;
  }[];
  reference: string;
  updated_at: string;
}

export interface IBankTransfer {
  banks: {
    account_name: string;
    account_number: string;
    bank_name: string;
    country_code: string;
    created_at: Date;
    id: number;
    position: number;
    updated_at: Date;
  }[];
  email: string | null;
  id: number;
  learner: learnerInterface;
  mobile: string;
  subscription: ISubscription;
  created_at: Date;
  updated_at: Date;
}

interface IinitialState {
  countryId: number;
  gradeGroupId: number;
  gradeId: number;
  plans: planInterface[];
  selectedPlan?: planInterface;
  autorenew: boolean;
  mode: string;
  subscription?: ISubscription;
  payment?: IPayment;
  momoTransfer?: IMomoTransfer;
  bankTransfer?: IBankTransfer;
  paymentComplete: boolean;
  scratchLoading?: string;
}

const initialState: IinitialState = {
  countryId: -99,
  gradeGroupId: -99,
  gradeId: -99,
  plans: [],
  autorenew: false,
  mode: '',
  paymentComplete: false,
  scratchLoading: '',
};

const subscribeReducers = (state = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_GRADE:
      return {
        ...state,
        countryId: payload.countryId,
        gradeGroupId: payload.gradeGroupId,
        gradeId: payload.gradeId,
      };
    case actionTypes.ALL_PLANS:
      return {
        ...state,
        plans: payload,
      };
    case actionTypes.SELECT_PLAN:
      return {
        ...state,
        selectedPlan: payload.plan,
        autorenew: payload.autorenew,
      };
    case actionTypes.SELECT_PAYMENT_MODE:
      return {
        ...state,
        mode: payload.mode,
        subscription: payload.subscription,
      };
    case actionTypes.INITIALIZE_PAYMENT:
      return {
        ...state,
        payment: payload,
      };
    case actionTypes.COMPLETE_PAYMENT:
      return {
        ...state,
        paymentComplete: true,
      };
    case actionTypes.SCRATCH_CARD_PAYMENT:
      return {
        ...state,
        paymentComplete: true,
      };
    case actionTypes.MOMO_TRANSFER:
      return {
        ...state,
        momoTransfer: payload,
      };
    case actionTypes.BANK_TRANSFER:
      return {
        ...state,
        bankTransfer: payload,
      };
    case actionTypes.SET_SCRATCH_LOADING: {
      return {
        ...state,
        scratchLoading: payload,
      };
    }
    case actionTypes.LOG_OUT:
      return {
        countryId: -99,
        gradeGroupId: -99,
        gradeId: -99,
        plans: [],
        autorenew: false,
        mode: '',
        paymentComplete: false,
      };
    default:
      return state;
  }
};

export default subscribeReducers;
