import { enableTrackingLibraries } from 'Utils/constants';
import {
  TRACKING_CHAT_QUESTION_SENT,
  TRACKING_CHAT_SESSION_END,
  TRACKING_CHAT_SESSION_STARTED,
} from './constants';
import { trackFirebaseEvent } from './firebase';
import { trackReactPixelEvent } from './reactPixel';
import { TrackTutorChatTypes } from './types';

export const trackChatStart = (): void => {};

export const trackChatSessionStarted = (): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_CHAT_SESSION_STARTED);
    trackReactPixelEvent(TRACKING_CHAT_SESSION_STARTED);
  }
};

export const trackChatSessionEnded = (): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_CHAT_SESSION_END);
    trackReactPixelEvent(TRACKING_CHAT_SESSION_END);
  }
};

export const trackChatQuestionSent = (): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_CHAT_QUESTION_SENT);
    trackReactPixelEvent(TRACKING_CHAT_QUESTION_SENT);
  }
};

export const trackChatQuestionAsk = (): void => {
  if (enableTrackingLibraries) {
    // TODO ADD TRACKING
  }
};

export const trackTutorChat = (eventParams: TrackTutorChatTypes): void => {};
