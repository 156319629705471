import * as actionTypes from '../Actions/types';

interface Action {
  type: string;
  payload: any;
}

interface IInitialState {
  appConfig: any;
  dialingCodeArray: string[];
  countries: string[];
  flags: string[];
  uuid: string;
  badges: Array<any>;
  interactive_quiz_base_url: string;
  remoteConfig: any;
}

const initialState: IInitialState = {
  appConfig: {},
  dialingCodeArray: [],
  countries: [],
  flags: [],
  uuid: '',
  badges: [],
  interactive_quiz_base_url: '',
  remoteConfig: {},
};

const support = ['nigeria', 'ghana', 'liberia', 'sierra leone', 'gambia'];

const mapFunc = (item: any, key: number) => {
  return {
    id: key,
    name: item.dialing_code,
    enabled: item.enabled,
    displayText: '',
    value: item.name,
    locale: item.country_code,
    unicode: item.flag_unicode,
    flag: require('../Utils/flags/flag-' + item.name.toLowerCase().replace(/ /g, '-') + '.svg')
      .default,
  };
};

const mainReducers = (state = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.CONFIG_PULLED: {
      let array = [];
      let countries = [];
      let supported = [];
      let flags = [];
      if (action?.payload?.config && action?.payload?.config?.countries?.length) {
        array = action?.payload?.config?.countries?.map((item: any, key: number) => {
          return {
            id: key,
            value: item.dialing_code,
            name: item.dialing_code,
            image: '../Utils/flags/flag-' + item.name.toLowerCase().replace(/ /g, '-') + '.svg',
          };
        });
        // eslint-disable-next-line
        flags = action.payload.config.countries
          .filter((item: any, key: number) => !support.includes(item.name.toLowerCase()))
          .map(mapFunc);
        // eslint-disable-next-line
        supported = action.payload.config.countries
          .filter((item: any, key: number) => support.includes(item.name.toLowerCase()))
          .map(mapFunc);
        countries = action.payload.config.countries.map((item: any, key: number) => {
          return {
            id: key,
            country_code: item.country_code,
            value: item.name,
            name: item.name.substring(0, 15),
            grade_groups: item.grade_groups,
            country_id: item.id,
            enabled: item.enabled,
            position: item.position,
            currency: item.currency,
            unicode: item.flag_unicode,
            flag: require('../Utils/flags/flag-' +
              item.name.toLowerCase().replace(/ /g, '-') +
              '.svg').default,
          };
        });
      }
      return {
        ...state,
        appConfig: action.payload.config,
        dialingCodeArray: array,
        countries,
        flags: [...supported, ...flags],
        uuid: action.payload.uuid,
        badges: action.payload.config.badges,
        interactive_quiz_base_url: action.payload.config.interactive_quiz_base_url,
      };
    }
    case actionTypes.ADD_BADGES: {
      return {
        ...state,
        badges: action.payload.badges,
      };
    }
    case actionTypes.REMOTE_CONFIG: {
      return {
        ...state,
        remoteConfig: action.payload,
      };
    }
    default:
      return state;
  }
};

export default mainReducers;
