import axios from 'axios';
import generateUniqueId from 'generate-unique-id';
import * as constant from './constants';
import { storeItem, retrieveItem, generateCorrelationId } from '../Functions';

const BASE_URL = process.env.REACT_APP_MOCK_URL;
const APP_TOKEN = process.env.REACT_APP_TOKEN;
const BUILD_NUMBER = process.env.REACT_APP_BUILD_NUMBER;

const axiosMock = () => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      'app-token': APP_TOKEN,
      'build-number': BUILD_NUMBER,
      language: 'en',
      source: 'fe-web-app',
    },
  });

  instance.interceptors.request.use(
    async config => {
      const authToken = config.headers[constant.ApiHeaders.Authorization];
      const deviceId = config.headers[constant.ApiHeaders.DeviceId];
      const correlationId = config.headers[constant.ApiHeaders.XCorrelationId];
      if (!authToken) {
        const savedAuthToken = await retrieveItem(constant.StorageKeys.MockToken);
        config.headers[constant.ApiHeaders.Authorization] = `Bearer ${savedAuthToken}`;
      }
      if (!deviceId) {
        const savedDeviceId = await retrieveItem(constant.StorageKeys.DeviceId);
        if (savedDeviceId) {
          config.headers[constant.ApiHeaders.DeviceId] = savedDeviceId;
        } else {
          const id = generateUniqueId({
            length: 36,
            useLetters: true,
            useNumbers: true,
            includeSymbols: ['-'],
          });
          storeItem(constant.StorageKeys.DeviceId, id);
          config.headers[constant.ApiHeaders.DeviceId] = id;
        }
      }
      if (!correlationId) {
        const id = generateCorrelationId();
        config.headers[constant.ApiHeaders.XCorrelationId] = id;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default axiosMock;
