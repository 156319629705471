import { learnerInterface } from 'Types/general';
import { enableTrackingLibraries } from 'Utils/constants';
import {
  TRACKING_ACCOUNT_REGISTRATION,
  TRACKING_CLASS_DATA_FETCH,
  TRACKING_EXAM_PROGRESS,
  TRACKING_LOGIN,
  TRACKING_LOGOUT,
  TRACKING_PARENT_PHONE_NUMBER_SAVE,
  TRACKING_PRACTICE_TEST_PROGRESS,
  TRACKING_VIDEO_CAP_REACHED,
} from './constants';
import { initFirebase, trackFirebaseEvent } from './firebase';
import { initReactPixel, trackReactPixelEvent } from './reactPixel';
import {
  TrackClassDataFetchTypes,
  TrackExamProgressTypes,
  TrackGradeSwitchTypes,
  TrackPlanSelectTypes,
  TrackSaveTestProgressTypes,
  TrackStartQuizzesTypes,
  TrackSubjectSelectTypes,
  TrackSubscribedTypes,
  TrackSubscriptionGradeStageTypes,
  TrackTestStartTypes,
  TrackUnlockPremiumTypes,
} from './types';

export const initTracking = () => {
  if (enableTrackingLibraries) {
    initFirebase();
    initReactPixel();
  }
};

export const promptUser = (): void => {};

//  TODO: Ask what the difference between login and process login tracking is
export const trackLogin = (learner: learnerInterface): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_LOGIN);
    trackReactPixelEvent(TRACKING_LOGIN);
  }
};

export const trackProcessLogin = (): void => {
  if (enableTrackingLibraries) {
    // TODO ADD TRACKING
  }
};

export const trackLogout = (): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_LOGOUT);
    trackReactPixelEvent(TRACKING_LOGOUT);
  }
};

export const trackCreateLearnerAccount = (learner: learnerInterface): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_ACCOUNT_REGISTRATION);
    trackReactPixelEvent(TRACKING_ACCOUNT_REGISTRATION);
  }
};

export const trackCreateAccount = (): void => {
  if (enableTrackingLibraries) {
    // TODO ADD TRACKING
  }
};

export const trackExamProgress = (eventParams: TrackExamProgressTypes): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_EXAM_PROGRESS, eventParams);
    trackReactPixelEvent(TRACKING_EXAM_PROGRESS, eventParams);
  }
};

export const trackSaveTestProgress = (eventParams: TrackSaveTestProgressTypes): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_PRACTICE_TEST_PROGRESS, eventParams);
    trackReactPixelEvent(TRACKING_PRACTICE_TEST_PROGRESS, eventParams);
  }
};

export const trackVideoCapReached = (): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_VIDEO_CAP_REACHED);
    trackReactPixelEvent(TRACKING_VIDEO_CAP_REACHED);
  }
};

export const trackPremiumSubscribe = (): void => {
  if (enableTrackingLibraries) {
    // TODO ADD TRACKING
  }
};

export const trackLimitLiveLessons = (): void => {
  if (enableTrackingLibraries) {
    // TODO ADD TRACKING
  }
};

export const trackParentDetailSave = (): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_PARENT_PHONE_NUMBER_SAVE);
    trackReactPixelEvent(TRACKING_PARENT_PHONE_NUMBER_SAVE);
  }
};

export const trackListChapters = (): void => {
  if (enableTrackingLibraries) {
    // TODO ADD TRACKING
  }
};

export const trackLessonsPath = (): void => {
  if (enableTrackingLibraries) {
    // TODO ADD TRACKING
  }
};

export const trackSearchBar = (): void => {
  if (enableTrackingLibraries) {
    // TODO ADD TRACKING
  }
};

export const trackShareApp = (): void => {
  if (enableTrackingLibraries) {
    // TODO ADD TRACKING
  }
};

export const trackLearningAnalysisStartTest = (): void => {
  if (enableTrackingLibraries) {
    // TODO ADD TRACKING
  }
};

export const trackQuestions = (): void => {
  if (enableTrackingLibraries) {
    // TODO ADD TRACKING
  }
};

export const trackClassDataFetch = (eventParams: TrackClassDataFetchTypes): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_CLASS_DATA_FETCH);
    trackReactPixelEvent(TRACKING_CLASS_DATA_FETCH);
  }
};

export const trackDashboardBannerClick = (bannerCode: string): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(bannerCode);
  }
};

export const trackSubjectSelect = (eventParams: TrackSubjectSelectTypes): void => {};

export const trackGradeSwitch = (eventParams: TrackGradeSwitchTypes): void => {};

export const trackPlanSelect = (eventParams: TrackPlanSelectTypes): void => {};

export const trackStartQuizzes = (eventParams: TrackStartQuizzesTypes): void => {};

export const trackUnlockPremium = (eventParams: TrackUnlockPremiumTypes): void => {};

export const trackSubscriptionGradeStage = (
  eventParams: TrackSubscriptionGradeStageTypes
): void => {};

export const trackCapReached = (): void => {};

export const trackTestStart = (eventParams: TrackTestStartTypes): void => {};

export const trackSubscribed = (eventParams: TrackSubscribedTypes): void => {};
