import * as actionTypes from '../Actions/trial/actionTypes';

interface Action {
  type: string;
  payload: any;
}

const initialState: any = {
  isFreeTrialOpen: false,
  isTrialExpired: false,
  isFreeBlocked: false,
  isTrialAnnouncement: false,
};

const trialReducer = (state = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.TOGGLE_FREE_TRIAL: {
      return {
        ...state,
        isFreeTrialOpen: payload,
      };
    }
    case actionTypes.TOGGLE_TRIAL_ANNOUNCEMENT: {
      return {
        ...state,
        isTrialAnnouncement: payload,
      };
    }
    case actionTypes.TOGGLE_TRIAL_EXPIRATION: {
      return {
        ...state,
        isTrialExpired: payload,
      };
    }
    case actionTypes.TOGGLE_FREE_BLOCKER: {
      return {
        ...state,
        isFreeBlocked: payload,
      };
    }
    default:
      return state;
  }
};

export default trialReducer;
