import { Dimensions } from 'react-native';
import * as actionTypes from '../Actions/auth/actionTypes';

const initialState = {
  dimension: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
    device: Dimensions.get('window').width < 500 ? true : false,
  },
  accountStatus: '',
  authStatus: '',
  authMessage: '',
  dialingCode: '',
  mobile: '',
  sendOtpStatus: '',
  gameToken: '',
  otpVerificationStatus: '',
  learners: [],
  authToken: '',
  isAuthenticated: false,
  learner: {},
  tutor: {},
  avatarId: 0,
  recaptchaToken: '',
};

const authReducers = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_DIMENSION: {
      return {
        ...state,
        dimension: payload,
      };
    }
    case actionTypes.ACCOUNT_RESOLVED: {
      return {
        ...state,
        accountStatus: payload.accountStatus,
        authMessage: payload.message,
        authStatus: payload.responseStatus,
        dialingCode: payload.dialingCode,
        mobile: payload.mobile,
      };
    }
    case actionTypes.OTP_SENT: {
      return {
        ...state,
        sendOtpStatus: payload.status,
      };
    }
    case actionTypes.OTP_VERIFIED: {
      return {
        ...state,
        otpVerificationStatus: payload.status,
        learners: payload.learners,
        authToken: payload.authToken,
        gameToken: payload.gameToken,
      };
    }
    case actionTypes.RESET_PASSWORD: {
      return {
        ...state,
        learners: [],
        authToken: '',
      };
    }
    case actionTypes.SAVE_LEARNER: {
      return {
        ...state,
        learner: payload.learner,
        isAuthenticated: true,
      };
    }
    case actionTypes.SAVE_LEARNERS: {
      return {
        ...state,
        learners: payload.learners,
      };
    }
    case actionTypes.LOGIN_COMPLETED: {
      return {
        ...state,
        learners: payload.learners,
        learner: payload.learner,
        tutor: payload.tutor,
        authToken: payload.authToken,
        gameToken: payload.gameToken,
      };
    }
    case actionTypes.SELECT_LEARNER: {
      return {
        ...state,
        isAuthenticated: true,
      };
    }
    case actionTypes.SELECT_GRADE:
      return {
        ...state,
        learners: [{ ...state.learner, grade: { ...payload } }],
        learner: { ...state.learner, grade: { ...payload } },
      };
    case actionTypes.SET_AVATAR:
      return {
        ...state,
        avatarId: payload,
      };
    case actionTypes.EDIT_PROFILE:
      return {
        ...state,
        learners: [payload],
        learner: payload,
      };
    case actionTypes.SET_PREMIUM:
      return {
        ...state,
        learners: [{ ...state.learner, premium: payload }],
        learner: { ...state.learner, premium: payload },
      };
    case actionTypes.FETCH_LEARNER: {
      return {
        ...state,
        learner: payload.learner,
        learners: [payload.learner],
      };
    }
    case actionTypes.VERIFY_RECAPTCHA: {
      return {
        ...state,
        recaptchaToken: payload,
      };
    }
    case actionTypes.LOG_OUT: {
      return {
        ...state,
        accountStatus: '',
        authStatus: '',
        authMessage: '',
        dialingCode: '',
        mobile: '',
        sendOtpStatus: '',
        otpVerificationStatus: '',
        learners: [],
        authToken: '',
        isAuthenticated: false,
        learner: {},
        tutor: {},
        avatarId: 0,
      };
    }
    default:
      return state;
  }
};

export default authReducers;
