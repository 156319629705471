import { IPracticeQuestion } from './../../Types/content.d';
import { IChapter, ILesson, IQuest } from 'Types/content';
import {
  CLICK_TAB,
  SELECT_CHAPTER,
  SELECT_LESSON,
  SEARCH_QUEST,
  CLEAR_CHAPTER_STATE,
  SET_REVISON_VIDEOS,
  SELECT_REVISION,
  TOGGLE_CHAPTER_TIMER,
} from './actionTypes';

export const selectChapter = (selectedChapter: IChapter | undefined, selectedIndex: number) => (
  dispatch: Function
) => {
  const data = { selectedChapter, selectedIndex };
  return dispatch({ type: SELECT_CHAPTER, payload: data });
};

export const toggleChapterTimer = () => (dispatch: Function) => {
  return dispatch({ type: TOGGLE_CHAPTER_TIMER });
};

export const selectLesson = (selectedLesson: ILesson, selectedQuest: IQuest) => (
  dispatch: Function
) => {
  const data = { selectedLesson, selectedQuest };
  return dispatch({ type: SELECT_LESSON, payload: data });
};

export const selectRevision = () => (dispatch: Function) => {
  return dispatch({ type: SELECT_REVISION });
};

export const clickTab = (tabIndex: number) => (dispatch: Function) => {
  return dispatch({ type: CLICK_TAB, payload: { selectedTabIndex: tabIndex } });
};

export const searchQuest = (searchedQuest: IQuest) => (dispatch: Function) => {
  const data = { searchedQuest };
  return dispatch({ type: SEARCH_QUEST, payload: data });
};

export const clearChapterState = () => (dispatch: Function) => {
  return dispatch({ type: CLEAR_CHAPTER_STATE });
};

export const setRevisionVideos = (
  chapterLessons: ILesson[] | undefined,
  practiceQuestions: IPracticeQuestion[],
  practice: any[]
) => (dispatch: Function) => {
  let revisionVideos: ILesson[] = [];

  if (practice && chapterLessons && practiceQuestions) {
    //check for answered questions that were wrong
    const wrongAttempts = practice.filter((item: any) => item.accuracy !== 1);

    if (wrongAttempts) {
      //unique set of question IDs
      const uniqueQuestionIds = [...new Set(wrongAttempts.map(item => item.questionId))];
      //filter out the practice questions to get the full object
      const wrongPracticeQuestions = practiceQuestions.filter(item =>
        uniqueQuestionIds.includes(item.id)
      );
      //filter out the lessons and push to the revision array
      const uniqueLessonIds = [...new Set(wrongPracticeQuestions.map(item => item.lesson_id))];
      uniqueLessonIds.forEach(uniqueLessonId => {
        const revisionLesson = chapterLessons.filter(chapterLesson =>
          uniqueLessonId.includes(chapterLesson.id)
        );
        revisionVideos.push(revisionLesson[0]);
      });
    }
  }

  const data = { revisionVideos };
  return dispatch({ type: SET_REVISON_VIDEOS, payload: data });
};
