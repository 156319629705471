import moment from 'moment';
import { subscriptionInterface } from 'Types/general';
import { currencyAmount } from 'Utils/currency';

type UserProps = {
  premium: boolean;
  free_trial: boolean;
  active_subscription: subscriptionInterface;
  subscriptions: Array<subscriptionInterface>;
};

export const learnerAccess = (user: UserProps | any) => {
  const activeSubscription = user?.active_subscription;

  // CHECK IF LEARNER IS ON BASIC ACCOUNT
  const start = moment(activeSubscription?.start_at);
  const end = moment(activeSubscription?.end_at);

  return {
    isFreeTrial: !user.premium && user.free_trial,
    isPremium: user.premium,
    isPremiumNotTrial: user.premium && !user.free_trial,
    isOnBasicPlan: end.diff(start, 'years') > 90 && currencyAmount(activeSubscription) === '0.00',
    isLifetime: end.diff(start, 'years') >= 9 && end.diff(start, 'years') < 90,
  };
};
