import firebase from 'firebase/app';
import 'firebase/analytics';

import { firebaseAnalyticsEnabled } from 'config';

export const initFirebase = (): void => {
  if (firebaseAnalyticsEnabled) {
    firebase.analytics();
  }
};

export const trackFirebaseEvent = (
  eventName: string,
  eventParams?: { [key: string]: any }
): void => {
  if (firebaseAnalyticsEnabled) {
    firebase.analytics().logEvent(eventName, eventParams);
  }
};
