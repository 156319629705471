import getSymbolFromCurrency from 'currency-symbol-map';
import { formatCurrency } from 'Functions';
import { subscriptionInterface } from 'Types/general';

export const determineCurrency = (subscription: subscriptionInterface | any) => {
  if (!subscription?.currency) return '--';
  const currency = subscription?.currency?.toLowerCase();

  // If no amount for that specific currency is found
  // in the subscription object revert back to Nigerian naira
  if (!subscription?.[`amount_${currency}`]) {
    const currencySymbol = getSymbolFromCurrency('ngn');
    const formattedCurrency = formatCurrency(subscription?.amount_ngn ?? '');
    return `${currencySymbol}${formattedCurrency}`;
  }

  const currencySymbol = getSymbolFromCurrency(currency);
  const formattedCurrency = formatCurrency(subscription?.[`amount_${currency}`] ?? '');
  return `${currencySymbol}${formattedCurrency}`;
};

export const currencyAmount = (subscription: subscriptionInterface | any) => {
  const currency = subscription?.currency?.toLowerCase();
  return subscription?.[`amount_${currency}`] ?? '--';
};
