/* eslint-disable no-console */
import { logger } from '@sentry/utils';
import { AppEnv } from 'config';
import { ENV_PRODUCTION } from './constants';

const isDevOutputEnabled: boolean = AppEnv?.toLowerCase() !== ENV_PRODUCTION;

const logError = (error: unknown): void => {
  // Sentry error log message
  logger.error(error);
  if (isDevOutputEnabled) console.error(error);
};

const logInfo = (message: string): void => {
  if (isDevOutputEnabled) console.info(message);
};

const logMessage = (message: string, ...optionalParams: any[]): void => {
  // Sentry log message
  logger.log(message);

  if (isDevOutputEnabled) console.log(message, optionalParams);
};

const logWarn = (message: string): void => {
  if (isDevOutputEnabled) console.warn(message);
};

export { logError, logInfo, logMessage, logWarn };
