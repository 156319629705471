export const LOG_OUT = 'LOG_OUT';
export const PLAY_LESSONS = 'PLAY_LESSONS';
export const SAVE_LESSONS_WATCHED = 'SAVE_LESSONS_WATCHED';
export const SUBJECT_CONTINUE_QUEST = 'SUBJECT_CONTINUE_QUEST';
export const CONTINUE_QUEST = 'CONTINUE_QUEST';
export const RESUME_JOURNEY = 'RESUME_JOURNEY';
export const SAVE_HIGHLIGHTS = 'SAVE_HIGHLIGHTS';
export const CLEAR_QUIZ_PREVIOUS_ANSWERS = 'CLEAR_QUIZ_PREVIOUS_ANSWERS';
export const ADD_QUIZ_PREVIOUS_ANSWERS = 'ADD_QUIZ_PREVIOUS_ANSWERS';
export const SET_SELECTED_PRACTICE = 'SET_SELECTED_PRACTICE';
export const GRADE_CONTENT = 'GRADE_CONTENT';
export const UPDATE_ATTEMPTED_TEST_IDS = 'UPDATE_ATTEMPTED_TEST_IDS';
export const GET_ALL_AWARDED_BADGES = 'GET_ALL_AWARDED_BADGES';
export const SET_SELECTED_ANALYSIS_SUBJECT_ID = 'SET_SELECTED_ANALYSIS_SUBJECT_ID';
export const SET_SELECTED_ANALYSIS = 'SET_SELECTED_ANALYSIS';
export const SET_PROGRESS_ANALYSIS = 'SET_PROGRESS_ANALYSIS';
export const GET_ALL_TESTS = 'GET_ALL_TESTS';
export const SET_PERFORMANCE_ANALYSIS = 'SET_PERFORMANCE_ANALYSIS';
export const SET_ACHIEVEMENT_ANALYSIS = 'SET_ACHIEVEMENT_ANALYSIS';
export const LESSON_PASSES = 'LESSON_PASSES';
export const TOGGLE_LESSON_PASSES = 'TOGGLE_LESSON_PASSES';
