import firebase from 'firebase/app';
import 'firebase/firestore';
import * as actionTypes from './actionTypes';
import axiosInstance from 'Utils/axios-orders';
import axiosMock from 'Utils/axios-mock';
import { fetchSubjects, fetchThemes } from 'Actions';
import { RootState } from 'Reducers';
import { catchHelper, retrieveItem, storeItem } from 'Functions';
import { Grade } from 'Components/GradeSwitch';
import { gradeInterface, ThunkResult } from 'Types/general';
import { trackGradeSwitch, trackSubjectSelect } from 'Utils/tracking';
import { TrackSubjectSelectTypes } from 'Utils/tracking/types';
import { ILearnerProgress } from 'Types/content';
import { captureException } from '@sentry/react';
import * as constant from '../../Utils/constants';

const axios = axiosInstance();
const axiosM = axiosMock();

const saveSearchHistory = (payload: any) => {
  return {
    type: actionTypes.SAVE_SEARCH_HISTORY,
    payload,
  };
};

const subjectAction = (payload: any) => {
  return {
    type: actionTypes.SELECT_SUBJECT,
    payload,
  };
};

const examAction = (payload: any) => {
  return {
    type: actionTypes.SELECT_EXAM_SUBJECT,
    payload,
  };
};

export const saveSearch = (data: any) => async (dispatch: Function, getState: Function) => {
  await dispatch(
    saveSearchHistory({
      gradeId: getState().auth.learner.grade.id,
      searchData: data,
    })
  );
};

export const retryFirstContent = () => async (dispatch: Function, getState: Function) => {
  dispatch({
    type: actionTypes.RETRY_CONTENT,
  });
};

export const closeError = () => (dispatch: Function, getState: Function) => {
  dispatch({
    type: actionTypes.CLOSE_ERROR,
  });
};

const syncData = async (backendData: any, mainstate: any, homestate: any, authstate: any) => {
  const unique = new Set();
  const examsCollectionName: string = 'examsProgress';
  const testsCollectionName: string = 'testsProgress';
  const badgesCollectionName: string = 'badgesAwarded';

  /* sync tests */
  const uniqueTests: any = [];
  const tests = backendData.data.data.tests;

  if (tests.length > 0) {
    unique.clear();
    tests.forEach((item: any) => {
      if (
        unique.has({
          chapter_id: item.chapter_id,
          grade_id: item.grade_id,
          learner_id: item.learner_id,
        })
      )
        return;
      unique.add(item);
      uniqueTests.push(item);
    });

    uniqueTests.map(async (test: any) => {
      const testExists = await firebase
        .firestore()
        .collection(testsCollectionName)
        .where('externalId', '==', test.learner_id)
        .where('gradeId', '==', test.grade_id)
        .where('chapterId', '==', test.chapter_id)
        .where('testId', '==', test.test_id)
        .get();

      if (testExists.empty) {
        if (homestate && homestate.tests && homestate.tests.length > 0) {
          const testInstance = homestate.tests.find(
            (testState: any) => testState.id === test.test_id
          );

          const remappedQuestions = test.questions
            .map((question: any, idx: number) => {
              if (testInstance && testInstance.questions && idx < testInstance.questions.length) {
                let selected = -1;
                if (question.options_selected.length > 0) {
                  testInstance.questions[idx].options.map((option: any, index: number) => {
                    if (option.id === question.options_selected[0].id) selected = index;
                  });
                }

                return {
                  accuracy: question.is_correct ? 1 : question.is_correct === false ? 0 : -1,
                  difficulty: testInstance.questions[idx].difficulty,
                  questionNo: idx,
                  selected,
                  subjectId: testInstance.subjectId,
                  testId: testInstance.id,
                  timeSpent: question.time_spent,
                };
              }
            })
            .filter((question: any) => {
              if (question) return question;
            });

          if (testInstance) {
            await firebase
              .firestore()
              .collection(testsCollectionName)
              .add({
                chapterId: test.chapter_id,
                externalId: test.learner_id,
                gradeId: test.grade_id,
                subjectId: testInstance.subjectId,
                testId: test.test_id,
                tests: remappedQuestions,
              })
              .then(res => {
                res.update({
                  docId: res.id,
                });
                return res.id;
              });
          }
        }
      }
    });
  }

  /* sync exams */
  const uniqueExams: any = [];
  const exams = backendData.data.data.exams;

  if (exams.length > 0) {
    unique.clear();
    exams.forEach((item: any) => {
      if (
        unique.has({
          exam_id: item.exam_id,
          subject_id: item.subject_id,
          learner_id: item.learner_id,
        })
      )
        return;
      unique.add(item);
      uniqueExams.push(item);
    });

    uniqueExams.map(async (exam: any) => {
      const examsExists = await firebase
        .firestore()
        .collection(examsCollectionName)
        .where('externalId', '==', exam.learner_id)
        .where('subjectId', '==', exam.subject_id)
        .where('examId', '==', exam.exam_id)
        .get();

      if (examsExists.empty) {
        if (homestate && homestate.exams && homestate.exams.length > 0) {
          const examInstance = homestate.exams.find(
            (examState: any) => examState.id === exam.exam_id
          );

          const remappedQuestions =
            exam.questions &&
            exam.questions
              .map((question: any, idx: number) => {
                if (examInstance && examInstance.questions && idx < examInstance.questions.length) {
                  let selected = -1;
                  if (question.options_selected.length > 0) {
                    examInstance.questions[idx].options.map((option: any, index: number) => {
                      if (option.id === question.options_selected[0].id) selected = index;
                    });
                  }

                  return {
                    accuracy: question.is_correct ? 1 : question.is_correct === false ? 0 : -1,
                    difficulty: examInstance.questions[idx].difficulty,
                    questionNo: idx,
                    selected,
                    subjectId: examInstance.subjectId,
                    examId: examInstance.id,
                    timeSpent: question.time_spent,
                  };
                }
              })
              .filter((question: any) => {
                if (question) return question;
              });

          if (examInstance) {
            await firebase
              .firestore()
              .collection(examsCollectionName)
              .add({
                externalId: exam.learner_id,
                gradeId: authstate.learner.grade_group_id,
                subjectId: examInstance.subjectId,
                examId: exam.exam_id,
                exams: remappedQuestions,
              })
              .then(res => {
                res.update({
                  docId: res.id,
                });
                return res.id;
              });
          }
        }
      }
    });
  }

  /* sync badges */
  const badges = backendData.data.data.badges;

  if (badges.length > 0) {
    badges.map(async (badge: any) => {
      const badgeInstance = mainstate.badges.find((badgeState: any) => badgeState.id === badge.id);
      if (!badgeInstance) return;
      const badgeExists = await firebase
        .firestore()
        .collection(badgesCollectionName)
        .where('externalId', '==', authstate.learner.id)
        .where('subjectId', '==', badgeInstance.subject_id)
        .where('badgeId', '==', badge.id)
        .get();

      if (badgeExists.empty) {
        await firebase.firestore().collection(badgesCollectionName).add({
          externalId: authstate.learner.id,
          gradeId: authstate.learner.grade.id,
          subjectId: badgeInstance.subject_id,
          badgeId: badge.id,
          createdAt: badge.awarded_at,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
    });
  }
};

export const fetchGradeContent = (gradeCode: string | number) => async (
  dispatch: Function,
  getState: () => RootState
): Promise<boolean> => {
  const { main, auth, home } = getState();
  const learner = auth.learner ? auth.learner : auth.learners[0];
  if (!learner.country || !learner.country.id)
    return catchHelper({ message: 'No country id found' }, dispatch);

  const authToken = auth.authToken;
  const config = {
    headers: {
      'auth-token': authToken,
      'device-uuid': main.uuid,
    },
  };
  await dispatch(fetchThemes());
  await dispatch(fetchSubjects());
  dispatch({ type: actionTypes.HOME_INIT });
  const getProgress = await axios.get(`progress/data?grade_id=${learner.grade.id}`, config);
  await syncData(getProgress, main, home, auth);

  return true;
};

export const fetchLearnerProgress = () => async (
  dispatch: Function,
  getState: () => RootState
): Promise<ILearnerProgress> => {
  try {
    const { main, auth } = getState();
    const learner = auth.learner ? auth.learner : auth.learners[0];
    if (!learner.country || !learner.country.id)
      return catchHelper({ message: 'No country id found' }, dispatch);

    const authToken = auth.authToken;
    const config = {
      headers: {
        'auth-token': authToken,
        'device-uuid': main.uuid,
      },
    };

    const getProgress = await axios.get(`progress/data?grade_id=${learner.grade.id}`, config);
    return getProgress.data.data;
  } catch (error) {
    captureException(error);
    const { status } = catchHelper(error, dispatch);
    return { status, tests: [], lessons: [], badges: [] };
  }
};

export const clearSelectedSubject = () => async (dispatch: Function, getState: Function) => {
  const lessonsLength = 0;
  await dispatch(subjectAction({ lessonsLength }));
  return true;
};

export const selectSubject = (string: string | number) => async (
  dispatch: Function,
  getState: Function
) => {
  const getName = getState().content.subjects.find((val: any) => val.subjectId === string);
  const subject = getState().home.content.find((val: any) => val.id === string);
  let lessonsLength = 0;
  await subject.chapters.forEach(async (item: any) => {
    await item.quests.forEach((value: any) => {
      lessonsLength += value.lessons.length;
    });
  });

  const eventParams: TrackSubjectSelectTypes = {
    subject_id: subject.id,
    subject_name: subject.name,
  };
  trackSubjectSelect(eventParams);

  await dispatch(
    subjectAction({
      ...subject,
      subjectDisplayName: getName.displayName,
      lessonsLength,
    })
  );
  return true;
};

export const selectPracticeExams = (string: string | number) => async (
  dispatch: Function,
  getState: Function
) => {
  const array = getState().home.exams;
  const exams = array
    .filter((val: any) => val.subjectId === string)
    .sort((a: any, b: any) => a.release_year - b.release_year);
  dispatch(examAction(exams));
};

export const selectedExamsFunc = (object: any) => async (dispatch: Function) => {
  await dispatch({
    type: actionTypes.SELECT_EXAMS,
    payload: object,
  });
};

export const selectedTestFunc = (object: any) => async (dispatch: Function) => {
  await dispatch({
    type: actionTypes.SELECT_TESTS,
    payload: object,
  });
};

export const selectChapterTests = (chapterTest: any) => (dispatch: Function) => {
  dispatch({
    type: actionTypes.SELECT_CHAPTER_TESTS,
    payload: {
      tests: chapterTest.tests,
      chapterId: chapterTest.chapterId,
    },
  });
};

export const updateAttemptedTestIds = (testId: string | number) => (dispatch: Function) => {
  dispatch({
    type: actionTypes.UPDATE_ATTEMPTED_TEST_IDS,
    payload: {
      testId,
    },
  });
};

export const setTotalTestAndQuestCount = (questCount: any, testCount: any) => (
  dispatch: Function
) => {
  dispatch({
    type: actionTypes.SET_TEST_AND_QUEST_COUNT,
    payload: {
      questCount,
      testCount,
    },
  });
};

export const gradeByCountry = () => (dispatch: Function, getState: Function) => {
  const learnerAuth =
    getState().auth.learner && getState().auth.learner.country
      ? getState().auth.learner
      : getState().auth.learners[0];
  const countryId = learnerAuth?.country?.id;
  const gradeGroupId = learnerAuth?.grade_group_id;
  const learnerGradeGroup =
    getState().main.appConfig &&
    getState().main.appConfig.countries &&
    getState().main.appConfig.countries.length &&
    getState().main.appConfig.countries.filter((gradeLevel: any) => {
      return gradeLevel.id === countryId;
    });
  const gradeGroup = learnerGradeGroup?.[0]?.grade_groups;

  let grades;
  let allgrades;
  if (learnerAuth.premium) {
    grades = gradeGroup?.filter((group: any) => group?.id === gradeGroupId)[0]?.grades;
    allgrades = grades?.sort((a: any, b: any) => a.id - b.id);
  } else {
    grades = gradeGroup?.map((group: any) => group?.grades);
    allgrades = grades?.flat()?.sort((a: any, b: any) => a.id - b.id);
  }
  const grade_id = learnerAuth.grade.id;
  const defaultGrade = allgrades?.filter((el: any) => el?.id === grade_id)?.[0];

  dispatch({
    type: actionTypes.GRADE_BY_COUNTRY,
    payload: {
      allgrades,
      defaultGrade,
    },
  });
};

export const switchSelectedGrade = (grade: Grade | gradeInterface): ThunkResult<any> => (
  dispatch: Function
) => {
  trackGradeSwitch({ class: grade.id });

  dispatch({
    type: actionTypes.SELECT_GRADE,
    payload: grade,
  });
};

export const getMockExams = (grade: number): ThunkResult<any> => async (dispatch: Function) => {
  const response = await axiosM.get(`scheduled-exam/exam/upcomingPublished?grade_id=${grade}`);

  dispatch({
    type: actionTypes.GET_MOCK_EXAMS,
    payload: response?.data,
  });
};

export const startMockExam = (examId: any): ThunkResult<any> => async (dispatch: Function) => {
  return await axiosM.patch(`scheduled-exam/${examId}/setStartedAt`, {});
};

export const getAllExams = (): ThunkResult<any> => async (dispatch: Function) => {
  const response = await axiosM.get(`scheduled-exam/myExams?range=all`);

  dispatch({
    type: actionTypes.GET_ALL_EXAMS,
    payload: response?.data,
  });
};

export const registerMockExam = (exam_id: number): ThunkResult<any> => async (
  dispatch: Function
) => {
  const response = await axiosM.post(`scheduled-exam`, { exam_id });
  return response.data;
};

export const loginMockUser = (): ThunkResult<any> => async (dispatch: Function) => {
  const auth_token = await retrieveItem(constant.StorageKeys.AuthToken);
  const device_uuid = await retrieveItem(constant.StorageKeys.DeviceId);
  const authLogin: { data: { token: string } } = await axiosM.post('learner-auth/login', {
    auth_token,
    device_uuid,
  });
  storeItem(constant.StorageKeys.MockToken, authLogin.data?.token);
};

export const getExamDetails = (examId: string): ThunkResult<any> => async (dispatch: Function) => {
  const response = await axiosM.get(`scheduled-exam/exam/${examId}`);
  return response.data;
};

export const getAvatars = (): ThunkResult<any> => async (dispatch: Function) => {
  const response = await axiosInstance('v3').get(`/learners/avatars`);
  return dispatch({
    type: actionTypes.GET_AVATARS,
    payload: response?.data,
  });
};
