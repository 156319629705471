import * as actionTypes from './actionTypes';
import axiosInstance from 'Utils/axios-orders';
import { catchHelper } from 'Functions';
import { reduxResponse, ThunkResult } from 'Types/general';
import { planInterface, amountsType } from 'Pages/Subscription/Packages';
import { modeType } from 'Pages/Subscription/PayMethod';
import { RootState } from 'Reducers';
import { captureException } from 'Utils/sentry';
import { trackPlanSelect } from 'Utils/tracking';
import { TrackPlanSelectTypes } from 'Utils/tracking/types';
const axios = axiosInstance();
const axiosV2 = axiosInstance('v2');

export const setSubscribeGrade = (
  countryId: number,
  gradeGroupId: number,
  gradeId: number
): ThunkResult<any> => (dispatch: Function, getState: Function): reduxResponse => {
  dispatch({ type: actionTypes.SET_GRADE, payload: { countryId, gradeGroupId, gradeId } });
  return { status: 'success', message: 'Grade group and grade Id successfully set' };
};

export const allPlans = () => async (
  dispatch: Function,
  getState: Function
): Promise<reduxResponse> => {
  try {
    const plans = await axios.get(`all_plans`);
    const { status, data } = plans.data.data;
    dispatch({ type: actionTypes.ALL_PLANS, payload: data });
    return { status, data };
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
    return { status: 'error' };
  }
};

export const selectPlan = (plan: any, autorenew: boolean): ThunkResult<any> => (
  dispatch: Function,
  getState: Function
): reduxResponse => {
  dispatch({ type: actionTypes.SELECT_PLAN, payload: { plan, autorenew } });
  return { status: 'success', message: 'Plan Successfully Selected!' };
};

export const selectPaymentMode = (
  mode: modeType | null,
  coupon_code?: any
): ThunkResult<any> => async (dispatch: Function, getState: Function): Promise<reduxResponse> => {
  try {
    const {
      subscribe: { selectedPlan },
      auth,
    }: RootState = getState();
    if (coupon_code) {
      const subscriptionData = {
        subscription: {
          address_id: '',
          currency: selectedPlan.charge_currency,
          deliver_to_fullname: auth.learner.firstname + ' ' + auth.learner.lastname,
          deliver_to_phone: `+${auth.learner.dialing_code}${auth.learner.mobile}`,
          delivery_instruction: '',
          grade_group_id: selectedPlan.grade_group_id,
          grade_group_plan_id: selectedPlan.id,
          learner_id: auth.learner.id,
          notes: '',
          payment_gateway: 'flutterwave',
          payment_mode: mode,
          plan_id: selectedPlan.plan_id,
          platform_id: null,
          preferred_delivery_day: null,
          shipping_fee: '0.0',
          taxes: '0.0',
          coupon_code: coupon_code,
          subscription_amount:
            selectedPlan[`amount_${selectedPlan.charge_currency.toLowerCase()}` as amountsType],
          total:
            selectedPlan[`amount_${selectedPlan.charge_currency.toLowerCase()}` as amountsType],
        },
      };
      const createSubscription = await axios.post(
        `checkout/subscriptions/create`,
        subscriptionData
      );
      const { status, subscription } = createSubscription.data.data;

      const eventParams: TrackPlanSelectTypes = {
        plan_code: selectedPlan.code,
        payment_mode: mode,
      };
      trackPlanSelect(eventParams);

      dispatch({ type: actionTypes.SELECT_PAYMENT_MODE, payload: { mode, subscription } });
      dispatch({ type: actionTypes.SET_SCRATCH_LOADING, payload: status });
      return { status, data: subscription };
    } else {
      const subscriptionData = {
        subscription: {
          address_id: '',
          currency: selectedPlan.charge_currency,
          deliver_to_fullname: auth.learner.firstname + ' ' + auth.learner.lastname,
          deliver_to_phone: `+${auth.learner.dialing_code}${auth.learner.mobile}`,
          delivery_instruction: '',
          grade_group_id: selectedPlan.grade_group_id,
          grade_group_plan_id: selectedPlan.id,
          learner_id: auth.learner.id,
          notes: '',
          payment_gateway: 'flutterwave',
          payment_mode: mode,
          plan_id: selectedPlan.plan_id,
          platform_id: null,
          preferred_delivery_day: null,
          shipping_fee: '0.0',
          taxes: '0.0',
          subscription_amount:
            selectedPlan[`amount_${selectedPlan.charge_currency.toLowerCase()}` as amountsType],
          total:
            selectedPlan[`amount_${selectedPlan.charge_currency.toLowerCase()}` as amountsType],
        },
      };
      const createSubscription = await axios.post(
        `checkout/subscriptions/create`,
        subscriptionData
      );
      const { status, subscription } = createSubscription.data.data;

      const eventParams: TrackPlanSelectTypes = {
        plan_code: selectedPlan.code,
        payment_mode: mode,
      };
      trackPlanSelect(eventParams);

      dispatch({ type: actionTypes.SELECT_PAYMENT_MODE, payload: { mode, subscription } });
      dispatch({ type: actionTypes.SET_SCRATCH_LOADING, payload: status });
      return { status, data: subscription };
    }
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const initializePayment = ({
  email,
  referral,
}: {
  email: string;
  referral: string;
}): ThunkResult<any> => async (dispatch: Function, getState: Function): Promise<reduxResponse> => {
  try {
    const {
      subscribe: { subscription, autorenew },
    }: RootState = getState();

    const payment = {
      subscription_id: subscription.id,
      email,
      agent_id: referral,
      auto_renew: autorenew,
    };

    const initializedPayment = await axiosV2.post(`checkout/transaction/initiate`, payment);
    const { status, data } = initializedPayment.data.data;
    dispatch({ type: actionTypes.INITIALIZE_PAYMENT, payload: data });
    return { status, data };
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const completePayment = ({
  reference,
  gateWayId,
}: {
  reference: string;
  gateWayId: string;
}): ThunkResult<any> => async (dispatch: Function, getState: Function): Promise<reduxResponse> => {
  try {
    const payment = {
      reference,
      payment_gateway_id: gateWayId,
    };

    const completedPayment = await axiosV2.post(`checkout/transaction/response`, payment);
    const { status, message } = completedPayment.data.data;
    dispatch({ type: actionTypes.COMPLETE_PAYMENT });
    return { status, message };
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const scratchCardPayment = ({
  email,
  pin,
}: {
  email?: string;
  pin: string;
}): ThunkResult<any> => async (dispatch: Function, getState: Function): Promise<reduxResponse> => {
  try {
    const {
      subscribe: { subscription, autorenew },
      auth: { learner },
    }: RootState = getState();

    const payment = {
      subscription_id: subscription.id,
      email,
      pin,
      learner_id: learner.id,
      agent_id: null,
      auto_renew: autorenew,
    };

    const scratchCard = await axios.post(`checkout/transaction/scratch_card`, payment);
    const { status, message } = scratchCard.data.data;
    dispatch({ type: actionTypes.SCRATCH_CARD_PAYMENT });
    return { status, message };
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const momoTransferPayment = (e?: any): ThunkResult<any> => async (
  dispatch: Function,
  getState: Function
): Promise<reduxResponse> => {
  try {
    const {
      subscribe: { subscription },
      auth: { learner },
    }: RootState = getState();

    const payment = {
      subscription_id: subscription.id,
      email: e && e.email,
      dialing_code: e && e.dialingCode,
      mobile: e && e.phone,
      learner_id: learner.id,
      agent_id: e && e.referral,
    };

    const momoTransfer = await axios.post(`checkout/mobile_money_transfer`, payment);

    const { status, message, mobile_money_transfer_details } = momoTransfer.data.data;
    dispatch({ type: actionTypes.MOMO_TRANSFER, payload: mobile_money_transfer_details });
    return { status, message, data: mobile_money_transfer_details };
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const bankTransferPayment = (e?: any): ThunkResult<any> => async (
  dispatch: Function,
  getState: Function
): Promise<reduxResponse> => {
  try {
    const {
      subscribe: { subscription },
      auth: { learner },
    }: RootState = getState();

    const payment = {
      subscription_id: subscription.id,
      email: e && e.email,
      dialing_code: learner.dialing_code,
      mobile: learner.mobile,
      learner_id: learner.id,
      agent_id: e && e.referral,
    };

    const bankTransfer = await axios.post(`checkout/pay_at_bank_details`, payment);

    const { status, message, pay_at_bank_details } = bankTransfer.data.data;
    dispatch({ type: actionTypes.BANK_TRANSFER, payload: pay_at_bank_details });
    return { status, message, data: pay_at_bank_details };
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const getPaymentLink = (): ThunkResult<any> => async (
  dispatch: Function,
  getState: Function
): Promise<reduxResponse> => {
  try {
    const { subscription } = getState().subscribe;
    const payload = {
      subscription_id: subscription.id,
    };
    const completedPayment = await axiosV2.post(`checkout/transaction/url`, payload);
    const { status, data } = completedPayment.data.data;
    return { status, data };
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const getDashboardBanners = (): ThunkResult<any> => async (
  dispatch: Function,
  getState: Function
): Promise<reduxResponse> => {
  try {
    const {
      auth: {
        learner: { grade },
      },
    }: RootState = getState();
    const result = await axiosV2.get(`misc/dashboard-banners?grade_id=${grade.id}`);
    const { status, data } = result.data;
    dispatch({
      type: actionTypes.GET_DASHBOARD_BANNERS,
      payload: data,
    });
    return { status, data };
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};
