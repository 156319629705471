export const ACCOUNT_RESOLVED = 'ACCOUNT_RESOLVED';
export const OTP_SENT = 'OTP_SENT';
export const OTP_VERIFIED = 'OTP_VERIFIED';
export const SAVE_LEARNER = 'SAVE_LEARNER';
export const SAVE_LEARNERS = 'SAVE_LEARNERS';
export const LOGIN_COMPLETED = 'LOGIN_COMPLETED';
export const LOG_OUT = 'LOG_OUT';
export const CLOSE_ERROR = 'CLOSE_ERROR';
export const SET_DIMENSION = 'SET_DIMENSION';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SELECT_GRADE = 'SELECT_GRADE';
export const SET_AVATAR = 'SET_AVATAR';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const SET_PREMIUM = 'SET_PREMIUM';
export const FETCH_LEARNER = 'FETCH_LEARNER';
export const SELECT_LEARNER = 'SELECT_LEARNER';
export const VERIFY_RECAPTCHA = 'VERIFY_RECAPTCHA';
