import * as actionTypes from 'Actions/content/actionTypes';
import {
  questionCheckInterface,
  lessonQuestionsInterface,
  ILesson,
  practiceTest,
  searchResultInterface,
  IContentSubject,
  chapterTestInterface,
  testQuestionCheckInterface,
  practiceExamInterface,
  recentResultInterface,
  themeInterface,
  IQuest,
} from 'Types/content';

interface Action {
  type: string;
  payload: any;
}

interface IInitialState {
  loading: boolean;
  theme: themeInterface | null;
  subjects: IContentSubject[] | null;
  subject: IContentSubject | null;
  recent: recentResultInterface | null;
  practiceTests: practiceTest[];
  selectedLessons: ILesson[] | null;
  selectedQuests: IQuest[] | null;
  searchResults: searchResultInterface;
  lessonQuiz: lessonQuestionsInterface | null;
  chapterTest: chapterTestInterface[];
  practiceExam: practiceExamInterface[];
  checkLessonQuiz: questionCheckInterface | null;
  checkPracticeTest: questionCheckInterface | null;
  checkChapterTest: testQuestionCheckInterface | null;
  checkPracticeExam: testQuestionCheckInterface | null;
  video: any;
}

const initialState: IInitialState = {
  loading: false,
  theme: null,
  subjects: null,
  subject: null,
  recent: null,
  practiceTests: [],
  selectedLessons: null,
  selectedQuests: null,
  lessonQuiz: null,
  chapterTest: [],
  practiceExam: [],
  checkLessonQuiz: null,
  checkPracticeTest: null,
  checkChapterTest: null,
  checkPracticeExam: null,
  searchResults: {
    lessons: [],
    quests: [],
  },
  video: null,
};

const ContentReducer = (state = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.CONTENT_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.CONTENT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case actionTypes.FETCH_SUBJECTS: {
      return {
        ...state,
        loading: false,
        subjects: payload,
      };
    }
    case actionTypes.FETCH_LESSON_VIDEO: {
      return {
        ...state,
        video: payload,
      };
    }
    case actionTypes.FETCH_SUBJECT: {
      return {
        ...state,
        loading: false,
        subject: payload,
      };
    }
    case actionTypes.FETCH_THEMES: {
      return {
        ...state,
        loading: false,
        theme: payload,
      };
    }
    case actionTypes.FETCH_PRACTICE_TESTS: {
      return {
        ...state,
        loading: false,
        practiceTests: payload,
      };
    }
    case actionTypes.FETCH_LESSON: {
      return {
        ...state,
        loading: false,
        selectedLessons: payload,
      };
    }
    case actionTypes.FETCH_QUEST: {
      return {
        ...state,
        loading: false,
        selectedQuests: payload,
      };
    }
    case actionTypes.FETCH_LESSON_BYPASS: {
      return {
        ...state,
        loading: false,
      };
    }
    case actionTypes.FETCH_QUEST_BYPASS: {
      return {
        ...state,
        loading: false,
      };
    }
    case actionTypes.SEARCH_RESULTS: {
      return {
        ...state,
        loading: false,
        searchResults: payload,
      };
    }
    case actionTypes.FETCH_LESSON_QUESTIONS: {
      return {
        ...state,
        loading: false,
        lessonQuiz: payload,
      };
    }
    case actionTypes.CHECK_LESSON_QUESTION_OPTION: {
      return {
        ...state,
        loading: false,
        checkLessonQuiz: payload,
      };
    }
    case actionTypes.CHECK_PRACTICE_TEST_OPTION: {
      return {
        ...state,
        loading: false,
        checkPracticeTest: payload,
      };
    }
    case actionTypes.FETCH_CHAPTER_TEST: {
      return {
        ...state,
        loading: false,
        chapterTest: payload,
      };
    }
    case actionTypes.ANALYZE_CHAPTER_TEST: {
      return {
        ...state,
        loading: false,
        chapterTest: state.chapterTest.filter(test => {
          if (test.id === payload.id) return payload;
          else return test;
        }),
      };
    }
    case actionTypes.CHECK_CHAPTER_TEST_OPTION: {
      return {
        ...state,
        loading: false,
        checkChapterTest: payload,
      };
    }
    case actionTypes.FETCH_PRACTICE_EXAMS: {
      return {
        ...state,
        loading: false,
        practiceExam: payload,
      };
    }
    case actionTypes.ANALYZE_PRACTICE_EXAM: {
      return {
        ...state,
        loading: false,
        practiceExam: state.practiceExam.filter(exam => {
          if (exam.id === payload.id) return payload;
          else return exam;
        }),
      };
    }
    case actionTypes.CHECK_PRACTICE_EXAM_OPTION: {
      return {
        ...state,
        loading: false,
        checkPracticeExam: payload,
      };
    }
    case actionTypes.FETCH_RECENT_DATA: {
      return {
        ...state,
        loading: false,
        recent: payload,
      };
    }
    case actionTypes.LOG_OUT: {
      return {
        ...state,
        loading: false,
        subjects: null,
        subject: null,
        theme: null,
        recent: null,
        practiceTests: [],
        selectedLessons: null,
        selectedQuests: null,
        lessonQuiz: null,
        chapterTest: [],
        practiceExam: [],
        checkLessonQuiz: null,
        checkPracticeTest: null,
        checkChapterTest: null,
        checkPracticeExam: null,
        searchResults: {
          lessons: [],
          quests: [],
        },
      };
    }
    default:
      return state;
  }
};

export default ContentReducer;
