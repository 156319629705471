import React, { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getPageTitle, trackPageView, trackSegmentEvent } from 'Utils/analytics';

interface AnonymousProps {
  children: ReactNode;
}

export const Anonymous = ({ children }: AnonymousProps) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((value: any) => {
      trackPageView({
        url: value.pathname,
        title: getPageTitle(value.pathname),
      });
    });
    return () => unlisten();
  }, [history, trackSegmentEvent]);

  return <>{children}</>;
};

export default Anonymous;
