export const SET_GRADE = 'SET_GRADE';
export const ALL_PLANS = 'ALL_PLANS';
export const SELECT_PLAN = 'SELECT_PLAN';
export const SELECT_PAYMENT_MODE = 'SELECT_PAYMENT_MODE';
export const SET_SCRATCH_LOADING = 'SET_SCRATCH_LOADING';
export const INITIALIZE_PAYMENT = 'INITIALIZE_PAYMENT';
export const COMPLETE_PAYMENT = 'COMPLETE_PAYMENT';
export const SCRATCH_CARD_PAYMENT = 'SCRATCH_CARD_PAYMENT';
export const MOMO_TRANSFER = 'MOMO_TRANSFER';
export const BANK_TRANSFER = 'BANK_TRANSFER';
export const GET_DASHBOARD_BANNERS = 'GET_DASHBOARD_BANNERS';
export const LOG_OUT = 'LOG_OUT';
