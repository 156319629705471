import React from 'react';

import ModalAtom from 'Components/ModalAtom';
import InfoIcon from 'Components/Icons/Errors/Info';

import './_browserIncompatible.scss';

interface BrowserIncompatibleProps {
  isOpen: boolean;
}

export const BrowserIncompatible = ({ isOpen }: BrowserIncompatibleProps) => {
  return (
    <ModalAtom isOpen={isOpen}>
      <div className="BrowserIncompatible -body">
        <InfoIcon />

        <h2>Oops! Incompatible browser</h2>
        <p>
          You are using a web browser that’s not supported. Please use one of{' '}
          <a href="https://www.google.com/chrome/" target="__blank">
            Google Chrome’s latest versions
          </a>{' '}
          for optimal experience
        </p>
      </div>
    </ModalAtom>
  );
};

export default BrowserIncompatible;
