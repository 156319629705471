import { FlagBodyType, FlagNameTypes } from "../types"

export const flagsDefault:Record<FlagNameTypes, FlagBodyType> = {
    identity: {
      description: "The unauthenticated routes for the identity feature",
      active: false
    },
    restyle: {
      description: "Toggle the use of the Chakra UI libary",
      active: false
    },
    videoLibraryUiRework: {
      description: "Chapter design update from list to grid view. JIRA UL-586",
      active: false
    },
    oneToOnePremium: {
      description: "Premium subscription / One to One content is displayed or not",
      active: false
    }
  }