export const CHAT_INIT = 'CHAT_INIT';
export const CHAT_FAIL = 'CHAT_FAIL';
export const ANY_IN_APP = 'ANY_IN_APP';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const GET_ALL_QUESTIONS = 'GET_ALL_QUESTIONS';
export const TOGGLE_JOIN_MODAL = 'TOGGLE_JOIN_MODAL';
export const GET_LIVE_LESSONS = 'GET_LIVE_LESSONS';
export const GET_MY_LESSONS = 'GET_MY_LESSONS';
export const GET_PROMOTED_LESSONS = 'GET_PROMOTED_LESSONS';
export const TOGGLE_REMIND_MODAL = 'TOGGLE_REMIND_MODAL';
export const SELECT_LIVE_LESSON = 'SELECT_LIVE_LESSON';
export const TOGGLE_LIMIT_LIVE_LESSON = 'TOGGLE_LIMIT_LIVE_LESSON';
export const SET_JOIN_NOW = 'SET_JOIN_NOW';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_PAGE = 'GET_NOTIFICATIONS_PAGE';
export const SET_CODING_SCHOOL_MODAL = 'SET_CODING_SCHOOL_MODAL';
export const LOG_OUT = 'LOG_OUT';
