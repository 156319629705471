import React, { ReactNode } from 'react';
import { TouchableOpacity, ImageBackground } from 'react-native';
import CloseIcon from 'Components/Icons/General/Close';

interface ModalAtomProps {
  children: ReactNode;
  isOpen: boolean;
  onClose?: Function | undefined;
  isClosable?: boolean;
  closeDist?: 'Far' | 'Near';
  showBg?: boolean;
}

export const ModalAtom = ({
  children,
  isOpen,
  isClosable,
  closeDist,
  onClose = () => {},
  showBg,
}: ModalAtomProps) => {
  const closeAction = () => {
    if (isClosable && typeof onClose === 'function') {
      onClose();
    }
  };

  if (isOpen) {
    return (
      <TouchableOpacity
        style={{
          backgroundColor: 'rgba(0, 0, 0, .5)',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 2000,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onPress={closeAction}
        activeOpacity={1}>
        {isClosable && (
          <CloseIcon style={{ marginRight: -450, marginBottom: 0 }} onClick={closeAction} />
        )}
        <TouchableOpacity
          style={{
            height: 500,
            width: 500,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          activeOpacity={1}>
          {showBg ? (
            children
          ) : (
            <ImageBackground
              source={require('Assets/Images/Modal/modal-body.svg').default}
              style={{
                height: 450,
                width: 450,
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 21,
              }}
              resizeMode="contain">
              {children}
            </ImageBackground>
          )}
        </TouchableOpacity>
      </TouchableOpacity>
    );
  } else {
    return null;
  }
};

export default ModalAtom;

/*export const ModalAtom = ({ children, isOpen, isClosable, closeDist, onClose }: ModalAtomProps) => {
  return (
    <div className="ModalAtom">
      <div className={`ModalAtom__backdrop ${isOpen && '-open'}`} onClick={onClose}>
        {isOpen && isClosable && closeDist === 'Far' && (
          <CloseIcon className="ModalAtom__close -far" onClick={onClose} />
        )}
        <div className={`ModalAtom__body ModalAtom__body--small ${isOpen && '-open'}`}>
          {isOpen && isClosable && closeDist === 'Near' && (
            <CloseIcon className="ModalAtom__close -near" onClick={onClose} />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalAtom;*/
