import * as actionTypes from '../Actions/home/actionTypes';

interface Action {
  type: string;
  payload: any;
}

const initialState: any = {
  error: false,
  loading: false,
  initialAssetsLoaded: false,
  content: [],
  recommended: [],
  searchIndex: null,
  searchHistory: {},
  meta: {},
  subjects: [],
  quests: [],
  chaptersList: { lessonsLength: 0 },
  exams: [],
  tests: [],
  selectedExamSubject: '',
  examsList: [],
  selectedExam: {},
  selectedTest: {},
  active: '/home',
  currentLessonQuiz: [],
  lessonsTotal: [],
  selectedChapterTests: [],
  selectedTestChapterId: null,
  attemptedTestIds: [],
  totalQuestCount: 0,
  totalTestCount: 0,
  gradesByCountry: [],
  defaultGrade: {},
  contentLoading: false,
  selectedGrade: {},
  dashboardBanners: [],
  mockExams: [],
  allExams: [],
};

const homeReducer = (state = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.HOME_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.HOME_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case actionTypes.GET_MOCK_EXAMS: {
      return {
        ...state,
        mockExams: action.payload,
      };
    }
    case actionTypes.GET_ALL_EXAMS: {
      return {
        ...state,
        allExams: action.payload,
      };
    }
    case actionTypes.GRADE_CONTENT: {
      return {
        ...state,
        ...payload,
        contentLoading: true,
        loading: false,
        error: false,
      };
    }
    case actionTypes.GRADE_BY_COUNTRY: {
      return {
        ...state,
        gradesByCountry: payload.allgrades,
        defaultGrade: payload.defaultGrade,
      };
    }
    case actionTypes.SELECT_SUBJECT: {
      return {
        ...state,
        chaptersList: payload,
      };
    }
    case actionTypes.ERROR_CONTENT: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case actionTypes.RETRY_CONTENT: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case actionTypes.CLOSE_ERROR: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case actionTypes.SELECT_EXAM_SUBJECT: {
      return {
        ...state,
        examsList: payload,
        selectedExamSubject: payload.length > 0 && payload[0].subject ? payload[0].subject : '',
      };
    }
    case actionTypes.CLOSE_SELECT_EXAM: {
      return {
        ...state,
        examsList: [],
        selectedExamSubject: '',
      };
    }
    case actionTypes.SELECT_EXAMS: {
      return {
        ...state,
        selectedExam: payload,
        examsList: [],
        selectedExamSubject: '',
      };
    }
    case actionTypes.SELECT_TESTS: {
      return {
        ...state,
        selectedTest: payload,
      };
    }
    case actionTypes.SAVE_LESSONS: {
      return {
        ...state,
        lessonsTotal: [...state.lessonsTotal, ...payload],
      };
    }
    case actionTypes.SAVE_SEARCH_HISTORY: {
      let data = state.searchHistory[payload.gradeId] || [];
      let newSearchData: any = {};
      data.push(payload.searchData);
      newSearchData[payload.gradeId] = data;
      return {
        ...state,
        searchHistory: { ...state.searchHistory, ...newSearchData },
      };
    }
    case actionTypes.SAVE_SEARCH_INDEX: {
      return {
        ...state,
        searchIndex: payload,
      };
    }
    case actionTypes.UPDATE_ASSET_LOAD: {
      return {
        ...state,
        initialAssetsLoaded: payload.assetStatus,
      };
    }
    case actionTypes.SET_DONGLE_MODAL: {
      return {
        ...state,
        dongleState: payload,
      };
    }
    case actionTypes.SELECT_CHAPTER_TESTS:
      return {
        ...state,
        selectedChapterTests: payload.tests,
        selectedTestChapterId: payload.chapterId,
      };
    case actionTypes.UPDATE_ATTEMPTED_TEST_IDS:
      return {
        ...state,
        attemptedTestIds: [...state.attemptedTestIds, payload.testId],
      };
    case actionTypes.SET_TEST_AND_QUEST_COUNT:
      return {
        ...state,
        totalQuestCount: payload.questCount,
        totalTestCount: payload.testCount,
      };
    case actionTypes.SELECT_GRADE:
      return {
        ...state,
        selectedGrade: {
          display_name: payload.display_name,
          grade_id: payload.id,
        },
      };
    case actionTypes.GET_DASHBOARD_BANNERS:
      return {
        ...state,
        dashboardBanners: payload,
      };
    case actionTypes.GET_AVATARS: {
      return {
        ...state,
        avatars: action.payload,
      };
    }
    case actionTypes.LOG_OUT:
      return {
        error: false,
        loading: true,
        initialAssetsLoaded: false,
        content: [],
        recommended: [],
        searchIndex: null,
        searchHistory: {},
        meta: {},
        subjects: [],
        quests: [],
        chaptersList: { lessonsLength: 0 },
        exams: [],
        selectedExamSubject: '',
        examsList: [],
        selectedExam: {},
        active: '/home',
        currentLessonQuiz: [],
        lessonsTotal: [],
        selectedChapterTests: [],
        selectedTestChapterId: null,
        attemptedTestIds: [],
        totalQuestCount: 0,
        totalTestCount: 0,
        gradesByCountry: [],
        defaultGrade: {},
        contentLoading: false,
        selectedGrade: '',
        dashboardBanners: [],
      };
    default:
      return state;
  }
};

export default homeReducer;
