import ReactPixel from 'react-facebook-pixel';
import { reactPixelEnabled, reactPixelKey } from 'config';

const trackingEnabled: boolean = Boolean(reactPixelEnabled && reactPixelKey);

export const initReactPixel = (): void => {
  if (trackingEnabled) {
    ReactPixel.init(reactPixelKey || '');
  }
};

export const trackReactPixelEvent = (
  eventName: string,
  eventParams?: { [key: string]: any }
): void => {
  if (trackingEnabled) {
    ReactPixel.trackCustom(eventName, eventParams);
  }
};
