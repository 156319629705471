import sha256 from 'crypto-js/sha256';
import * as actionTypes from 'Actions/auth/actionTypes';
import axiosInstance from '../../Utils/axios-orders';
import { RootState } from 'Reducers';
import { storeItem, removeItem, retrieveItem, catchHelper } from '../../Functions';
import * as constants from '../../Utils/constants';
import { editLearnerInterface, reduxResponse, ThunkResult } from 'Types/general';
import { persistor } from 'Store';
import { trackCreateLearnerAccount, trackLogin, trackLogout } from 'Utils/tracking';
import { captureException } from 'Utils/sentry';
import { VerifyOTPData } from './interfaces';
import { logoutSegmentUser, trackIdentifyUser, trackSegmentEvent } from '../../Utils/analytics';

const axios = axiosInstance();

const accountResolved = (
  accountStatus: string,
  responseStatus: string,
  responseMessage: string,
  code: string,
  mobile: string
) => {
  return {
    type: actionTypes.ACCOUNT_RESOLVED,
    payload: {
      accountStatus: accountStatus,
      responseStatus: responseStatus,
      message: responseMessage,
      dialingCode: code,
      mobile: mobile,
    },
  };
};

const otpSent = (status: string) => {
  return {
    type: actionTypes.OTP_SENT,
    payload: {
      status: status,
    },
  };
};

const otpVerified = (status: string, learners: [], authToken: string, gameToken: string) => {
  return {
    type: actionTypes.OTP_VERIFIED,
    payload: {
      status: status,
      learners: learners,
      authToken,
      gameToken,
    },
  };
};

const saveLearner = (learner: {}) => {
  return {
    type: actionTypes.SAVE_LEARNER,
    payload: {
      learner: learner,
    },
  };
};

export const saveLearners = (learners: []) => {
  return {
    type: actionTypes.SAVE_LEARNERS,
    payload: {
      learners: learners,
    },
  };
};

const loginDone = (
  learner: {},
  learners: Array<Object>,
  authToken: string,
  gameToken?: string,
  tutor?: any
) => {
  return {
    type: actionTypes.LOGIN_COMPLETED,
    payload: {
      learner,
      learners,
      tutor,
      authToken,
      gameToken,
    },
  };
};

const closeError = () => {
  return {
    type: actionTypes.CLOSE_ERROR,
  };
};

export const resolveAccount = (data: any) => async (dispatch: Function, getState: Function) => {
  axios.defaults.headers['device-uuid'] = getState().main.uuid;
  const result = await axios
    .post('resolve_account_status', data)
    .then(response => {
      const { status, message, account_status, dialing_code, mobile } = response.data.data;
      dispatch(accountResolved(account_status, status, message, dialing_code, mobile));
      return { accountStatus: account_status, authStatus: status, authMessage: message };
    })
    .catch(error => catchHelper(error, dispatch, true));
  if (result) {
    return result;
  }
  return false;
};

export const verifyVirtualNumber = (data: any) => async (
  dispatch: Function,
  getState: Function
) => {
  axios.defaults.headers['device-uuid'] = getState().main.uuid;
  const result = await axios
    .post('verify_virtual_number', data)
    .then(response => {
      return response.data.data;
    })
    .catch(error => catchHelper(error, dispatch, true));
  if (result) {
    return result;
  }
  return false;
};

export const sendOtp = (data: any) => async (dispatch: Function, getState: Function) => {
  setNonceHeaders(getState().main.uuid);

  const result = await axios
    .post('send_otp', data)
    .then(response => {
      dispatch(otpSent(response.data.data.status));
      return {
        status: response.data.data.status,
        message: response.data.data.message,
        data: response.data.data.data,
      };
    })
    .catch(error => catchHelper(error, dispatch, true));

  if (result) {
    return result;
  }
  return false;
};

export const sendWhatsAppOtp = (data: any) => async (dispatch: Function, getState: Function) => {
  setNonceHeaders(getState().main.uuid);

  const result = await axios
    .post('otp_on_whatsapp', data)
    .then(response => {
      dispatch(otpSent(response.data.data.status));
      return {
        status: response.data.data.status,
        message: response.data.data.message,
        data: response.data.data.data,
      };
    })
    .catch(error => catchHelper(error, dispatch, true));

  if (result) {
    return result;
  }
  return false;
};

export const createLearnerAccount = (data: any) => async (
  dispatch: Function,
  getState: Function
) => {
  const authToken = getState().auth.authToken;
  axios.defaults.headers['device-uuid'] = getState().main.uuid;

  const response = await axios
    .post('learners', data)
    .then(response => {
      const { status, message, learner } = response.data.data;
      axios.defaults.headers['auth-token'] = authToken;

      if (status === constants.ResponseStatus.Success) {
        trackCreateLearnerAccount(learner);
        trackIdentifyUser(learner);
        trackSegmentEvent('ue_registration_completed', null, learner);
      }

      dispatch(saveLearner(learner));
      storeItem(constants.StorageKeys.Learner, learner);

      return { status: status, message: message };
    })
    .catch(error => catchHelper(error, dispatch, true));
  if (response) return response;
  return { status: 'error' };
};

export const loginUser = (data: any) => async (dispatch: Function, getState: Function) => {
  setNonceHeaders(getState().main.uuid);
  const response = await axios
    .post('login', data)
    .then(response => {
      const {
        status,
        message,
        learners,
        tutor,
        auth_token,
        account_type,
        game_token,
      } = response.data.data;
      axios.defaults.headers['auth-token'] = auth_token;
      const learnerId = learners && learners.length > 0 ? learners[0].id : '';
      const learner = learners[0];

      if (status === constants.ResponseStatus.Success) {
        trackLogin(learner);
        trackIdentifyUser(learner);
        trackSegmentEvent('ue_otp_status', null, learner);
      }

      dispatch(loginDone({ ...learner, game_token }, learners, auth_token, game_token, tutor));

      storeItem(constants.StorageKeys.Learners, learners);
      storeItem(constants.StorageKeys.Tutor, tutor);
      storeItem(constants.StorageKeys.AuthToken, auth_token);

      return {
        status: status,
        message: message,
        learnerId: learnerId,
        tutor: tutor,
        accountType: account_type,
      };
    })
    .catch(error => {
      captureException(error);
      catchHelper(error, dispatch, true);
    });
  if (response) return response;
  return { status: 'error' };
};
export const selectLearner = (learnerId: string) => async (
  dispatch: Function,
  getState: Function
) => {
  setNonceHeaders(getState().main.uuid);
  const response = await axios
    .get(`learners/${learnerId}/select`)
    .then(response => {
      const { status, message, learner } = response.data.data;
      storeItem(constants.StorageKeys.Learner, learner);
      const learners = [learner];
      storeItem(constants.StorageKeys.Learners, learners);
      dispatch({ type: actionTypes.SELECT_LEARNER });
      return { status: status, message: message };
    })
    .catch(error => catchHelper(error, dispatch, true));
  if (response) return response;
  return { status: 'error' };
};

export const resetPassword = (data: any) => async (dispatch: Function) => {
  const response = await axios
    .post('reset_password', data)
    .then(response => {
      const { status, message } = response.data.data;
      dispatch({ type: actionTypes.RESET_PASSWORD });
      return { status: status, message: message };
    })
    .catch(error => catchHelper(error, dispatch, true));
  if (response) return response;
  return { status: 'error' };
};

export const verifyOTP = (data: VerifyOTPData) => async (
  dispatch: Function,
  getState: Function
) => {
  try {
    setNonceHeaders(getState().main.uuid);
    const result = await axios
      .post('verify_otp', data)
      .then(response => {
        const { status, learners, auth_token, game_token } = response.data.data;
        axios.defaults.headers['auth-token'] = auth_token;
        dispatch(otpVerified(status, learners, auth_token, game_token));
        storeItem(constants.StorageKeys.AuthToken, auth_token);
        storeItem(constants.StorageKeys.Learners, learners);
        return { status, learners, auth_token };
      })
      .catch(error => {
        captureException(error);
        catchHelper(error, dispatch, true);
      });

    if (result) {
      return result;
    }

    return false;
  } catch (error) {
    captureException(error);
    return false;
  }
};

export const verifyResetPasswordOTP = (data: any) => async (dispatch: Function) => {
  const result = await axios
    .post('verify_otp', data)
    .then(response => {
      const { status, auth_token } = response.data.data;
      axios.defaults.headers['auth-token'] = auth_token;
      return { status };
    })
    .catch(error => catchHelper(error, dispatch, true));
  if (result) {
    return result;
  }
  return { status: 'error' };
};

export const logOut = () => async (dispatch: Function, getState: Function) => {
  const authToken = getState().auth.authToken;
  const learner_id = getState().auth.learner.id;

  axios.defaults.headers['auth-token'] = authToken;
  axios.defaults.headers['device-uuid'] = getState().main.uuid;

  trackLogout();
  logoutSegmentUser();

  if (authToken) trackSegmentEvent('ue_logout');

  await removeItem(constants.StorageKeys.AuthToken);
  await removeItem(constants.StorageKeys.Learners);
  await removeItem(constants.StorageKeys.Learner);
  await removeItem(constants.StorageKeys.AppConfig);
  await storeItem(`coding_school_small_${learner_id}`, learner_id);

  localStorage.removeItem('freeTrialOpened_' + learner_id);

  await persistor.purge();

  axios
    .delete(`logout`)
    .then(a => {})
    .catch(error => captureException(error));

  dispatch({ type: actionTypes.LOG_OUT });
};

export const persistentLogin = (auth_token: string, learners: Array<Object>) => (
  dispatch: Function,
  getState: Function
) => {
  axios.defaults.headers['auth-token'] = auth_token;
  axios.defaults.headers['device-uuid'] = getState().main.uuid;
  dispatch(loginDone(learners[0], learners, auth_token));
};

const setNonceHeaders = (uuid: string) => {
  const nonce = Math.floor(Date.now() / 1000).toString();
  const signature = sha256(nonce + constants.SignatureSecret).toString();
  axios.defaults.headers['device-uuid'] = uuid;
  axios.defaults.headers['nonce'] = nonce;
  axios.defaults.headers['signature'] = signature;
};

export const verifyToken = () => async (dispatch: Function, getState: Function) => {
  const { authToken } = getState().auth;
  axios.defaults.headers['auth-token'] = authToken;
  const resp = await axios
    .post('login/validate')
    .then(response => {
      const { status, message, learner } = response.data.data;
      const msg = `${status === 'error' ? 'expired' : status}`;
      if (status === 'error') {
        dispatch(closeError());
      }
      return { status: msg, message, verified: true, learner };
    })
    .catch(error => {
      captureException(error);
      const response = error.response;
      dispatch(closeError());
      if (response && response.data.data) {
        const { status } = response.data.data;
        const msg = `${status === 'error' ? 'expired' : 'error'}`;
        return { status: msg, message: 'An error occured' };
      }
      return { status: 'error', message: 'An error occured', verified: false };
    });
  if (resp) return resp;
};

export const sendCallOtp = (data: any) => async (dispatch: Function, getState: Function) => {
  setNonceHeaders(getState().main.uuid);
  const result = await axios
    .post('otp_on_call', data)
    .then(response => {
      const { status } = response.data.data;
      return { status };
    })
    .catch(error => {
      captureException(error);
      catchHelper(error, dispatch, true);
    });
  if (result) {
    return result;
  }
  return { status: 'error' };
};

export const editProfile = (learner: editLearnerInterface): ThunkResult<any> => {
  return async (dispatch: any, getState: any): Promise<reduxResponse> => {
    setNonceHeaders(getState().main.uuid);
    const learnerId = getState().auth.learner.id;
    const result = await axios
      .post(`learners/${learnerId}`, learner)
      .then(response => {
        dispatch({
          type: actionTypes.EDIT_PROFILE,
          payload: response.data.data.learner,
        });
        const { status } = response.data.data;
        return { status };
      })
      .catch(error => {
        captureException(error);
        catchHelper(error, dispatch);
      });
    if (result) return result;

    return { status: 'error' };
  };
};

export const setDimension = ({ window, screen }: any) => (dispatch: any, getState: any) => {
  dispatch({
    type: actionTypes.SET_DIMENSION,
    payload: {
      height: window.height,
      width: window.width,
      device: window.width < 992 ? true : false,
    },
  });
};

export const setAvatar = (id: number) => (dispatch: any, getState: any) => {
  dispatch({
    type: actionTypes.SET_AVATAR,
    payload: id,
  });
};

export const setPremium = () => (dispatch: any, getState: any) => {
  dispatch({
    type: actionTypes.SET_PREMIUM,
    payload: true,
  });
};

export const fetchLearner = ({ mobile }: { mobile: string }): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    const learnerData = await axios.get(`learners/fetch?mobile=${mobile}`);
    const { status, learner } = learnerData.data.data;
    dispatch({ type: actionTypes.FETCH_LEARNER, payload: { learner } });
    return { status, data: learner };
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const verifyRecaptcha = (recaptchaToken: string) => async (
  dispatch: Function,
  getState: Function
) => {
  try {
    setNonceHeaders(getState().main.uuid);
    const response = await axios
      .post(`recaptcha/token/verify`, {
        gRecaptchaResponse: recaptchaToken,
      })
      .then(response => {
        if (response.status === 200) {
          dispatch({ type: actionTypes.VERIFY_RECAPTCHA, payload: recaptchaToken });
          return { status: 'success', message: 'success' };
        } else {
          return { status: 'error' };
        }
      })
      .catch(error => {
        captureException(error);
        catchHelper(error, dispatch, true);
      });
    if (response) return response;
    return { status: 'error' };
  } catch (error) {
    captureException(error);
    return { status: 'error' };
  }
};
