import React from 'react';
import * as Sentry from '@sentry/react';
import { Router } from 'Navigation/Router/index.web';
import Routes from 'Navigation/Routes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './Store';
import ErrorBoundary from 'Pages/ErrorBoundary';
import { ChakraProvider } from '@chakra-ui/react';
import { Feature, FlagsProvider } from 'flagged';
import { flags } from 'Config/FeatureFlags/flags';

const App = () => {
  return (
    <FlagsProvider features={flags}>
      <Feature
        name="restyle"
        render={(isEnabled: boolean) =>
          isEnabled ? (
            <ChakraProvider>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <Router>
                    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
                      <Routes />
                    </Sentry.ErrorBoundary>
                  </Router>
                </PersistGate>
              </Provider>
            </ChakraProvider>
          ) : (
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <Router>
                  <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
                    <Routes />
                  </Sentry.ErrorBoundary>
                </Router>
              </PersistGate>
            </Provider>
          )
        }
      />
    </FlagsProvider>
  );
};

export default App;
