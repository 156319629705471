import { AppRegistry } from 'react-native';
import App from './App';
import './Styles/main.scss';
import firebase from 'firebase/app';
import { init as initSentry } from './Utils/sentry';
import { logError, logMessage } from 'Utils/logger';
import { initTracking } from './Utils/tracking/';
import { firebaseConfig } from 'config';

if ('serviceWorker' in navigator) {
  // Register service worker
  window.addEventListener('load', function () {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/service-worker.js`)
      .then(function (reg) {
        logMessage('SW registration succeeded. Scope is ' + reg.scope);
      })
      .catch(function (err) {
        logError('SW registration failed with error ' + err);
      });
  });
}

firebase.initializeApp(firebaseConfig);

//  TODO: Abstract move sentry to tracking file
initSentry();
initTracking();

AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', { rootTag: document.getElementById('root') });
