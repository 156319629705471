import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import * as urls from 'Navigation/UrlStore.json';

import Button from 'Components/Form/Button';
import ErrorBoundaryIcon from 'Components/Icons/Errors/ErrorBoundary';

import './_errorBoundary.scss';

export class ErrorBoundary extends Component<RouteComponentProps> {
  render() {
    const { history } = this.props;

    return (
      <div className="ErrorBoundary -body">
        <div className="ErrorBoundary -content">
          <div className="ErrorBoundary -content-icon">
            <ErrorBoundaryIcon />
          </div>

          <div className="ErrorBoundary -content-text">
            <h1>Oops... Something went wrong</h1>
            <p>
              Lets take you to a safe place <br /> while we work on it.
            </p>
          </div>
          <div className="ErrorBoundary -content-btn">
            <Button
              className="Button-brand"
              image={require('Assets/Images/Button/not-found-btn.svg').default}
              onClick={() => {
                setTimeout(() => {
                  window.location.reload();
                }, 45);
                history.replace(urls.Home);
              }}>
              Take Me Home
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ErrorBoundary);
