import moment from 'moment';
import { logOut } from 'Actions';
import _ from 'lodash';

export const catchHelper = (error: any, dispatch: Function, ignore?: boolean) => {
  const response = error && error.response ? error.response : null;
  if (response && response.status && [401, 403].includes(response.status) && dispatch && !ignore) {
    return dispatch(logOut());
  }
  if (
    response &&
    response.data &&
    response.data.data &&
    response.data.data.data &&
    response.data.data.message
  ) {
    return { status: 'error', message: response.data.data.message, data: response.data.data.data };
  } else if (response && response.data.data && response.data.data.message) {
    return { status: 'error', message: response.data.data.message };
  } else if (response) {
    return { status: 'error', message: 'An error occured. Try again later' };
  }
  return {
    status: 'error',
    message:
      error && error.message
        ? 'Sorry! Connection error. Try again.'
        : 'An error occured. Try again later',
  };
};

export const checkIsNumeric = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const calculatePercentage = (score: number, total: number): number => {
  if (score === 0 || total === 0) {
    return 0;
  } else {
    const percentage = (score / total) * 100;
    return Math.round(percentage);
  }
};

export const shuffle = (array: Array<any>) => {
  const a = array.slice();
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

export const secondsToHms = (d: number) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay: any = h > 0 ? h + ':' : '';
  var mDisplay: any = m > 0 ? m + ':' : m === 0 ? '00:' : '00:';
  var sDisplay: any = s > 0 ? s : s === 0 ? '00' : '';

  if (hDisplay > 0 && hDisplay <= 9) hDisplay = '0' + hDisplay;
  if (mDisplay > 0 && mDisplay <= 9) mDisplay = '0' + mDisplay;
  if (sDisplay > 0 && sDisplay <= 9) sDisplay = '0' + sDisplay;
  return hDisplay + mDisplay + sDisplay;
};

export const timeSince = (utcTime: any) => {
  let then = moment(utcTime, 'DD/MM/YYYY HH:mm:ss');
  let now = moment().utc();

  let thenString = then.format('DD/MM/YYYY HH:mm:ss');
  let nowString = now.format('DD/MM/YYYY HH:mm:ss');

  now = moment(nowString, 'DD/MM/YYYY HH:mm:ss');
  then = moment(thenString, 'DD/MM/YYYY HH:mm:ss');

  thenString = then.format('DD/MM/YYYY HH:mm:ss');
  nowString = now.format('DD/MM/YYYY HH:mm:ss');

  const elapsedTime = moment(now.valueOf() - then.valueOf()).utc();
  const months = parseInt(elapsedTime.format('M')) - 1;
  const days = parseInt(elapsedTime.format('D')) - 1;
  const hours = parseInt(elapsedTime.format('HH'));
  const minutes = parseInt(elapsedTime.format('mm'));
  const seconds = parseInt(elapsedTime.format('ss'));

  const statement = (value: number, type: string) => {
    return `${Number(value)} ${type}${value > 1 ? 's' : ' '} ago`;
  };

  if (months > 0) {
    return statement(months, 'month');
  }

  if (days > 0) {
    return statement(days, 'day');
  }

  if (hours > 0) {
    return statement(hours, 'hour');
  }

  if (minutes > 0) {
    return statement(minutes, 'min');
  }

  if (seconds > 0) {
    return statement(seconds, 'sec');
  } else {
    return 'just now';
  }
};
