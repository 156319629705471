export const colors = {
  lightGray: '#E5E5E5',
  darkGray: '#313848', // rgb(49, 56, 72)
  modalText: '#3D4352',
  anotherGray: '#9b9b9b',
  similarWhite: '#FCFBF9',
  inputBar: '#595f6c',
  phoneBorder: '#545965',
  white: '#FFFFFF',
  black: '#000000',
  darkOrange: '#EA7052', // rgb(234, 112, 82)
  darkerOrange: '#E65D47',
  lightOrange: '#E48E45', // rgb(228, 142, 69)
  Orange: '#FB9653',
  green: '#4DA47E', // rgb(77, 164, 126)
  darkerGreen: '#55B18A',
  purple: '#7B7FDA', // rgb(123, 127, 218)
  darkerPurple: '#646AD5',
  transparent: 'transparent',
  gray: '#9b9b9b',
  red: '#DA0000',
  lightRed: '#EA5478',
  yellow: '#F9AD6D',
  bio: '#68BC98',
  chem: '#FCA964',
  darkBlue: '#506AAC',
  juniorMaths: '#F15B5E',
  basicScience: '#5CBBF3',
  basicTech: '#916899',
  businessStudies: '#EFBC37',
  gradientDarkOrange: 'rgba(234, 112, 82, 0.103)',
  gradientPurple: 'rgba(123, 128, 218, 0.103)',
  gradientYellow: 'rgba(249, 172, 109, 0.116)',
  gradientGreen: 'rgba(104, 188, 152, 0.158)',
  gradientDarkBlue: 'rgba(80, 106, 172, 0.13)',
  gradientJuniorMaths: 'rgba(241, 91, 94, 0.137)',
  gradientBasicScience: 'rgba(92, 188, 243, 0.13)',
  gradientBasicTech: 'rgba(145, 104, 153, 0.11)',
  gradientBusinessStudies: 'rgba(239, 187, 55, 0.11)',
  lightestGreen: '#EBF2EB',
  lightestPink: '#FEF1F0',
  primaryMaths: '#3BB8A3',
  gradientPrimaryMaths: 'rgba(59, 184, 163, 0.103)',
  primaryEnglish: '#7876E9',
  gradientPrimaryEnglish: 'rgba(120, 118, 233, 0.103)',
  primaryScienceTech: '#EE6B6B',
  gradrientPrimaryScienceTech: 'rgba(238, 107, 107, 0.103)',
};
