import axios from 'axios';
import generateUniqueId from 'generate-unique-id';
import * as constant from './constants';
import { storeItem, retrieveItem } from '../Functions';
import { generateCorrelationId } from '../Functions';

const BASE_URL = process.env.REACT_APP_TUTOR_BASE_URL;
const BASE_URL_V2 = process.env.REACT_APP_TUTOR_BASE_URL_V2;

const axiosTutorInstance = (version: string = 'v1') => {
  const instance = axios.create({
    baseURL: version === 'v1' ? BASE_URL : BASE_URL_V2,
    headers: {
      language: 'en',
    },
  });

  instance.interceptors.request.use(
    async config => {
      const authToken = config.headers[constant.ApiHeaders.AuthToken];
      const deviceId = config.headers[constant.ApiHeaders.DeviceId];
      const correlationId = config.headers[constant.ApiHeaders.XCorrelationId];
      if (!authToken) {
        const savedAuthToken = await retrieveItem(constant.StorageKeys.AuthToken);
        config.headers[constant.ApiHeaders.AuthToken] = savedAuthToken;
      }
      if (!deviceId) {
        const savedDeviceId = await retrieveItem(constant.StorageKeys.DeviceId);
        if (savedDeviceId) {
          config.headers[constant.ApiHeaders.DeviceId] = savedDeviceId;
        } else {
          const id = generateUniqueId({
            length: 36,
            useLetters: true,
            useNumbers: true,
            includeSymbols: ['-'],
          });
          storeItem(constant.StorageKeys.DeviceId, id);
          config.headers[constant.ApiHeaders.DeviceId] = id;
        }
      }
      if (!correlationId) {
        const id = generateCorrelationId();
        config.headers[constant.ApiHeaders.XCorrelationId] = id;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default axiosTutorInstance;
