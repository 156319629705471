import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as urls from 'Navigation/UrlStore.json';
import './_learninganalysis.scss';
import PageHeader from 'Components/LearningAnalysis/PageHeader';
import { learnerAnalysisUrl } from 'Utils/constants';

const LearningAnalysis = ({ callback }: { callback: Function }) => {
  const frame: React.MutableRefObject<any> = useRef(null);
  const {
    auth: {
      authToken,
      learner: { encrypted_id, premium },
    },
  } = useSelector((state: any) => state);
  const [loaded, setLoaded] = useState('');
  const history = useHistory();
  const link = `${learnerAnalysisUrl}progress/all?learner=${encrypted_id}&is_premium_user=${premium}&token=${authToken}`;

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
      }}>
      {
        <div className="LearningAnalysis -header">
          <PageHeader heading="Learning Analysis" back={() => history.push(urls.Home)} />
        </div>
      }
      <iframe
        ref={frame}
        src={link}
        width={'100%'}
        height={'100%'}
        style={{ border: 'none' }}
        onLoad={e => {
          if (loaded !== '') {
            return callback();
          }
          setLoaded(e.currentTarget.src);
        }}
        title="Learning Analysis App"></iframe>
    </div>
  );
};

export default LearningAnalysis;
