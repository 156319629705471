import * as actionTypes from './actionTypes';
import axios from 'axios';
import axiosInstance from 'Utils/axios-orders';
import axiosTutorInstance from 'Utils/tutor-orders';
import { catchHelper, retrieveItem } from 'Functions';
import { RootState } from 'Reducers';
import * as urls from 'Navigation/UrlStore.json';
import { Platform } from 'react-native';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { subjectInterface } from 'Types/general';
import { captureException } from '../../Utils/sentry';
import { logInfo, logMessage, logWarn } from 'Utils/logger';
import { trackChatSessionStarted } from 'Utils/tracking/chat';
import { IContentSubject } from 'Types/content';

const axiosBase = axiosInstance();
const axiosBaseV2 = axiosInstance('v2');
const axiosTutorBase = axiosTutorInstance();
const axiosTutorBaseV2 = axiosTutorInstance('v2');

export const createUser = () => async (dispatch: Function, getState: Function) => {
  try {
    const {
      auth: { learner },
    }: RootState = getState();
    const learnerId: number = learner.id;
    const payload = {
      user_id: learnerId,
      nickname: learner.firstname,
      profile_url: null,
      issue_access_token: true,
    };
    const config = {
      headers: {
        'api-token': process.env.REACT_APP_SEND_BIRD_MASTER_TOKEN,
      },
    };
    const LINK = process.env.REACT_APP_SEND_BIRD_BASE_URL;
    if (learner.sendbird_token) {
      const updatedResult: any = await axios
        .put(
          `${LINK}users/${learner.id}`,
          {
            issue_access_token: true,
          },
          config
        )
        .catch(err => captureException(err));
      return dispatch(updateSendBirdToken({ sendbird_token: updatedResult.data.access_token }));
    }
    const res: any = await axios.post(`${LINK}users`, payload, config).catch(async err => {
      const updatedResult: any = await axios
        .put(
          `${LINK}users/${learner.id}`,
          {
            issue_access_token: true,
          },
          config
        )
        .catch(err => captureException(err));
      return updatedResult;
    });
    if (res && res.data && res.data.access_token)
      return dispatch(updateSendBirdToken({ sendbird_token: res.data.access_token }));
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const updateSendBirdToken = (payload: any) => async (
  dispatch: Function,
  getState: Function
) => {
  try {
    if (!payload.sendbird_token) return;
    const {
      auth: { learner },
    }: RootState = getState();
    const learnerId: number = learner.id;
    const response = await axiosBase.post(`learners/${learnerId}`, payload);
    dispatch({
      type: actionTypes.EDIT_PROFILE,
      payload:
        response.data && response.data.data && response.data.data.learner
          ? { ...learner, ...response.data.data.learner }
          : learner,
    });
    await dispatch(fetchQuestions());
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const createQuestion = (payload: any) => async (dispatch: Function, getState: Function) => {
  try {
    const {
      auth: { learner },
    }: RootState = getState();
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    };
    axiosTutorBaseV2.defaults.baseURL = process.env.REACT_APP_TUTOR_BASE_URL_V2;

    const response: any = await axiosTutorBaseV2.post(`questions/create`, payload, config);

    dispatch({
      type: actionTypes.EDIT_PROFILE,
      payload: {
        ...learner,
        bundle: {
          ...learner.bundle,
          no_of_sessions:
            learner.bundle && learner.bundle.no_of_sessions ? learner.bundle.no_of_sessions - 1 : 0,
        },
      },
    });

    logMessage(`CREATE_QUESTIONS ${response}`);
    await dispatch(fetchQuestions());
    return response.data;
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
    return error?.response?.data;
  }
};

export const loginSendBird = () => async (dispatch: Function, getState: Function) => {
  try {
    const {
      auth: { learner },
    }: RootState = getState();
    const learnerId: number = learner.id;
    logInfo(`loginSendBird success: ${learnerId}`);
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const fetchQuestions = () => async (dispatch: Function, getState: Function) => {
  try {
    const response: any = await axiosTutorBase
      .get(`questions/fetch`)
      .catch(error => captureException(error));

    if (response !== undefined && response.data) {
      const { status, data } = response.data;
      if (status === 'success') {
        const array = data.map((val: any) => {
          return {
            ...val,
            active: 'pending',
          };
        });
        dispatch({
          type: actionTypes.GET_ALL_QUESTIONS,
          payload: array,
        });
        return array;
      }
    }
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const startChatSession = (questionId: number, object: any) => async (
  dispatch: Function,
  getState: Function
) => {
  try {
    const response: any = await axiosTutorBase
      .post(`questions/${questionId}/chat/start`, object)
      .catch(error => captureException(error));

    await dispatch(fetchQuestions());

    return response;
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const toggleJoinModal = (a: boolean) => async (dispatch: Function, getState: Function) => {
  dispatch({
    type: actionTypes.TOGGLE_JOIN_MODAL,
    payload: a,
  });
};

export const rateTutor = (payload: any) => async (dispatch: Function, getState: Function) => {
  try {
    const response: any = await axiosTutorBase
      .post(`tutors/rate`, payload)
      .catch(error => captureException(error));

    return response;
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const getTutorProfile = (tutorId: number) => async (dispatch: Function) => {
  try {
    const id = `${tutorId}`.replace(/Tutor-/g, '');
    const response: any = await axiosTutorBase
      .get(`tutors/${id}`)
      .then(() => {
        trackChatSessionStarted();
      })
      .catch(error => captureException(error));
    return response.data?.data;
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const getFCMToken = () => async (dispatch: any, getState: any) => {
  try {
    const {
      auth: { learner },
    } = getState();
    const learnerId: number = learner.id;
    await axiosBase.post(`learners/${learnerId}`, {
      fcm_token: null,
      app_os: Platform.OS,
      app_version: '1.0.0',
    });
    const token = await firebase
      .messaging()
      .getToken()
      .catch(error => logWarn(error));
    if (token) {
      const response = await axiosBase.post(`learners/${learnerId}`, {
        fcm_token: token,
        app_os: Platform.OS,
        app_version: '1.0.0',
      });
      dispatch({
        type: actionTypes.EDIT_PROFILE,
        payload:
          response.data && response.data.data && response.data.data.learner
            ? { ...learner, ...response.data.data.learner }
            : learner,
      });
    }
  } catch (error) {
    captureException(error);
  }
};

export const getLiveLessons = (subjectId?: number) => async (
  dispatch: Function,
  getState: Function
) => {
  const {
    auth: { learner },
    content: { subjects },
  }: RootState = getState();

  if (learner !== undefined && learner.grade.id) {
    const {
      grade: { id },
    } = learner;
    try {
      const url = subjectId
        ? `live/lessons?grade_id=${id}&subject_id=${subjectId}`
        : `live/lessons?grade_id=${id}`;
      const response = await axiosBaseV2.get(url);
      const subjectLiveArray = subjects
        .map((item: IContentSubject) => {
          if (item.scopes.find((scope: string) => scope === 'live_lesson_subject'))
            return {
              name: item.name,
              value: item.id,
            };
          return {};
        })
        .filter((item: IContentSubject) => item);
      const allLessons = response.data.data.map((item: any) => {
        const getSubject = subjects.find((value: IContentSubject) => value.id === item.subject.id);
        return {
          ...item,
          subject: {
            ...item.subject,
            name: getSubject ? getSubject.name : item.subject.name,
          },
        };
      });
      dispatch({
        type: actionTypes.GET_LIVE_LESSONS,
        payload: {
          allLessons: allLessons,
          liveSubjects: [{ name: 'ALL SUBJECTS', value: 0 }, ...subjectLiveArray],
        },
      });
      dispatch(getMyLessons());
      dispatch(getPromotedLessons());
    } catch (error) {
      captureException(error);
      catchHelper(error, dispatch);
    }
  }
};

export const getFilterLiveLessons = (a: 'live' | 'upcoming' | 'replay', link?: string) => async (
  dispatch: Function,
  getState: Function
) => {
  const {
    auth: { learner },
    content: { subjects },
  }: RootState = getState();

  if (learner !== undefined && learner.grade.id) {
    const {
      grade: { id },
    } = learner;
    try {
      const url = link
        ? `live/lessons?grade_id=${id}&status=${a}${link}`
        : `live/lessons?grade_id=${id}&status=${a}`;
      const response = await axiosBaseV2.get(url);
      const subjectLiveArray = subjects
        .map((item: IContentSubject) => {
          if (item.scopes.find((scope: string) => scope === 'live_lesson_subject'))
            return {
              name: item.name,
              value: item.id,
            };
          return {};
        })
        .filter((item: IContentSubject) => item);

      const allLessons = response.data.data.map((item: any) => {
        const getSubject = subjects.find((value: IContentSubject) => value.id === item.subject.id);
        return {
          ...item,
          subject: {
            ...item.subject,
            name: getSubject ? getSubject.displayName : item.subject.name,
          },
        };
      });
      dispatch({
        type: actionTypes.GET_LIVE_LESSONS,
        payload: {
          filterAllLessons: allLessons,
          liveSubjects: [{ name: 'ALL SUBJECTS', value: 0 }, ...subjectLiveArray],
        },
      });
    } catch (error) {
      captureException(error);
      catchHelper(error, dispatch);
    }
  }
};

export const getMyLessons = (subjectId?: number) => async (
  dispatch: Function,
  getState: Function
) => {
  try {
    const {
      home: { subjects },
      chat: { mySubjects },
    }: RootState = getState();
    const url = subjectId
      ? `live/lessons/registrations?subject_id=${subjectId}`
      : `live/lessons/registrations`;
    const response = await axiosBaseV2.get(url);
    let subjectLiveArray: Array<any> = [];
    const myLessons = response.data.data.map((item: any) => {
      const getSubject = subjects.find(
        (value: subjectInterface) => value.subjectId === item.subject.id
      );
      return {
        ...item,
        subject: {
          ...item.subject,
          name: getSubject ? getSubject.displayName : item.subject.name,
        },
      };
    });
    const array = [...new Set(myLessons.map((item: any) => item.subject.name))];
    array.forEach((element: any) => {
      const getSubject = myLessons.find((value: any) => value.subject.name === element);
      if (getSubject && getSubject.subject) {
        subjectLiveArray = [
          ...subjectLiveArray,
          { name: getSubject.subject.name, value: getSubject.subject.id },
        ];
      }
    });
    dispatch({
      type: actionTypes.GET_MY_LESSONS,
      payload: {
        myLessons: myLessons,
        mySubjects: subjectId
          ? mySubjects
          : [{ name: 'ALL SUBJECTS', value: 0 }, ...subjectLiveArray],
      },
    });
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const getFilterMyLessons = (a: 'live' | 'upcoming' | 'replay', link?: string) => async (
  dispatch: Function,
  getState: Function
) => {
  try {
    const {
      home: { subjects },
      chat: { mySubjects },
    }: RootState = getState();
    const url = link
      ? `live/lessons/registrations?status=${a}${link}`
      : `live/lessons/registrations?status=${a}`;
    const response = await axiosBaseV2.get(url);
    const myLessons = response.data.data.map((item: any) => {
      const getSubject = subjects.find(
        (value: subjectInterface) => value.subjectId === item.subject.id
      );
      return {
        ...item,
        subject: {
          ...item.subject,
          name: getSubject ? getSubject.displayName : item.subject.name,
        },
      };
    });
    dispatch({
      type: actionTypes.GET_MY_LESSONS,
      payload: {
        filterMyLessons: myLessons,
        mySubjects: mySubjects,
      },
    });
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const addMyLessons = (liveLessonId: number, skipModal = false) => async (
  dispatch: Function,
  getState: Function
) => {
  try {
    const {
      chat: {
        myLessons,
        allLessons,
        remindMeModalDetails,
        mySubjects,
        liveSubjects,
        promotedLessons,
      },
    }: RootState = getState();
    let ALL_LESSONS = [...allLessons];
    let PROMOTED_LESSONS = [...promotedLessons];
    const payload = {
      live_lesson_id: liveLessonId,
    };
    const response = await axiosBaseV2.post(`live/lessons/register`, payload);
    if (response.data.status === 'success') {
      const getIndex = allLessons.findIndex((item: any) => item.id === liveLessonId);
      const getProIndex = promotedLessons.findIndex((item: any) => item.id === liveLessonId);
      ALL_LESSONS[getIndex].registered = true;
      dispatch({
        type: actionTypes.GET_LIVE_LESSONS,
        payload: { allLessons: ALL_LESSONS, liveSubjects },
      });
      if (!skipModal) {
        dispatch({
          type: actionTypes.TOGGLE_REMIND_MODAL,
          payload: { ...remindMeModalDetails, registered: true },
        });
      }
      dispatch({
        type: actionTypes.GET_MY_LESSONS,
        payload: { myLessons: [ALL_LESSONS[getIndex], ...myLessons], mySubjects },
      });
      if (getProIndex >= 0 && PROMOTED_LESSONS[getProIndex]) {
        PROMOTED_LESSONS[getProIndex].registered = true;
        dispatch({
          type: actionTypes.GET_PROMOTED_LESSONS,
          payload: PROMOTED_LESSONS,
        });
      }
    }
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const getLiveLessonPass = (lesson_id: number) => async (
  dispatch: Function,
  getState: Function
) => {
  try {
    const response = await axiosBaseV2.post(`live/lessons/passes`, { lesson_id });
    return response.data.data;
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const getPromotedLessons = () => async (dispatch: Function, getState: Function) => {
  const {
    home: { subjects },
    auth: { learner },
  }: RootState = getState();

  if (learner !== undefined && learner.grade?.id) {
    const {
      grade: { id },
    } = learner;

    try {
      const response = await axiosBaseV2.get(`live/lessons/promoted?grade_id=${id}`);
      const promoted = response.data.data.map((item: any) => {
        const getSubject = subjects.find(
          (value: subjectInterface) => value.subjectId === item.subject.id
        );
        return {
          ...item,
          subject: {
            ...item.subject,
            name: getSubject ? getSubject.displayName : item.subject.name,
          },
        };
      });
      dispatch({
        type: actionTypes.GET_PROMOTED_LESSONS,
        payload: promoted,
      });
    } catch (error) {
      captureException(error);
      catchHelper(error, dispatch);
    }
  }
};

export const toggleIsLiveRemindMe = (remindMeModalDetails?: any, history?: any) => async (
  dispatch: Function,
  getState: Function
) => {
  if (
    remindMeModalDetails &&
    remindMeModalDetails.status &&
    remindMeModalDetails.status.toLowerCase() === 'upcoming'
  ) {
    dispatch({
      type: actionTypes.TOGGLE_REMIND_MODAL,
      payload: remindMeModalDetails,
    });
  } else if (
    remindMeModalDetails &&
    remindMeModalDetails.status &&
    (remindMeModalDetails.status.toLowerCase() === 'live' ||
      remindMeModalDetails.status.toLowerCase() === 'replay')
  ) {
    try {
      dispatch({ type: actionTypes.CHAT_INIT });
      const livePasses = await dispatch(getLiveLessonPass(remindMeModalDetails.id));

      if (livePasses.authorize_join) {
        dispatch({ type: actionTypes.SELECT_LIVE_LESSON, payload: remindMeModalDetails });
        history.push(urls.Live);
      } else {
        dispatch({ type: actionTypes.CHAT_FAIL });
        dispatch(toggleLimitLiveLesson());
      }
    } catch (error) {
      captureException(error);
      dispatch({ type: actionTypes.CHAT_FAIL });
    }
  } else {
    dispatch({
      type: actionTypes.TOGGLE_REMIND_MODAL,
      payload: null,
    });
  }
};

export const toggleLimitLiveLesson = () => async (
  dispatch: Function,
  getState: RootState
): Promise<void> => {
  dispatch({ type: actionTypes.TOGGLE_LIMIT_LIVE_LESSON });
};

export const setJoinNowModal = (data: any) => async (
  dispatch: Function,
  getState: RootState
): Promise<void> => {
  dispatch({ type: actionTypes.SET_JOIN_NOW, payload: data });
};

export const getNotificationsPage = (page: number) => async (dispatch: Function) => {
  try {
    const response = await axiosBaseV2.get(`notifications?page=${page}`);
    if (response.data.meta.current_page <= response.data.meta.last_page) {
      dispatch({
        type: actionTypes.GET_NOTIFICATIONS_PAGE,
        payload: {
          notifications: response.data.data,
          current_page: response.data.meta.current_page,
          last_page: response.data.meta.last_page,
        },
      });
    }
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const getNotifications = () => async (dispatch: Function) => {
  try {
    const response = await axiosBaseV2.get(`notifications`);
    dispatch({
      type: actionTypes.GET_NOTIFICATIONS,
      payload: {
        notifications: response.data.data,
        unread_count: response.data.unread_count,
        current_page: response.data.meta.current_page,
        last_page: response.data.meta.last_page,
      },
    });
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const markAsRead = (ids?: Array<any>) => async (dispatch: Function) => {
  const payload =
    ids && ids.length > 0
      ? {
          notification_ids: ids,
        }
      : { read_all: true };
  try {
    await axiosBaseV2.post(`notifications/read`, payload);
    await dispatch(getNotifications());
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const deleteNotification = (ids?: Array<any>) => async (dispatch: Function) => {
  const payload =
    ids && ids.length > 0
      ? {
          notification_ids: ids,
        }
      : { delete_all: true };
  try {
    await axiosBaseV2.post(`notifications/delete`, payload);
    await dispatch(getNotifications());
  } catch (error) {
    captureException(error);
    catchHelper(error, dispatch);
  }
};

export const showCodingSchoolModal = (
  callback: any,
  codingSchoolOpen: boolean,
  force?: boolean
) => async (dispatch: Function, getState: Function) => {
  const { learner } = getState().auth;
  if (force) {
    return dispatch({
      type: actionTypes.SET_CODING_SCHOOL_MODAL,
      payload: codingSchoolOpen,
    });
  }
  const codingSchool = await retrieveItem(`coding_school_${learner.id}`);
  if (codingSchool === learner.id) {
    callback();
  } else {
    dispatch({
      type: actionTypes.SET_CODING_SCHOOL_MODAL,
      payload: codingSchoolOpen,
    });
  }
};
