import React from 'react';

export const Info = () => {
  return (
    <svg width="40" height="36" viewBox="0 0 40 36" fill="none">
      <g clipPath="url(#clip67)">
        <path
          d="M0.0698889 15.75C0.669889 5.55 13.0699 0 19.5699 0C35.7699 0 39.0699 9 39.8199 15.75C40.5699 22.5 35.6199 36 21.8199 36C4.56989 36 -0.680111 28.5 0.0698889 15.75Z"
          fill="#EA7052"
        />
        <path
          d="M4.93988 6.15039C2.1204 8.5879 0.371443 12.0356 0.0698889 15.7504C-0.680111 28.5004 4.56989 36.0004 21.8199 36.0004C30.4999 36.0004 35.6699 30.6604 38.1399 25.0004C26.3899 15.9504 15.1299 9.26039 4.93988 6.15039Z"
          fill="#F9AD6D"
        />
        <path
          d="M16 20.9397C10.752 21.002 5.56474 22.0713 0.719971 24.0897C2.62997 31.6897 8.80998 35.9797 21.85 35.9797C24.4699 36.0441 27.0682 35.4922 29.4357 34.3684C31.8032 33.2446 33.8736 31.5803 35.48 29.5097C33.0465 26.7567 30.0444 24.5648 26.6812 23.0852C23.318 21.6056 19.6737 20.8735 16 20.9397Z"
          fill="#68BC98"
        />
        <path
          d="M17.2 21.0096C17.2894 21.6351 17.4679 22.2446 17.73 22.8196C17.8297 23.205 18.072 23.5382 18.4081 23.7517C18.7441 23.9652 19.1487 24.0431 19.54 23.9696C20.0228 24.0576 20.521 23.965 20.94 23.7096C21.3421 23.4056 21.6674 23.0118 21.89 22.5596C22.3172 20.8722 22.5421 19.14 22.56 17.3996C22.56 14.5096 22.38 9.82955 21.39 7.16955C21.1754 6.70615 20.7985 6.3374 20.3305 6.1329C19.8625 5.92839 19.3359 5.9023 18.85 6.05956C17.9 6.22956 17.31 7.62957 17.02 8.53957C16.6594 9.90554 16.5077 11.3182 16.57 12.7296C16.57 14.2496 16.57 15.7296 16.68 17.2796C16.78 18.5396 17 19.7696 17.2 21.0096Z"
          fill="white"
        />
        <path
          d="M16.8501 27C17.0576 26.5723 17.3756 26.2078 17.7711 25.9441C18.1667 25.6804 18.6255 25.5271 19.1001 25.5C20.3501 25.5 22.8501 25.95 22.8501 27.75C22.8808 28.2504 22.8048 28.7517 22.6272 29.2206C22.4496 29.6895 22.1744 30.1153 21.8199 30.4698C21.4654 30.8243 21.0396 31.0995 20.5707 31.2771C20.1018 31.4547 19.6005 31.5307 19.1001 31.5C16.8501 31.5 16.1001 28.5 16.8501 27Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip67">
          <rect width="39.89" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Info;
