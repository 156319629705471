import { ENV_DEV, ENV_PRODUCTION, ENV_STAGING, ENV_UAT } from "Utils/constants"
import { captureException } from "Utils/sentry";
import { FlagBodyType, FlagNameTypes } from "./types";
import { flagsDefault } from "./environments/default";
import { flagsDevelopment } from "./environments/dev";
import { flagsProduction } from "./environments/main";
import { flagsUat } from "./environments/uat";
import { flagsStaging } from "./environments/staging";
import { FEATURE_FLAG_IDENTITY, FEATURE_FLAG_ONE_TO_ONE_PREMIUM, FEATURE_FLAG_RESTYLE, FEATURE_FLAG_VIDEO_LIBRARY_UI_REWORK } from "./constants";
import { AppEnv } from "config";

/*
 *  Get the correct flags for a specific environment
 *  TODO: get the environment flags via a secret manager implementation
 */
const getEnvFlags = ():Record<FlagNameTypes, FlagBodyType> => {
    const appEnv:string | undefined = AppEnv?.toLowerCase();

    switch(appEnv) {
        case ENV_DEV: return flagsDevelopment;
        case ENV_STAGING: return flagsStaging;
        case ENV_UAT: return flagsUat;
        case ENV_PRODUCTION: return flagsProduction;
        default: return flagsDefault;
    }
};

/*
 *  Determines whether a specific flag is active
 */
export const isFlagActive = (featureName:FlagNameTypes):boolean => {
    try {
        const envFlags:Record<FlagNameTypes, FlagBodyType> = getEnvFlags();
        const feature:FlagBodyType = envFlags[featureName];

        return feature.active || false;
    } catch (error) {
        captureException(error)
        return false;
    }
}

/*
 *  A list of all feature flags passed to the provider
 */
export const flags = { 
    identity: isFlagActive(FEATURE_FLAG_IDENTITY),
    restyle: isFlagActive(FEATURE_FLAG_RESTYLE),
    videoLibraryUiRework: isFlagActive(FEATURE_FLAG_VIDEO_LIBRARY_UI_REWORK),
    oneToOnePremium: isFlagActive(FEATURE_FLAG_ONE_TO_ONE_PREMIUM)
}