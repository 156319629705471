import * as actionTypes from './actionTypes';
import { RootState } from 'Reducers';

export const setTheme = (key: string) => (dispatch: Function, getState: () => RootState) => {
  const Theme = getState().content.theme;
  dispatch({
    type: actionTypes.SET_THEME,
    payload: Theme[key ? key : 'default'],
  });
  return Theme[key];
};
