export const CONTENT_INIT = 'CONTENT_INIT';
export const CONTENT_FAIL = 'CONTENT_FAIL';
export const LOG_OUT = 'LOG_OUT';

export const FETCH_SUBJECTS = 'FETCH_SUBJECTS';
export const FETCH_SUBJECT = 'FETCH_SUBJECT';
export const FETCH_LESSON = 'FETCH_LESSON';
export const FETCH_QUEST = 'FETCH_QUEST';
export const FETCH_LESSON_BYPASS = 'FETCH_LESSON_BYPASS';
export const FETCH_QUEST_BYPASS = 'FETCH_QUEST_BYPASS';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const FETCH_PRACTICE_TESTS = 'FETCH_PRACTICE_TESTS';
export const CHECK_PRACTICE_TEST_OPTION = 'CHECK_PRACTICE_TEST_OPTION';
export const FETCH_LESSON_QUESTIONS = 'FETCH_LESSON_QUESTIONS';
export const CHECK_LESSON_QUESTION_OPTION = 'CHECK_LESSON_QUESTION_OPTION';
export const FETCH_CHAPTER_TEST = 'FETCH_CHAPTER_TEST';
export const ANALYZE_CHAPTER_TEST = 'ANALYZE_CHAPTER_TEST';
export const CHECK_CHAPTER_TEST_OPTION = 'CHECK_CHAPTER_TEST_OPTION';
export const FETCH_PRACTICE_EXAMS = 'FETCH_PRACTICE_EXAMS';
export const ANALYZE_PRACTICE_EXAM = 'ANALYZE_PRACTICE_EXAM';
export const CHECK_PRACTICE_EXAM_OPTION = 'CHECK_PRACTICE_EXAM_OPTION';
export const FETCH_RECENT_DATA = 'FETCH_RECENT_DATA';
export const FETCH_THEMES = 'FETCH_THEMES';
export const FETCH_LESSON_VIDEO = 'FETCH_LESSON_VIDEO';
