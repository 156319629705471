import AsyncStorage from '@react-native-community/async-storage';
import { logError } from 'Utils/logger';
import { captureException } from 'Utils/sentry';

export const storeItem = async (key: any, item: any) => {
  try {
    return await AsyncStorage.setItem(key, JSON.stringify(item));
  } catch (error) {
    captureException(error);
    logError(error);
  }
};

export const retrieveItem = async (key: any) => {
  try {
    const retrievedItem = await AsyncStorage.getItem(key);
    return JSON.parse(<string>retrievedItem);
  } catch (error) {
    captureException(error);
    logError(error);
  }
  return null;
};

export const removeItem = async (key: any) => {
  try {
    const removeItem = await AsyncStorage.removeItem(key);
    return removeItem + ' was removed!';
  } catch (error) {
    captureException(error);
    logError(error);
  }
  return null;
};
