import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../Reducers';
import { persistStore, persistReducer } from 'redux-persist';
import { environment } from 'Utils/constants';
// import AsyncStorage from '@react-native-community/async-storage';
import localforage from 'localforage';

const persistConfig = {
  key: 'ulessonWebApp',
  storage: localforage,
  blacklist: [],
  // blacklist: ["home"],
};

const initialState = {};

const middleWare = [thunk];

const middlewareDev = [
  applyMiddleware(...middleWare),
  ...(window && (window as any).__REDUX_DEVTOOLS_EXTENSION__ && environment === 'development'
    ? [(window as any).__REDUX_DEVTOOLS_EXTENSION__()]
    : []),
];

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, initialState, compose(...middlewareDev));

export let persistor = persistStore(store);
