import { Integrations } from '@sentry/tracing';
import { enableTrackingLibraries, ENV_STAGING, sentryKey } from './constants';
import * as Sentry from '@sentry/react';
import { AppEnv } from 'config';

export const init = () => {
  if (enableTrackingLibraries) {
    Sentry.init({
      dsn: sentryKey,
      integrations: [new Integrations.BrowserTracing()],
      //  Set the sampling rate to
      //    100% for staging
      //    25% for production
      //  This value has an impact on page load speeds if too many samples are taken
      tracesSampleRate: AppEnv?.toLowerCase() === ENV_STAGING ? 1.0 : 0.25,
      environment: AppEnv,
    });
  }
};

export const captureException = (error: unknown) => {
  Sentry.captureException(error);
};
