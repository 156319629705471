import { combineReducers } from 'redux';
import mainReducers from './mainReducers';
import authReducers from './authReducers';
import themeReducers from './themeReducers';
import homeReducers from './homeReducers';
import videoReducers from './videoReducers';
import subscribeReducers from './subscribeReducers';
import chatReducers from './chatReducers';
import contentReducers from './contentReducers';
import chapterReducer from './chapterReducer';
import trialReducer from './trialReducers';

const RootReducer = combineReducers({
  main: mainReducers,
  auth: authReducers,
  theme: themeReducers,
  home: homeReducers,
  video: videoReducers,
  subscribe: subscribeReducers,
  chat: chatReducers,
  content: contentReducers,
  chapter: chapterReducer,
  trial: trialReducer,
});
export default RootReducer;

export type RootState = ReturnType<typeof RootReducer>;
