import moment from 'moment';
import { liveLessonStatus } from 'Types/general';
import { makePaschal } from './searchInitializer';

const SECONDS_IN_ONE_DAY = 86400;
export const SECONDS_IN_ONE_MINUTE = 60;
export const xIntervals = 5;

export const timeIntervals = (endTimeInSeconds: number, totalIntervals: number): number[] => {
  let intervalsCollection = [];
  const startTimeinMinutes = 0;
  const endTimeinMinutes = Math.ceil(endTimeInSeconds / SECONDS_IN_ONE_MINUTE);
  if (endTimeinMinutes < totalIntervals) {
    for (let i = 0; i < totalIntervals; i++) {
      intervalsCollection.push(i);
    }
  } else {
    const interval = Math.round(endTimeinMinutes / (totalIntervals - 1));
    intervalsCollection.push(startTimeinMinutes);
    for (let i = 0; i < totalIntervals - 2; i++) {
      intervalsCollection.push((i + 1) * interval);
    }
    intervalsCollection.push(endTimeinMinutes);
  }
  return intervalsCollection;
};

export const dateIntervals = (startDate: Date, endDate: Date, totalIntervals: number): Date[] => {
  let intervalsCollection = [];
  let interval = 1;
  const startMoment = moment(startDate).startOf('day').utc();
  const endMoment = moment(endDate).startOf('day').utc();
  const timeDiffInSeconds =
    (startMoment.clone().toDate().getTime() - endMoment.clone().toDate().getTime()) / 1000;
  const dayDifference = Math.abs(Math.ceil(timeDiffInSeconds / SECONDS_IN_ONE_DAY));

  if (dayDifference >= totalIntervals) {
    interval = Math.round(dayDifference / (totalIntervals - 1));
    intervalsCollection.push(startMoment.clone().toDate());
    for (let i = 0; i < totalIntervals - 2; i++) {
      intervalsCollection.push(
        startMoment
          .clone()
          .add((i + 1) * interval, 'days')
          .toDate()
      );
    }
    intervalsCollection.push(endMoment.clone().toDate());
  } else {
    for (let i = 0; i < totalIntervals; i++) {
      intervalsCollection.unshift(endMoment.clone().subtract(i, 'days').toDate());
    }
  }
  return intervalsCollection;
};

export const updatedTimeSince = (date: number) => {
  var seconds = Math.floor((new Date().valueOf() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + ' year(s) ago';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' month(s) ago';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' day(s) ago';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hour(s) ago';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minute(s) ago';
  }
  return Math.floor(seconds) + ' second(s) ago';
};

export const formatSecsToMins = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  const str_pad_left = (string: number, pad: string, length: number) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  };

  return str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
};

export const addMinutesToDate = (date: Date, minutes: number) => {
  return new Date(date.getTime() + minutes * 60000).toISOString();
};

export const formatLiveLessonDate = (date: Date | string, status: liveLessonStatus): string => {
  let formattedDate = '';
  if (status) {
    status = status.toLocaleLowerCase() as liveLessonStatus;

    if (status === 'upcoming') {
      formattedDate =
        moment(date).format('MMMM Do YYYY') === moment().format('MMMM Do YYYY')
          ? `Today, ${moment(date).format('h:mm A')}`
          : `${moment(date).format('MMM DD, h:mm A')}`;
    } else if (status === 'live') {
      formattedDate = `Started at ${moment(date).format('h:mm A')}`;
    } else if (status === 'replay') {
      let prefix = 'Available for ';
      const time = moment(date).diff(moment(), 'hour');
      switch (true) {
        case time > 72: {
          const days = moment(date).diff(moment(), 'days');
          prefix += `${days} days`;
          break;
        }
        case time > 48 && time <= 72: {
          prefix += '3 days';
          break;
        }
        case time >= 25 && time <= 48: {
          prefix += '2 days';
          break;
        }
        case time >= 24 && time < 25: {
          prefix += '1 day';
          break;
        }
        case time >= 1 && time < 24: {
          prefix += time === 1 ? `1 hour` : `${time} hours`;
          break;
        }
        case time < 1: {
          const minutes = moment(date).diff(moment(), 'minute');
          prefix += minutes === 1 ? `1 minute` : `${minutes} minutes`;
          break;
        }
      }
      return `${prefix}`;
    }
  }

  return makePaschal(formattedDate);
};

export const formatNormalDate = (date: Date | string): string => {
  let formattedDate = '';
  if (moment(date).format('MMMM Do YYYY') === moment().format('MMMM Do YYYY')) {
    formattedDate = `${moment(date).format('h:mm A')}`;
  } else {
    formattedDate = `${moment(date).format('MMM DD, h:mm A')}`;
  }
  return makePaschal(formattedDate);
};
