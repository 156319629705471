import { trackSegmentEvent } from 'Utils/analytics';
import * as actionTypes from './actionTypes';

export const toggleFreeTrial = (status: boolean) => async (dispatch: Function) => {
  return dispatch({
    type: actionTypes.TOGGLE_FREE_TRIAL,
    payload: status,
  });
};

export const toggleTrialExpiration = (status: boolean) => async (dispatch: Function) => {
  return dispatch({
    type: actionTypes.TOGGLE_TRIAL_EXPIRATION,
    payload: status,
  });
};

export const toggleTrialAnnouncement = (status: boolean) => async (dispatch: Function) => {
  return dispatch({
    type: actionTypes.TOGGLE_TRIAL_ANNOUNCEMENT,
    payload: status,
  });
};

export const toggleFreeBlocker = (status: boolean) => async (dispatch: Function) => {
  if (status) {
    trackSegmentEvent('ue_premium_content_blocked');
  }
  return dispatch({
    type: actionTypes.TOGGLE_FREE_BLOCKER,
    payload: status,
  });
};
