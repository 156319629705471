import * as actionTypes from 'Actions/video/actionTypes';

interface Action {
  type: string;
  payload: any;
}

const initialState: any = {
  selectedLesson: {},
  lessonsOfSameQuest: [],
  type: '',
  lessonsWatched: [],
  subjectContinueQuest: [],
  resumeJourney: [],
  highlight: {},
  attemptedQuizQuestions: {},
  selectedPractice: {},
  attemptedTestIds: [],
  badgesAwarded: [],
  selectedAnalysis: {},
  selectedAnalysisSubjectId: null,
  progressAnalysis: {},
  allTests: [],
  performanceAnalysis: {},
  lessonPasses: {},
  progressLessons: [],
  progressTests: [],
  isLimitedPassesOpen: false,
};

const VideoReducer = (state = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.PLAY_LESSONS: {
      return {
        ...state,
        ...payload,
      };
    }
    case actionTypes.GRADE_CONTENT: {
      return {
        ...state,
        progressLessons: payload.backendLessons,
        progressTests: payload.backendTests,
        // lessonsWatched: [],
        // resumeJourney: [],
        // subjectContinueQuest: [],
        highlight: {},
        selectedLesson: {},
      };
    }
    case actionTypes.SAVE_LESSONS_WATCHED: {
      return {
        ...state,
        lessonsWatched: payload,
      };
    }
    case actionTypes.SUBJECT_CONTINUE_QUEST: {
      return {
        ...state,
        subjectContinueQuest: payload,
      };
    }
    case actionTypes.RESUME_JOURNEY: {
      return {
        ...state,
        resumeJourney: payload,
      };
    }
    case actionTypes.SAVE_HIGHLIGHTS: {
      return {
        ...state,
        highlight: payload,
      };
    }
    case actionTypes.CLEAR_QUIZ_PREVIOUS_ANSWERS: {
      const newData = {
        ...state.attemptedQuizQuestions,
        ...{ [`lesson_${payload.lessonId}`]: [] },
      };
      return {
        ...state,
        attemptedQuizQuestions: newData,
      };
    }
    case actionTypes.ADD_QUIZ_PREVIOUS_ANSWERS: {
      const key = `lesson_${payload.lessonId}`;
      const data = (state.attemptedQuizQuestions && state.attemptedQuizQuestions[key]) || [];
      const newData = {
        ...state.attemptedQuizQuestions,
        ...{ [key]: [...data, payload.questionId] },
      };
      return {
        ...state,
        attemptedQuizQuestions: newData,
      };
    }
    case actionTypes.SET_SELECTED_PRACTICE: {
      return {
        ...state,
        selectedPractice: {
          ...state.selectedPractice,
          ...payload,
        },
      };
    }
    case actionTypes.UPDATE_ATTEMPTED_TEST_IDS: {
      return {
        ...state,
        attemptedTestIds: [...state.attemptedTestIds, payload.testId],
      };
    }
    case actionTypes.GET_ALL_AWARDED_BADGES: {
      return {
        ...state,
        badgesAwarded: payload,
      };
    }
    case actionTypes.SET_SELECTED_ANALYSIS_SUBJECT_ID: {
      return {
        ...state,
        selectedAnalysisSubjectId: payload,
      };
    }
    case actionTypes.SET_SELECTED_ANALYSIS: {
      return {
        ...state,
        selectedAnalysis: payload,
      };
    }
    case actionTypes.SET_PROGRESS_ANALYSIS: {
      return {
        ...state,
        progressAnalysis: payload,
      };
    }
    case actionTypes.GET_ALL_TESTS: {
      return {
        ...state,
        allTests: payload,
      };
    }
    case actionTypes.SET_PERFORMANCE_ANALYSIS: {
      return {
        ...state,
        performanceAnalysis: payload,
      };
    }
    case actionTypes.LESSON_PASSES: {
      return {
        ...state,
        lessonPasses: payload,
      };
    }
    case actionTypes.TOGGLE_LESSON_PASSES: {
      return {
        ...state,
        isLimitedPassesOpen: payload,
      };
    }
    case actionTypes.LOG_OUT:
      return {
        // selectedLesson: {},
        // lessonsOfSameQuest: [],
        type: '',
        // lessonsWatched: [],
        // subjectContinueQuest: [],
        // resumeJourney: [],
        highlight: {},
        // attemptedQuizQuestions: {},
        selectedPractice: {},
        attemptedTestIds: [],
        badgesAwarded: [],
        selectedAnalysis: {},
        selectedAnalysisSubjectId: '',
        progressAnalysis: {},
        lessonPasses: {},
      };
    default:
      return state;
  }
};

export default VideoReducer;
