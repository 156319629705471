import * as actionTypes from './actionTypes';
import axiosInstance from '../../Utils/axios-orders';
import { RootState } from 'Reducers';
import { catchHelper } from 'Functions';
import { reduxResponse, ThunkResult } from 'Types/general';
import {
  fetchLessonParameterInterface,
  fetchQuestParameterInterface,
  ILesson,
  IQuest,
  questionCheckPayloadInterface,
} from 'Types/content';
import { captureException } from 'Utils/sentry';
const axios = axiosInstance('v2');
const axiosV3 = axiosInstance('v3');

export const fetchSubjects = (): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    const gradeId = getState().auth.learner.grade.id;
    dispatch({ type: actionTypes.CONTENT_INIT });
    const subjects = await axios.get(`content/subjects?grade_id=${gradeId}`);

    const { status, message, data } = subjects.data;
    dispatch({ type: actionTypes.FETCH_SUBJECTS, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const fetchLessonVideo = (lessonId: number): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    const lessonVideo = await axiosV3.get(`content/video/${lessonId}`);
    const { status, message, data } = lessonVideo.data;

    dispatch({ type: actionTypes.FETCH_LESSON_VIDEO, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const fetchSubject = (id: number): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const subject = await axiosV3.get(`content/subject/${id}`);

    const { status, message, data } = subject.data;
    dispatch({ type: actionTypes.FETCH_SUBJECT, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const fetchLesson = ({
  lessonId,
  questId,
  chapterId,
  subjectId,
  recommended,
  byPassStore,
}: fetchLessonParameterInterface): ThunkResult<any> => async (
  dispatch: Function
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    let url = `content/lessons`;
    if (lessonId) url += `?lesson_ids[]=${lessonId}`;
    if (questId) url += `?quest_id=${questId}`;
    if (chapterId) url += `?chapter_id=${chapterId}`;
    if (subjectId) url += `?subject_id=${subjectId}`;
    if (recommended) url += `?recommended=${recommended}`;
    const lessons = await axios.get(url);

    const { status, message, data } = lessons.data;
    if (!byPassStore) {
      dispatch({ type: actionTypes.FETCH_LESSON, payload: data });
    } else {
      dispatch({ type: actionTypes.FETCH_LESSON_BYPASS });
    }

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const fetchQuests = ({
  questId,
  chapterId,
  subjectId,
  recommended,
  byPassStore,
}: fetchQuestParameterInterface): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });

    const gradeId = getState().auth.learner.grade.id;
    let url = `content/quests?grade_id=${gradeId}`;
    if (questId) url += `&quest_ids[]=${questId}`;
    if (chapterId) url += `&chapter_id=${chapterId}`;
    if (subjectId) url += `&subject_id=${subjectId}`;
    if (recommended) url += `&recommended=${recommended}`;

    const quests = await axios.get(url);

    const { status, message, data } = quests.data;
    if (!byPassStore) dispatch({ type: actionTypes.FETCH_QUEST, payload: data });
    else dispatch({ type: actionTypes.FETCH_QUEST_BYPASS });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const clearSearch = (): ThunkResult<any> => async (dispatch: Function) => {
  dispatch({
    type: actionTypes.SEARCH_RESULTS,
    payload: {
      quests: [],
      lessons: [],
    },
  });
};

export const searchQuery = (query: string, subjectId?: number): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  const gradeId = getState().auth.learner.grade.id;
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    let url = `content/search?q=${query}&grade_id=${gradeId}`;
    if (subjectId) url += `&subject_id=${subjectId}`;
    const searchResult = await axios.get(url);

    const { status, message, data } = searchResult.data;
    const quests = data.quests.map((quest: IQuest) => {
      return { ...quest, uniqueId: `quest::${quest.id}`, type: 'quest' };
    });
    const lessons = data.lessons.map((lesson: ILesson) => {
      return { ...lesson, uniqueId: `lesson::${lesson.id}`, type: 'lesson' };
    });
    dispatch({
      type: actionTypes.SEARCH_RESULTS,
      payload: {
        quests,
        lessons,
      },
    });

    return { status, message, data: { quests, lessons } };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const fetchPracticeTests = (id: number): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const practiceTest = await axios.get(`content/chapters/${id}/practice-test/questions`);

    const { status, message, data } = practiceTest.data;
    dispatch({ type: actionTypes.FETCH_PRACTICE_TESTS, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const checkPracticeTestOption = (
  questionId: number,
  optionIds: number[]
): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const practiceTestOption = await axios.post(`content/chapters/practice-test/check-answer`, {
      question_id: questionId,
      option_ids: optionIds,
    });

    const { status, message, data } = practiceTestOption.data;
    dispatch({ type: actionTypes.CHECK_PRACTICE_TEST_OPTION, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const fetchLessonQuestions = (id: number): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const lessonQuestion = await axios.get(`content/lessons/${id}/questions`);

    const { status, message, data } = lessonQuestion.data;
    dispatch({ type: actionTypes.FETCH_LESSON_QUESTIONS, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const checkLessonQuestionOption = (
  questionId: number,
  optionIds: number[]
): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const questionOption = await axios.post(`content/lessons/questions/check-answer`, {
      question_id: questionId,
      option_ids: optionIds,
    });

    const { status, message, data } = questionOption.data;
    dispatch({ type: actionTypes.CHECK_LESSON_QUESTION_OPTION, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const fetchChapterTest = (id: number): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const chapterTest = await axios.get(`content/chapters/${id}/test`);

    const { status, message, data } = chapterTest.data;
    dispatch({ type: actionTypes.FETCH_CHAPTER_TEST, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const analyzeChapterTest = (id: number): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const analyzedChapterTest = await axios.get(`content/chapters/test/${id}/analyze`);

    const { status, message, data } = analyzedChapterTest.data;
    dispatch({ type: actionTypes.ANALYZE_CHAPTER_TEST, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const checkChapterTestOption = (
  payload: questionCheckPayloadInterface[]
): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const chapterTestOption = await axios.post(`content/chapters/test/check-answer`, {
      question_and_answers: payload,
    });

    const { status, message, data } = chapterTestOption.data;
    dispatch({ type: actionTypes.CHECK_CHAPTER_TEST_OPTION, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const fetchPracticeExam = (id: number): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const practiceExam = await axios.get(`content/subjects/${id}/practice-exams`);

    const { status, message, data } = practiceExam.data;
    dispatch({ type: actionTypes.FETCH_PRACTICE_EXAMS, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const analyzePracticeExam = (id: number): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const analyzedPracticeExam = await axios.get(`content/subjects/practice-exams/${id}/analyze`);

    const { status, message, data } = analyzedPracticeExam.data;
    dispatch({ type: actionTypes.ANALYZE_PRACTICE_EXAM, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const checkPracticeExamOption = (
  payload: questionCheckPayloadInterface[]
): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const practiceExamOption = await axios.post(`content/subjects/practice-exams/check-answer`, {
      question_and_answers: payload,
    });

    const { status, message, data } = practiceExamOption.data;
    dispatch({ type: actionTypes.CHECK_PRACTICE_EXAM_OPTION, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const fetchRecentData = (): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const gradeId = getState().auth.learner.grade.id;
    const recent = await axios.get(`content/recent?grade_id=${gradeId}`);

    const { status, message, data } = recent.data;
    dispatch({ type: actionTypes.FETCH_RECENT_DATA, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const fetchThemes = (): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    dispatch({ type: actionTypes.CONTENT_INIT });
    const themes = await axios.get(`content/themes?client=web`);

    const { status, message, data } = themes.data;
    dispatch({ type: actionTypes.FETCH_THEMES, payload: data });

    return { status, message, data };
  } catch (error) {
    captureException(error);
    dispatch({ type: actionTypes.CONTENT_FAIL });
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};
