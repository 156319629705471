import React from 'react';

export const ErrorBoundary = () => {
  return (
    <svg width="460" height="460" viewBox="0 0 460 460" fill="none">
      <mask
        id="mask10"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="60"
        y="33"
        width="367"
        height="372">
        <path
          d="M129.4 100.91C46.0766 163.159 42.5139 288.578 93.7335 351.057C170.199 444.373 376.802 407.054 418.214 299.087C441.621 238.027 409.633 161.126 359.792 117.134C345.372 104.405 328.691 93.7627 310.674 85.688C325.531 69.188 300.031 30.188 245.031 33.188C221.545 31.7597 167.531 37.688 171.649 78.188C156.913 83.4886 142.689 90.9894 129.4 100.91Z"
          fill="#F3F4F9"
        />
      </mask>
      <g mask="url(#mask10)">
        <path
          d="M129.4 100.91C46.0766 163.159 42.5138 288.577 93.7335 351.057C170.199 444.372 376.802 407.054 418.214 299.086C441.621 238.026 409.633 161.126 359.792 117.134C299.915 64.2815 201.077 47.4056 129.4 100.91Z"
          fill="#F3F4F9"
        />
        <path
          d="M137.023 203.542C137.023 203.542 107.979 196.853 127.12 159.026C146.244 121.215 194.9 56.3413 209.43 52.9969C223.96 49.6526 281.561 36.7784 299.452 74.8002C317.342 112.822 354.244 220.166 345.867 272.734C337.473 325.286 283.477 348.209 239.188 345.985C194.9 343.761 138.582 335.579 132.072 291.859C125.561 248.122 137.023 203.542 137.023 203.542Z"
          fill="#271B11"
        />
        <path
          d="M174.558 339.54C168.6 273.108 196.864 164.822 197.14 163.734L200.209 164.546C199.917 165.634 171.798 273.384 177.707 339.264L174.558 339.54Z"
          fill="#3F2B1D"
        />
        <path
          d="M308.965 331.455H305.783C305.881 269.244 274.548 165.65 274.223 164.611L277.259 163.685C277.583 164.724 309.063 268.773 308.965 331.455Z"
          fill="#3F2B1D"
        />
        <path
          d="M338.415 295.252C336.791 209.743 294.029 103.292 293.591 102.221L296.529 101.036C296.968 102.107 339.957 209.094 341.581 295.203L338.415 295.252Z"
          fill="#3F2B1D"
        />
        <path
          d="M136.114 203.266C170.694 198.022 185.516 128.342 185.679 127.596L188.78 128.229C188.13 131.378 173.21 201.318 136.325 206.448L136.114 203.266Z"
          fill="#3F2B1D"
        />
        <path
          d="M229.545 183.979L226.477 218.218C226.477 218.218 231.785 224.647 241.575 224.647C251.365 224.647 256.949 217.374 256.949 217.374L255.553 183.963H229.545V183.979Z"
          fill="#915C44"
        />
        <path
          d="M207.855 355.824L203.017 389.511C202.855 397.271 274.077 398.732 274.239 390.988C273.07 375.711 267.664 355.824 267.664 355.824H207.855Z"
          fill="#68BC98"
        />
        <path
          d="M274.239 390.988L260.212 371.117L263.329 369.088L273.103 381.523L274.239 390.988Z"
          fill="#00A784"
        />
        <path
          d="M203.78 384.267L215.161 369.088L220.275 372.659L203.017 389.527L203.78 384.267Z"
          fill="#00A784"
        />
        <path
          d="M237.646 388.537L238.717 369.023L241.153 369.152L240.26 385.403C242.046 384.234 244.854 380.825 244.254 371.052L246.689 370.906C247.695 387.611 239.383 388.407 239.026 388.439L237.646 388.537Z"
          fill="#00A784"
        />
        <path
          d="M187.237 127.92C187.237 127.92 177.172 115.972 167.106 124.982C157.041 133.992 167.853 157.062 187.237 148.879C189.088 131.265 187.237 127.92 187.237 127.92Z"
          fill="#9E6749"
        />
        <path
          d="M291.042 127.92C291.042 127.92 301.108 115.972 311.157 124.982C321.223 133.992 310.41 157.062 291.042 148.879C289.175 131.265 291.042 127.92 291.042 127.92Z"
          fill="#9E6749"
        />
        <path
          d="M188.033 137.466C186.215 134.431 180.029 125.161 171.977 123.975C170.71 123.797 169.46 123.829 168.226 124.073C167.853 124.349 167.48 124.657 167.106 124.982C166.018 125.956 165.191 127.109 164.574 128.343C166.846 126.752 169.201 126.102 171.619 126.459C173.616 126.752 175.516 127.677 177.237 128.911C174.461 130.258 172.772 132.077 172.236 134.349C171.132 139.057 175.565 143.684 175.759 143.879L177.561 142.126C177.529 142.093 173.909 138.262 174.688 134.934C175.094 133.197 176.701 131.784 179.412 130.713C183.584 134.544 186.344 139.545 186.393 139.626L188.017 138.749C188.017 138.311 188.017 137.889 188.033 137.466Z"
          fill="#915C44"
        />
        <path
          d="M313.511 128.002C312.91 126.898 312.131 125.875 311.141 124.982C311.108 124.949 311.076 124.933 311.043 124.901C308.235 124.576 305.588 125.031 303.153 126.281C295.718 130.145 292.942 140.048 292.828 140.47L295.263 141.135C295.279 141.054 297.065 134.739 301.367 130.664C302.228 131.021 303.803 131.898 304.403 133.57C305.15 135.681 304.176 138.652 301.578 142.174L303.608 143.668C306.774 139.398 307.829 135.713 306.774 132.726C306.108 130.859 304.744 129.674 303.559 128.959C303.819 128.797 304.062 128.635 304.322 128.505C307.066 127.092 310.15 126.93 313.511 128.002Z"
          fill="#915C44"
        />
        <path
          d="M296.01 136.086C294.646 125.485 292.633 114.494 290.198 104.753C287.568 91.8143 279.126 79.1675 268.768 76.1316C242.598 68.4363 222.613 71.6508 206.962 78.4369C195.809 83.2748 188.406 111.328 185.825 121.8C185.159 126.524 184.737 131.557 184.737 136.866C184.737 169.303 208.212 192.356 241.77 192.356C275.327 192.356 299.371 162.159 296.01 136.086Z"
          fill="#9E6749"
        />
        <path
          d="M275.213 123.115C275.213 123.115 275.132 123.115 274.97 123.099C274.759 123.083 274.467 123.05 274.077 123.018C273.427 122.936 272.535 122.807 271.463 122.596C269.336 122.173 266.544 121.345 263.849 120.03C262.518 119.349 261.203 118.553 260.05 117.66C259.498 117.189 258.897 116.784 258.443 116.28C257.972 115.777 257.485 115.355 257.112 114.868C256.738 114.381 256.397 113.942 256.089 113.553C255.797 113.147 255.602 112.757 255.407 112.449C255.05 111.832 254.839 111.475 254.839 111.475C253.962 109.949 254.482 108.017 255.991 107.124C257.501 106.231 259.449 106.767 260.342 108.277C260.375 108.341 260.407 108.406 260.44 108.471L260.602 108.812C260.602 108.812 260.716 109.04 260.91 109.445C261.008 109.64 261.105 109.916 261.268 110.192C261.446 110.468 261.641 110.777 261.852 111.101C262.063 111.442 262.388 111.751 262.664 112.124C262.94 112.514 263.362 112.822 263.719 113.196C264.498 113.91 265.44 114.608 266.447 115.225C268.476 116.459 270.797 117.368 272.583 117.887C273.476 118.147 274.223 118.326 274.742 118.423C274.937 118.472 275.083 118.504 275.197 118.521C275.359 118.537 275.441 118.553 275.441 118.553L275.554 118.569C276.804 118.715 277.697 119.852 277.551 121.102C277.421 122.287 276.382 123.148 275.213 123.115Z"
          fill="black"
        />
        <path
          d="M215.859 139.415C215.859 137.19 217.661 135.064 219.885 135.064C222.11 135.064 223.912 137.19 223.912 139.415C223.912 141.639 222.191 143.376 219.967 143.376C217.759 143.376 215.859 141.639 215.859 139.415Z"
          fill="black"
        />
        <path
          d="M259.043 139.366C259.043 137.272 260.748 135.258 262.842 135.258C264.937 135.258 266.641 137.272 266.641 139.366C266.641 141.46 265.018 143.116 262.907 143.116C260.829 143.1 259.043 141.46 259.043 139.366Z"
          fill="black"
        />
        <path
          d="M211.557 157.792C219.51 157.792 225.957 154.834 225.957 151.185C225.957 147.535 219.51 144.577 211.557 144.577C203.604 144.577 197.157 147.535 197.157 151.185C197.157 154.834 203.604 157.792 211.557 157.792Z"
          fill="#925A3C"
        />
        <path
          d="M277.519 157.792C285.472 157.792 291.919 154.834 291.919 151.185C291.919 147.535 285.472 144.577 277.519 144.577C269.566 144.577 263.118 147.535 263.118 151.185C263.118 154.834 269.566 157.792 277.519 157.792Z"
          fill="#925A3C"
        />
        <path
          opacity="0.2"
          d="M194.575 129.252C194.575 129.252 220.665 135.242 239.253 131.086C238.377 142.889 236.834 151.802 227.727 153.62C218.619 155.455 200.631 153.003 198.666 150.6C196.702 148.198 194.575 129.252 194.575 129.252Z"
          fill="#FAFAFA"
        />
        <path
          opacity="0.2"
          d="M249.887 131.086C249.887 131.086 282.048 135.242 289.175 131.086C288.996 144.236 286.513 148.993 280.879 151.152C275.229 153.312 261.186 154.594 256.965 152.403C252.728 150.195 249.887 131.086 249.887 131.086Z"
          fill="#FAFAFA"
        />
        <path
          d="M211.2 119.3C211.2 119.3 211.297 119.3 211.46 119.3C211.687 119.284 212.012 119.251 212.434 119.219C213.132 119.154 214.09 119.024 215.226 118.813C217.483 118.407 220.437 117.579 223.197 116.232C224.577 115.533 225.892 114.722 227.045 113.796C227.597 113.293 228.181 112.887 228.62 112.368C229.074 111.848 229.545 111.41 229.886 110.907C230.227 110.403 230.552 109.949 230.828 109.543C231.087 109.121 231.266 108.715 231.428 108.39C231.737 107.741 231.932 107.367 231.932 107.367C232.695 105.776 231.883 103.715 230.129 102.757C228.36 101.799 226.298 102.318 225.535 103.893C225.503 103.958 225.47 104.023 225.454 104.104L225.324 104.461C225.324 104.461 225.227 104.705 225.064 105.127C224.983 105.338 224.902 105.614 224.756 105.906C224.593 106.182 224.415 106.507 224.22 106.864C224.041 107.221 223.717 107.546 223.457 107.936C223.213 108.341 222.791 108.65 222.45 109.056C221.704 109.803 220.762 110.517 219.739 111.15C217.677 112.416 215.258 113.342 213.375 113.845C212.434 114.105 211.638 114.283 211.086 114.381C210.875 114.429 210.713 114.446 210.599 114.478C210.437 114.494 210.339 114.511 210.339 114.511L210.226 114.527C208.878 114.657 208.05 115.842 208.375 117.173C208.683 118.391 209.933 119.316 211.2 119.3Z"
          fill="black"
        />
        <path
          d="M248.783 133.96C247.614 130.274 245.52 126.768 244.465 126.751C243.491 126.719 241.737 129.868 240.893 133.099L237.646 132.255C238.036 130.761 240.163 123.31 244.562 123.391C248.686 123.504 251.283 130.729 251.981 132.953L248.783 133.96Z"
          fill="#F2F2F2"
        />
        <path
          d="M194.04 120.014L180.841 118.683L182.448 123.91L194.137 121.881L194.04 120.014Z"
          fill="#F2F2F2"
        />
        <path
          d="M289.175 120.582L296.4 117.806V123.569L289.581 122.676L289.175 120.582Z"
          fill="#F2F2F2"
        />
        <path
          d="M225.876 155.747C225.665 155.747 225.454 155.747 225.243 155.731L207.937 154.984C200.956 154.691 195.89 149.935 195.144 142.986L191.556 112.384L242.322 113.504L242.273 115.923C242.03 124.219 240.763 135.502 239.741 143.49C238.848 150.584 232.938 155.747 225.876 155.747ZM195.144 118.358L197.92 142.126C198.488 147.418 202.758 151.412 208.067 151.639L225.373 152.386C230.99 152.613 235.698 148.636 236.412 143.084C237.37 135.664 238.393 126.216 238.734 118.244C223.863 117.319 201.556 118.098 195.144 118.358Z"
          fill="#F2F2F2"
        />
        <path
          d="M260.862 154.497C255.212 154.497 250.423 150.324 249.725 144.61L246.462 112.4L291.237 114.364L289.224 142.629C288.802 148.149 284.5 152.5 278.98 152.971L261.852 154.448C261.527 154.497 261.187 154.497 260.862 154.497ZM249.887 117.92L253.069 144.204C253.589 148.441 257.323 151.477 261.576 151.104L278.704 149.626C282.568 149.285 285.587 146.233 285.88 142.369L287.649 118.926C283.168 118.52 269.499 117.465 249.887 117.92Z"
          fill="#F2F2F2"
        />
        <path
          d="M245.828 140.811C245.828 140.811 246.64 146.217 250.926 144.61C255.212 143.003 257.793 144.334 258.362 147.418C258.93 150.487 250.098 156.12 243.539 152.402C236.98 148.685 243.539 135.112 243.539 135.112L245.828 140.811Z"
          fill="#915C44"
        />
        <path
          d="M233.49 175.748C233.49 175.748 236.623 174.871 242.598 177.404C248.572 179.936 250.358 182.225 250.358 182.225C250.358 182.225 251.056 175.326 244.919 172.728C238.783 170.131 233.49 175.748 233.49 175.748Z"
          fill="#7A4C39"
        />
        <path
          d="M203.017 389.527C201.978 404.187 203.699 464.288 203.699 464.288L226.623 469.142L238.312 390.322C238.312 390.339 222.873 370.581 203.017 389.527Z"
          fill="#68BC98"
        />
        <path
          d="M274.239 390.988C275.278 405.648 275.1 464.288 275.1 464.288L252.176 469.142L238.864 390.322C238.864 390.339 254.384 372.058 274.239 390.988Z"
          fill="#68BC98"
        />
        <path
          d="M220.307 217.731C220.307 217.731 204.073 293.466 204.073 370.857C237.614 375.565 271.983 373.666 271.983 373.666L263.005 217.731C263.005 217.731 246.267 213.591 220.307 217.731Z"
          fill="#F0F0F0"
        />
        <path
          d="M236.867 249.941L221.85 248.772C221.85 248.772 220.665 255.639 227.694 255.412C234.724 255.184 236.867 249.941 236.867 249.941Z"
          fill="#D1D1D1"
        />
        <path
          d="M241.086 220.435L237.623 373.628L240.138 373.685L243.601 220.492L241.086 220.435Z"
          fill="#D1D1D1"
        />
        <path
          d="M248.702 250.574L262.079 248.788C262.079 248.788 262.404 254.99 256.949 255.395C251.494 255.818 248.702 250.574 248.702 250.574Z"
          fill="#D1D1D1"
        />
        <path
          d="M195.062 263.562C195.062 263.562 183.682 288.953 172.106 289.829C156.505 291.015 137.656 236.791 137.656 236.791L134.685 237.667C134.685 237.667 135.741 304.814 165.077 305.106C194.413 305.399 206.135 268.546 206.135 268.546L195.062 263.562Z"
          fill="#9E6749"
        />
        <path
          d="M221.801 213.445C227.532 217 209.008 267.166 209.008 267.166L192.53 259.519C192.53 259.519 208.57 205.214 221.801 213.445Z"
          fill="#F0F0F0"
        />
        <path
          d="M188.926 266.208L193.731 255.672L210.615 262.734L207.725 273.643L188.926 266.208Z"
          fill="white"
        />
        <path
          d="M137.656 236.807C137.656 236.807 139.637 233.56 139.994 231.563C140.351 229.566 140.952 228.332 140.595 227.163C140.368 226.4 139.523 225.572 137.137 226.108C134.734 226.66 127.428 226.108 134.718 238.463C137.283 239.193 137.656 236.807 137.656 236.807Z"
          fill="url(#paint0_radial)"
        />
        <path
          d="M165.434 242.505C165.434 242.505 164.119 231.628 155.239 227.407C147.267 223.608 137.51 226.968 121.665 240.817C112.996 248.398 73.1882 275.251 37.7315 276.777C-16.9471 279.114 -59.9691 259.535 -59.9691 259.535L-59.5308 263.513C-59.5308 263.513 5.96016 287.297 52.7324 277.686C79.3412 272.215 105.836 256.581 116.778 248.366C128.922 239.258 141.309 225.816 153.907 230.248C163.129 233.495 162.658 242.651 162.658 242.651L165.434 242.505Z"
          fill="#BAB233"
        />
        <path
          d="M175.786 252.945C175.786 252.945 175.786 234.702 164.003 236.34C152.22 237.978 153.402 252.945 153.402 252.945C153.402 252.945 150.468 252.832 150.468 254.811C150.468 256.79 158.702 257.472 165.185 257.472C169.303 257.472 174.307 257.7 177.856 255.971C181.404 254.242 175.786 252.945 175.786 252.945Z"
          fill="#F1D576"
        />
        <path
          d="M161.682 263.955C161.205 264.797 159.066 265.684 158.361 262.727C157.406 258.723 158.066 255.129 158.066 255.129C158.066 255.129 160.477 252.832 161.682 255.129C162.865 257.427 162.16 263.114 161.682 263.955Z"
          fill="#F1D576"
        />
        <path
          d="M168.415 263.955C168.893 264.797 171.031 265.684 171.737 262.727C172.692 258.723 172.032 255.129 172.032 255.129C172.032 255.129 169.621 252.832 168.415 255.129C167.233 257.427 167.961 263.114 168.415 263.955Z"
          fill="#F1D576"
        />
        <path
          d="M137.25 237.765C137.25 237.765 145.806 230.751 142.267 229.923C138.728 229.111 135.091 228.657 135.27 229.923C135.448 231.189 139.199 231.741 139.199 231.741C139.199 231.741 130.416 234.696 137.25 237.765Z"
          fill="#9E6749"
        />
        <path
          d="M275.993 376.669C275.993 376.669 300.426 439.627 297.617 446.641C294.809 453.654 269.905 446.641 269.905 446.641L271.755 379.818L275.993 376.669Z"
          fill="#E89817"
        />
        <path
          d="M274.58 377.903C274.58 377.903 285.879 435.179 290.344 439.238C294.809 443.296 299.89 435.585 299.89 435.585L277.567 375.727L274.58 377.903Z"
          fill="#CC8614"
        />
        <path
          d="M223.149 212.146L216.038 213.218C216.038 213.218 239.773 341.083 271.755 379.819C274.223 378.487 275.993 376.669 275.993 376.669C275.993 376.669 238.734 307.477 223.149 212.146Z"
          fill="#D98E15"
        />
        <path
          d="M225.6 274.212V266.24L236.786 263.058L238.523 271.874L225.6 274.212ZM227.223 267.458V272.263L236.623 270.559L235.536 265.088L227.223 267.458Z"
          fill="#96620E"
        />
        <path
          d="M286.172 264.633C286.172 264.633 294.306 290.024 305.881 290.901C321.483 292.086 343.578 237.862 343.578 237.862L346.549 238.739C346.549 238.739 345.494 304.262 316.157 304.554C286.821 304.846 275.1 269.617 275.1 269.617L286.172 264.633Z"
          fill="#9E6749"
        />
        <path
          d="M259.433 214.516C253.702 218.072 272.226 268.237 272.226 268.237L288.704 260.591C288.704 260.591 272.665 206.285 259.433 214.516Z"
          fill="#F0F0F0"
        />
        <path
          d="M292.309 267.279L287.503 256.743L270.619 263.805L273.509 274.715L292.309 267.279Z"
          fill="white"
        />
        <path
          d="M343.204 238.252C343.204 238.252 341.484 234.859 341.289 232.845C341.078 230.832 340.591 229.55 341.045 228.413C341.337 227.683 342.23 226.904 344.584 227.634C346.938 228.365 354.26 228.413 346.013 240.135C343.399 240.654 343.204 238.252 343.204 238.252Z"
          fill="url(#paint1_radial)"
        />
        <path
          d="M315.297 244.616C315.297 244.616 316.612 233.738 325.493 229.517C333.464 225.718 342.994 225.426 359.066 242.927C375.138 260.428 425.677 272.004 449.705 277.799C496.071 288.985 537.031 295.447 537.031 295.447L536.723 298.336C536.723 298.336 479.641 289.878 419.378 273.286C393.175 266.078 374.927 258.707 363.969 250.493C351.825 241.385 342.198 226.644 329.6 231.076C320.379 234.323 318.089 244.762 318.089 244.762L315.297 244.616Z"
          fill="#BAB233"
        />
        <path
          d="M305.41 255.849C305.41 255.849 305.41 238.403 316.678 239.969C327.947 241.535 326.816 255.849 326.816 255.849C326.816 255.849 329.622 255.741 329.622 257.633C329.622 259.526 321.747 260.179 315.547 260.179C311.61 260.179 306.824 260.396 303.43 258.743C300.058 257.089 305.41 255.849 305.41 255.849Z"
          fill="#F1D576"
        />
        <path
          d="M343.529 239.242C343.529 239.242 335.558 231.563 339.146 231.027C342.734 230.491 346.403 230.329 346.11 231.579C345.834 232.829 342.052 233.073 342.052 233.073C342.052 233.073 350.575 236.726 343.529 239.242Z"
          fill="#9E6749"
        />
        <path
          d="M299.436 74.8002C281.545 36.7784 223.96 49.6363 209.414 52.9969C205.826 53.8249 200.176 58.3869 193.406 65.3516C197.839 71.0824 208.602 78.0471 208.602 78.0471C217.629 78.0471 251.105 113.26 272.421 112.871C287.13 112.595 300.361 100.776 306.157 90.1907C303.738 84.2975 301.465 79.1024 299.436 74.8002Z"
          fill="#261A11"
        />
        <path
          d="M296.205 69.0531C293.624 72.0403 290.263 75.4659 286.48 78.3394C282.129 81.6513 277.21 84.2326 272.145 84.606C266.495 85.0444 261.251 82.5929 256.592 77.3653C243.491 62.6729 227.11 61.0494 215.81 62.2021H215.794C210.128 62.7865 205.745 64.0691 203.716 64.7509C202.579 63.03 201.199 61.2442 199.511 59.4746L197.173 61.6339C199.673 64.2152 201.41 66.7965 202.644 69.1505C205.274 74.232 205.469 78.1933 205.485 78.4693L207.06 78.4206L208.635 78.3719C208.635 78.3394 208.635 78.2258 208.618 78.0309C208.537 76.8296 208.034 72.6248 205.372 67.5271C211.995 65.3354 236.185 59.2311 254.238 79.4758C259.173 85.0281 264.758 87.8367 270.862 87.8367C271.366 87.8367 271.885 87.8205 272.405 87.7718C278.087 87.3334 283.526 84.5735 288.234 81.0344C291.984 78.2095 295.312 74.8976 297.958 71.9267L296.205 69.0531Z"
          fill="#3F2B1D"
        />
        <path
          d="M306.287 90.5156L304.891 87.1387C300.491 92.7397 295.28 99.1524 290.653 104.185L290.636 104.201C288.818 106.182 287.097 107.952 285.571 109.348L285.555 109.364L290.783 106.815L306.287 90.5156Z"
          fill="#3F2B1D"
        />
        <path
          d="M241.705 209.273L242.679 215.896L244.497 209.695L248.686 217.731C248.686 217.731 244.075 220.458 242.403 220.458C240.731 220.458 237.922 218.786 237.922 218.786L241.705 209.273Z"
          fill="#DBDBDB"
        />
        <path
          d="M221.574 217.536L226.477 203.867L241.705 209.273C241.705 209.273 238.896 225.215 232.548 224.647C226.201 224.079 221.574 217.536 221.574 217.536Z"
          fill="#FCFCFC"
        />
        <path
          d="M257.712 204.305L244.513 209.679C244.513 209.679 244.513 221.416 249.887 222.748C255.277 224.079 263.654 215.539 263.654 215.539L257.712 204.305Z"
          fill="#FCFCFC"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.3738 95.4939C76.7768 93.974 78.7027 93.0894 79.6377 92.686C80.1806 92.4517 80.4887 92.3366 80.4887 92.3366L82.1738 97.0296L81.3269 94.6939L82.1738 97.0296C82.1436 97.0426 81.9411 97.1122 81.5942 97.2618C80.7949 97.6067 79.1255 98.3807 77.0196 99.7187L74.3738 95.4939ZM64.1681 104.012C65.6651 102.382 67.2895 100.84 68.9876 99.4279L72.1931 103.25C70.6716 104.533 69.1955 105.921 67.847 107.397L64.1681 104.012ZM56.4695 114.775C57.528 112.834 58.7094 110.929 59.9861 109.161L64.0455 112.061C62.8871 113.687 61.8195 115.4 60.8512 117.178L56.4695 114.775ZM51.5049 127.024C51.5883 126.72 51.6717 126.415 51.7701 126.105C52.322 124.274 52.9603 122.479 53.6461 120.752L58.2638 122.623C57.6228 124.205 57.0511 125.865 56.5376 127.536C56.4563 127.803 56.375 128.071 56.3088 128.332L51.5049 127.024ZM50.1404 140.403C50.0033 138.136 50.0495 135.881 50.2769 133.672L55.2363 134.179C55.0383 136.125 55.0001 138.109 55.1301 140.11L50.1404 140.403ZM62.3202 170.744L66.2853 167.746C68.3192 170.428 69.8067 171.968 69.8348 171.992L68.0599 173.724L69.3139 175.884C67.3218 177.03 66.2906 176.008 62.3202 170.744ZM52.8704 153.446C52.1147 151.321 51.5012 149.153 51.0539 147.039L55.9232 146.011C56.3311 147.91 56.8837 149.854 57.5614 151.797L52.8704 153.446ZM55.4259 159.534L59.9042 157.351C60.7669 159.144 61.7548 160.935 62.8482 162.682L58.6104 165.297C57.4433 163.422 56.3688 161.471 55.4259 159.534Z"
        fill="#EDEDEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.1443 94.675C90.7616 95.7031 87.9984 94.6057 86.9703 92.223C85.9422 89.8403 87.0396 87.0771 89.4222 86.049C91.8049 85.0209 94.5681 86.1183 95.5962 88.501C96.6243 90.8837 95.5269 93.6468 93.1443 94.675Z"
        fill="#EDEDEE"
      />
      <path
        d="M303.795 365.259C303.458 364.845 315.691 351.732 315.654 350.973C315.614 350.214 308.606 345.275 308.603 343.962C308.599 342.649 330.035 329.717 330.34 330.195C330.643 330.672 318.442 343.91 318.479 344.48C318.519 345.048 325.688 350.152 325.53 351.491C325.374 352.83 304.131 365.672 303.795 365.259Z"
        fill="#7B7FDA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M352.031 330.188C352.031 332.95 349.793 335.188 347.031 335.188C344.269 335.188 342.031 332.95 342.031 330.188C342.031 327.426 344.269 325.188 347.031 325.188C349.776 325.188 352.031 327.426 352.031 330.188Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M382.822 297.365L377.561 296.855C377.683 295.553 377.753 294.199 377.753 292.844C377.753 292.07 377.736 291.279 377.683 290.469L382.961 290.188C383.014 291.085 383.031 291.965 383.031 292.844C383.031 294.374 382.961 295.905 382.822 297.365ZM378.92 311.279L374.199 308.886C375.123 307.039 375.872 305.087 376.429 303.046L381.516 304.471C380.871 306.846 379.983 309.133 378.92 311.279ZM370.071 322.712L366.605 318.702C368.19 317.312 369.618 315.782 370.89 314.146L375.053 317.435C373.572 319.335 371.9 321.111 370.071 322.712ZM357.634 330.188L356.031 325.104L356.832 327.637L356.031 325.104C356.048 325.104 358.417 324.313 361.536 322.413L364.27 326.987C360.577 329.238 357.756 330.153 357.634 330.188Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.8628 353.94L45.7699 354.967C45.4154 353.278 45.1349 351.532 44.9171 349.783L50.0918 349.164C50.2724 350.771 50.5544 352.378 50.8628 353.94ZM53.6432 363.173L48.7951 365.084C48.1398 363.46 47.5586 361.779 47.04 360.094L52.0449 358.635C52.5113 360.169 53.0552 361.708 53.6432 363.173ZM58.0611 371.722L53.6695 374.501C52.7082 373.018 51.821 371.478 51.0141 369.905L55.6578 367.554C56.3736 368.974 57.1997 370.378 58.0611 371.722ZM64.018 379.309L60.2072 382.847C58.9912 381.556 57.8105 380.205 56.74 378.839L60.862 375.662C61.8387 376.914 62.8958 378.132 64.018 379.309ZM71.2677 385.769L68.1194 389.895C66.6938 388.822 65.3184 387.697 64.0233 386.54L67.4966 382.684C68.6804 383.756 69.9533 384.78 71.2677 385.769ZM76.9879 395.6C76.3486 395.246 75.7093 394.892 75.0638 394.515C74.1931 394.006 73.3161 393.473 72.4418 392.902L75.2774 388.553C76.0767 389.08 76.8935 389.578 77.7042 390.052C78.3047 390.403 78.8989 390.73 79.4782 391.049L76.9879 395.6ZM86.503 400.04C84.861 399.401 83.2391 398.693 81.68 397.982L83.8912 393.288C85.3515 393.961 86.8833 394.617 88.4177 395.233L86.503 400.04ZM96.4391 403.326C94.7453 402.876 93.0628 402.373 91.4218 401.834L93.0637 396.908C94.6208 397.418 96.2194 397.891 97.8055 398.318L96.4391 403.326ZM106.829 405.434C106.751 405.429 104.761 405.207 101.537 404.544L102.595 399.476C105.527 400.088 107.355 400.276 107.37 400.285L106.829 405.434ZM119.494 397.481C122.181 399.052 123.097 402.49 121.513 405.168C119.928 407.846 116.46 408.722 113.773 407.151C111.071 405.572 110.17 402.142 111.755 399.464C113.33 396.801 116.807 395.91 119.494 397.481Z"
        fill="#EDEDEE"
      />
      <circle
        cx="38.347"
        cy="309.482"
        r="2.97403"
        transform="rotate(-100.567 38.347 309.482)"
        stroke="#7A7DE1"
        strokeWidth="3.32263"
      />
      <circle
        cx="46.8173"
        cy="322.534"
        r="2.97403"
        transform="rotate(-100.567 46.8173 322.534)"
        stroke="#7A7DE1"
        strokeWidth="3.32263"
      />
      <circle
        cx="55.0331"
        cy="305.912"
        r="2.97403"
        transform="rotate(-100.567 55.0331 305.912)"
        stroke="#7A7DE1"
        strokeWidth="3.32263"
      />
      <circle
        cx="63.3064"
        cy="324.791"
        r="2.97403"
        transform="rotate(-100.567 63.3064 324.791)"
        stroke="#7A7DE1"
        strokeWidth="3.32263"
      />
      <path d="M39.9467 311.558L44.4219 320.181" stroke="#7A7DE1" strokeWidth="3.32263" />
      <path d="M48.9279 318.682L53.6394 309.043" stroke="#7A7DE1" strokeWidth="3.32263" />
      <path d="M49.6055 323.791L60.15 324.916" stroke="#7A7DE1" strokeWidth="3.32263" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.623 414.007L155.951 421.707H147.274L151.623 414.007ZM151.623 404.188L139.031 426.574H164.215L151.623 404.188Z"
        fill="#EA7052"
      />
      <path
        d="M116.493 90.112C112.377 90.112 109.031 86.7657 109.031 82.65C109.031 78.5343 112.377 75.188 116.493 75.188C120.609 75.188 123.955 78.5343 123.955 82.65C123.955 86.7478 120.609 90.112 116.493 90.112ZM116.493 79.1426C114.56 79.1426 112.986 80.7174 112.986 82.65C112.986 84.5826 114.56 86.1573 116.493 86.1573C118.425 86.1573 120 84.5826 120 82.65C120 80.6995 118.425 79.1426 116.493 79.1426Z"
        fill="#EA7052"
      />
      <path
        d="M426.462 377.924C422.346 377.924 419 374.578 419 370.462C419 366.346 422.346 363 426.462 363C430.578 363 433.924 366.346 433.924 370.462C433.924 374.56 430.578 377.924 426.462 377.924ZM426.462 366.955C424.529 366.955 422.955 368.529 422.955 370.462C422.955 372.395 424.529 373.969 426.462 373.969C428.395 373.969 429.969 372.395 429.969 370.462C429.969 368.511 428.395 366.955 426.462 366.955Z"
        fill="#949AED"
      />
      <path
        d="M376.375 124.052V139.145C376.375 146.666 386.344 144.16 386.344 150.845V154.188V141.368C386.344 134.957 392.687 137.361 392.687 130.15V115.188M376.375 124.052L382.719 129.37M376.375 124.052L370.031 129.37M392.687 115.188L386.344 120.506M392.687 115.188L399.031 120.506"
        stroke="#FC6747"
        strokeWidth="4"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(140.168 235.408) rotate(180) scale(6.91795)">
          <stop stopColor="#9E6749" />
          <stop offset="1" stopColor="#915C44" />
        </radialGradient>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(345.457 239.721) rotate(-67.3801) scale(6.75711)">
          <stop stopColor="#9E6749" />
          <stop offset="1" stopColor="#915C44" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default ErrorBoundary;
