import * as actionTypes from './actionTypes';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import firebase from 'firebase/app';
import 'firebase/firestore';
import axiosInstance from 'Utils/axios-orders';
import * as urls from 'Navigation/UrlStore.json';
import { BADGE_KEY_MAP } from 'Utils/badges';
import { dateIntervals, timeIntervals, catchHelper } from 'Functions';
import { RootState } from 'Reducers';
import { reduxResponse, ThunkResult } from 'Types/general';
import { fetchLesson } from 'Actions/content/action';
import { ILesson } from 'Types/content';
import { logMessage } from 'Utils/logger';
import { trackExamProgress, trackSaveTestProgress, trackVideoCapReached } from 'Utils/tracking';
import { trackLessonStarted } from 'Utils/tracking/lessons';
import { captureException } from 'Utils/sentry';
import {
  TrackExamProgressTypes,
  TrackLessonTypes,
  TrackSaveTestProgressTypes,
} from 'Utils/tracking/types';

const axios = axiosInstance();

const lessonsCollectionName: string = 'lessonsWatched';
const questsCollectionName: string = 'questsWatched';
const examsCollectionName: string = 'examsProgress';
const practiceCollectionName: string = 'practiceProgress';
const testsCollectionName: string = 'testsProgress';
const badgesCollectionName: string = 'badgesAwarded';

const clearQuizAnswersAction = (payload: any) => {
  return {
    type: actionTypes.CLEAR_QUIZ_PREVIOUS_ANSWERS,
    payload,
  };
};

const addQuizAnswerAction = (payload: any) => {
  return {
    type: actionTypes.ADD_QUIZ_PREVIOUS_ANSWERS,
    payload,
  };
};

const saveLessonsWatched = (array: Array<any>) => {
  return {
    type: actionTypes.SAVE_LESSONS_WATCHED,
    payload: array,
  };
};

const subjectsContinueQuest = (array: Array<any>) => {
  return {
    type: actionTypes.SUBJECT_CONTINUE_QUEST,
    payload: array,
  };
};

const saveResumeJourney = (array: Array<any>) => {
  return {
    type: actionTypes.RESUME_JOURNEY,
    payload: array,
  };
};

const saveToHighlight = (object: Array<any>) => {
  return {
    type: actionTypes.SAVE_HIGHLIGHTS,
    payload: object,
  };
};

export const playLessons = (
  lesson: ILesson,
  lessonsOfSameQuest: Array<ILesson>,
  videoButtonType: string
) => (dispatch: Function) => {
  const eventParam: TrackLessonTypes = {
    lesson_id: lesson.id,
    lesson_name: lesson.name,
  };

  trackLessonStarted(eventParam);

  dispatch({
    type: actionTypes.PLAY_LESSONS,
    payload: {
      selectedLesson: lesson,
      lessonsOfSameQuest,
      type: videoButtonType,
    },
  });
};

export const getLessonsWatched = () => async (dispatch: Function, getState: Function) => {
  try {
    const externalId = getState().auth?.learner.id;
    const gradeId = getState().auth?.learner.grade.id;
    const progressLessons = getState().video.progressLessons;
    let newLessonsWatched: Array<any> = [];
    const res = await firebase
      .firestore()
      .collection(lessonsCollectionName)
      .where('gradeId', '==', gradeId)
      .where('externalId', '==', externalId)
      .orderBy('updatedAt', 'desc')
      .get();
    const array: Array<any> = !res.empty
      ? res.docs.map((val, idx) => {
          return {
            index: idx,
            ...val.data(),
          };
        })
      : [];

    if (progressLessons && progressLessons.length > 0) {
      const mergedArrays = [...array, ...progressLessons].sort(
        (a: any, b: any) =>
          b &&
          b.updatedAt &&
          a &&
          a.updatedAt &&
          new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf()
      );
      mergedArrays.forEach(
        (item: { questId: number; lessonId: number; isComplete: boolean; timeSpent: number }) => {
          const lessonToCheck = newLessonsWatched.find(
            lesson => lesson.questId === item.questId && lesson.lessonId === item.lessonId
          );

          if (item.isComplete === false) return;
          else if (lessonToCheck) {
            item.timeSpent += lessonToCheck.timeSpent;
            newLessonsWatched = newLessonsWatched.filter(
              lessonWatched =>
                !(
                  lessonWatched.questId === item.questId && lessonWatched.lessonId === item.lessonId
                )
            );
          }

          newLessonsWatched.push(item);
        }
      );
    }
    await dispatch(saveLessonsWatched(newLessonsWatched));
    dispatch(continueQuestForSubjects());
    dispatch(getResumeJourney());
  } catch (error) {
    captureException(error);
  }
};

export const addOrUpdateLessonsWatched = (object: any) => async (
  dispatch: Function,
  getState: Function
) => {
  try {
    const externalId = getState().auth?.learner.id;
    const gradeId = getState().auth?.learner.grade.id;

    const arr = [
      {
        id: object.lessonId,
        completed_at: new Date().toISOString(),
        grade_id: gradeId,
        uuid: uuid(),
        time_spent: object.timeSpent,
        quest_id: object.questId,
      },
    ];

    await dispatch(saveLessonsBackend(arr));
    dispatch(getLessonsWatched());

    const eventParams: TrackLessonTypes = {
      lesson_id: object.lessonId,
      lesson_name: object.lessonName,
    };
    trackLessonStarted(eventParams);

    const res = await firebase
      .firestore()
      .collection(lessonsCollectionName)
      .where('gradeId', '==', gradeId)
      .where('externalId', '==', externalId)
      .where('subjectId', '==', object.subjectId)
      .where('questId', '==', object.questId)
      .where('lessonId', '==', object.lessonId)
      .limit(1)
      .get();
    if (res.empty) {
      firebase
        .firestore()
        .collection(lessonsCollectionName)
        .add({
          ...object,
          externalId,
          gradeId,
          isComplete: true,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } else if (res && !res.empty && res.docs && res.docs[0] && res.docs[0].data()) {
      firebase
        .firestore()
        .collection(lessonsCollectionName)
        .doc(res.docs[0].id)
        .update({
          timeSpent: firebase.firestore.FieldValue.increment(object.timeSpent),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }
    return true;
  } catch (error) {
    captureException(error);
    return true;
  }
};

export const addQuestsWatched = (object: any, boolean: boolean) => async (
  dispatch: Function,
  getState: Function
) => {
  const externalId = getState().auth?.learner.id;
  const gradeId = getState().auth?.learner.grade.id;
  try {
    const res = await firebase
      .firestore()
      .collection(questsCollectionName)
      .where('gradeId', '==', gradeId)
      .where('externalId', '==', externalId)
      .where('questId', '==', object.questId)
      .where('subjectId', '==', object.subjectId)
      .where('isComplete', '==', false)
      .limit(1)
      .get();
    if (boolean === true && res.empty) {
      await firebase
        .firestore()
        .collection(questsCollectionName)
        .add({
          ...object,
          externalId,
          gradeId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } else {
      if (res && !res.empty && res.docs && res.docs[0] && res.docs[0].id) {
        await firebase.firestore().collection(questsCollectionName).doc(res.docs[0].id).update({
          isComplete: true,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
    }
    await dispatch(continueQuestForSubjects());
    await dispatch(getResumeJourney());
  } catch (error) {
    captureException(error);
  }
};

export const getQuestProgress = (
  item: any,
  subjectId: string | number,
  returnNoOfWatched: boolean
) => async (dispatch: Function, getState: Function) => {
  const externalId = getState().auth?.learner.id;
  const gradeId = getState().auth?.learner.grade.id;
  const lessonsWatched = getState().video.lessonsWatched;
  let noOfWatched = 0;
  let lastLesson = null;
  if (!item.lessons || item.test) {
    return '0';
  }
  await item.lessons.forEach((value: any) => {
    const result = lessonsWatched
      ? lessonsWatched.find(
          (val: any) =>
            val.externalId === externalId &&
            val.gradeId === gradeId &&
            val.subjectId === subjectId &&
            val.lessonId === value.id &&
            val.questId === item.id
        )
      : null;
    if (result) {
      noOfWatched += 1;
      lastLesson = result;
    }
  });
  if (returnNoOfWatched) {
    return { noOfWatched, lastLesson };
  } else {
    const float = (noOfWatched / item.lessons.length) * 100;
    return float.toFixed(0);
  }
};

export const continueQuestForSubjects = () => async (dispatch: Function, getState: Function) => {
  const quests = getState().home.quests;
  const lessonsWatched = getState().video.lessonsWatched;
  const subjects = [...new Set(getState().home.subjects.map((val: any) => val.subjectId))];
  let array: Array<any> = [];
  const arrayResult: Array<any> = [];
  await Promise.all(
    subjects.map(async (value: any) => {
      const exist = lessonsWatched.find(
        (val: any) => val.subjectId === value && val.isComplete === false
      );
      if (exist) {
        arrayResult.push(exist);
      }
    })
  );
  if (arrayResult && arrayResult.length > 0) {
    await Promise.all(
      arrayResult.map(async object => {
        const originalQuestObject = quests.find((value: any) => value.id === object.questId);
        const getLastLessonObject =
          originalQuestObject &&
          originalQuestObject.lessons[originalQuestObject.lessons.length - 1];
        const objectExist =
          getLastLessonObject &&
          lessonsWatched.find((val: any) => val.lessonId === getLastLessonObject.id);
        if (objectExist && originalQuestObject) {
          array = [...array, originalQuestObject];
        }
      })
    );
    await dispatch(subjectsContinueQuest(array));
  }
};

export const removeContinueQuestForSubjects = (subjectId: string | number) => (
  dispatch: Function,
  getState: Function
) => {
  const subjectContinueQuest = getState().video.subjectContinueQuest;
  const array = subjectContinueQuest.filter((val: any) => val.subjectId !== subjectId);
  dispatch(subjectsContinueQuest(array));
};

export const getResumeJourney = () => async (dispatch: Function, getState: Function) => {
  const quests = getState().home.quests;
  const lessonsWatched = getState().video.lessonsWatched;
  let array: Array<any> = [];
  let uniqueQuests: Array<any> = [];
  const exist = lessonsWatched.filter((val: any) => val.isComplete === false);
  const unique: Array<any> = [];
  exist.forEach((item: { questId: number }) => {
    if (unique.includes(item.questId)) return;
    unique.push(item.questId);
    uniqueQuests.push(item);
  });
  uniqueQuests &&
    uniqueQuests.length > 0 &&
    (await Promise.all(
      uniqueQuests.map(async object => {
        const originalQuestObject = quests.find((value: any) => value.id === object.questId);
        const getLastLessonObject =
          originalQuestObject &&
          originalQuestObject.lessons[originalQuestObject.lessons.length - 1];
        const objectExist =
          getLastLessonObject &&
          lessonsWatched.find((val: any) => val.lessonId === getLastLessonObject.id);
        if (objectExist && originalQuestObject) {
          array = [...array, originalQuestObject];
          return true;
        }
      })
    ));
  await dispatch(saveResumeJourney(array));
};

export const addAnsweredQuizQuestion = (
  lessonId: string | number,
  questionId: string | number
) => async (dispatch: Function) => {
  await dispatch(addQuizAnswerAction({ lessonId, questionId }));
};

export const clearAnsweredQuizQuestion = (lessonId: string | number) => async (
  dispatch: Function
) => {
  await dispatch(clearQuizAnswersAction({ lessonId }));
};

export const saveExamProgress = (object: any, array: Array<any>) => async (
  dispatch: Function,
  getState: Function
) => {
  try {
    const externalId = getState().auth?.learner.id;
    const gradeId = getState().auth?.learner.grade_group_id;
    const examInstance = getState().content.practiceExam.find(
      (examState: any) => examState.id === object.examId
    );
    const id = await firebase
      .firestore()
      .collection(examsCollectionName)
      .add({
        gradeId,
        externalId,
        subjectId: object.subjectId,
        examId: object.examId,
        exams: array,
        createdAt: new Date().toISOString(),
      })
      .then(res => {
        res.update({
          docId: res.id,
        });
        return res.id;
      });

    const remappedQuestions = examInstance.questions.map((question: any, idx: number) => {
      const examData = array[idx];
      return {
        id: question.id,
        time_spent: examData.timeSpent,
        grade_id: gradeId,
        marked_for_review: false,
        uuid: uuid(),
        shown_at: examData.createdAt ? examData.createdAt : new Date(),
        is_correct:
          examData.accuracy && examData.accuracy === 1
            ? true
            : examData.accuracy === 0
            ? false
            : null,
        options_selected:
          examData.selected === -1
            ? []
            : [{ id: question.options[examData.selected].id, option_id: uuid() }],
      };
    });

    const arr = [
      {
        subject_id: object.subjectId,
        exam_id: object.examId,
        uuid: uuid(),
        attempted_at: new Date().toISOString(),
        score_percentage:
          (array.reduce((acc, obj) => {
            return obj.accuracy > 0 ? acc + obj.accuracy : acc;
          }, 0) /
            array.length) *
          100,
        time_spent: array.reduce((acc, obj) => {
          return obj.timeSpent > 0 ? acc + obj.timeSpent : acc;
        }, 0),
        questions: remappedQuestions,
      },
    ];

    const eventParams: TrackExamProgressTypes = {
      exam_name: examInstance.name,
      exam_id: examInstance.id,
      subject_name: object.displayName,
      subject_id: object.subjectId,
      duration: array.reduce((acc, obj) => {
        return obj.timeSpent > 0 ? acc + obj.timeSpent : acc;
      }, 0),
      number_of_correct_answers: array.reduce((acc, obj) => {
        return obj.accuracy > 0 ? acc + obj.accuracy : acc;
      }, 0),
      number_of_wrong_answers: array.reduce((acc, obj) => {
        return obj.accuracy === 0 ? acc + obj.accuracy : acc;
      }, 0),
      percent_completed:
        array.reduce((acc, obj) => {
          return obj.accuracy > -1 ? acc + obj.accuracy : acc;
        }, 0) / array.length,
    };
    trackExamProgress(eventParams);

    await dispatch(saveExamsBackend(arr));
    return id;
  } catch (error) {
    captureException(error);
  }
};

export const saveTestProgress = (object: any, array: Array<any>) => async (
  dispatch: Function,
  getState: () => RootState
) => {
  try {
    const externalId = getState().auth?.learner.id;
    const gradeId = getState().auth?.learner.grade.id;
    const testInstance = getState().content.chapterTest.find(
      (testState: any) => testState.id === object.testId
    );
    const id = await firebase
      .firestore()
      .collection(testsCollectionName)
      .add({
        gradeId,
        externalId,
        subjectId: object.subjectId,
        testId: object.testId,
        chapterId: object.chapterId,
        tests: array,
        createdAt: new Date().toISOString(),
      })
      .then(res => {
        res.update({
          docId: res.id,
        });
        return res.id;
      });

    const remappedQuestions = testInstance.questions.map((question: any, idx: number) => {
      const testData = array[idx];
      testData.selected =
        typeof testData.selected != 'number'
          ? testData.selected[testData.selected.length - 1]
          : testData.selected;
      return {
        id: question.id,
        time_spent: testData.timeSpent,
        grade_id: gradeId,
        marked_for_review: false,
        uuid: uuid(),
        shown_at: testData.createdAt ? testData.createdAt : new Date(),
        is_correct:
          testData.accuracy && testData.accuracy === 1
            ? true
            : testData.accuracy === 0
            ? false
            : null,
        options_selected:
          testData.selected === -1
            ? []
            : [{ id: question.options[testData.selected].id, option_id: uuid() }],
      };
    });

    const arr = [
      {
        chapter_id: object.chapterId,
        grade_id: gradeId,
        test_id: object.testId,
        uuid: uuid(),
        attempted_at: new Date().toISOString(),
        score_percentage:
          (array.reduce((acc, obj) => {
            return obj.accuracy > 0 ? acc + obj.accuracy : acc;
          }, 0) /
            array.length) *
          100,
        time_spent: array.reduce((acc, obj) => {
          return obj.timeSpent > 0 ? acc + obj.timeSpent : acc;
        }, 0),
        questions: remappedQuestions,
      },
    ];

    const eventParams: TrackSaveTestProgressTypes = {
      test_id: object.testId,
      chapter_id: object.chapterId,
      chapter_name: '',
      subject_id: object.subjectId,
      subject_name: '',
      score_percentage:
        array.reduce((acc, obj) => {
          return obj.accuracy > 0 ? acc + obj.accuracy : acc;
        }, 0) / array.length,
      percent_completed: array.filter(item => item.accuracy !== -1).length / array.length,
      time_spent: array.reduce((acc, obj) => {
        return obj.timeSpent > 0 ? acc + obj.timeSpent : acc;
      }, 0),
    };
    trackSaveTestProgress(eventParams);

    await dispatch(saveTestsBackend(arr));
    return id;
  } catch (error) {
    captureException(error);
  }
};

export const examsForHighlights = (
  id: string,
  subjectId: string | number,
  examId: string | number,
  test?: boolean,
  testQuestions?: any
) => async (dispatch: Function, getState: Function) => {
  try {
    const exams = getState().content.practiceExam;
    const examQuestions = exams.find((val: any) => val.id === examId);
    let revisionArray: Array<any> = [];
    const collectionName = !test ? examsCollectionName : testsCollectionName;
    const objectToUse = !test ? examQuestions : testQuestions;
    const res = await firebase.firestore().collection(collectionName).doc(id).get();
    const array: Array<any> =
      res.exists && !test ? res.data()?.exams : res.exists && test ? res.data()?.tests : [];
    const totalQuestions = array ? array.length : 0;
    const getAllCorrect = array.filter((val: any) => val.accuracy === 1);
    const getAllInCorrect = array.filter((val: any) => val.accuracy === 0);
    const getAllIgnored = array.filter((val: any) => val.accuracy === -1);
    const easyCorrect = getAllCorrect.filter((val: any) => val.difficulty === 'Easy');
    const easyInCorrect = getAllInCorrect.filter((val: any) => val.difficulty === 'Easy');
    const easyIgnored = getAllIgnored.filter((val: any) => val.difficulty === 'Easy');

    const intCorrect = getAllCorrect.filter((val: any) => val.difficulty === 'Intermediate');
    const intInCorrect = getAllInCorrect.filter((val: any) => val.difficulty === 'Intermediate');
    const intIgnored = getAllIgnored.filter((val: any) => val.difficulty === 'Intermediate');

    const hardCorrect = getAllCorrect.filter((val: any) => val.difficulty === 'Hard');
    const hardInCorrect = getAllInCorrect.filter((val: any) => val.difficulty === 'Hard');
    const hardIgnored = getAllIgnored.filter((val: any) => val.difficulty === 'Hard');

    const getSetOfIncorrect = [...new Set(getAllInCorrect.map((val: any) => val.questionNo))];
    const highLightAccuracy = getAllCorrect.length / totalQuestions;
    const totalTimeSpent = array.reduce((a: any, b: any) => a + (b.timeSpent || 0), 0);
    const averageTimeSpent = (totalTimeSpent / totalQuestions).toFixed(0);
    const quizArrayOfIncorrect = objectToUse.questions.filter((value: any, key: any) =>
      getSetOfIncorrect.includes(key)
    );
    await quizArrayOfIncorrect.forEach((item: any) => {
      const lessonIds = item.lesson_id ? item.lesson_id : item.lesson_ids ? item.lesson_ids : [];
      revisionArray = [...revisionArray, ...lessonIds];
    });

    const hightLightObject: any = {
      subjectId,
      examId,
      all: array,
      correctNo: getAllCorrect.length,
      inCorrectNo: getAllInCorrect.length,
      ignoreNo: getAllIgnored.length,
      highLightAccuracy,
      averageTimeSpent,
      revisionArray: revisionArray,
      easyCorrect: easyCorrect.length,
      easyInCorrect: easyInCorrect.length,
      easyIgnored: easyIgnored.length,
      intCorrect: intCorrect.length,
      intInCorrect: intInCorrect.length,
      intIgnored: intIgnored.length,
      hardCorrect: hardCorrect.length,
      hardInCorrect: hardInCorrect.length,
      hardIgnored: hardIgnored.length,
      duration: objectToUse.duration,
    };
    await dispatch(saveToHighlight(hightLightObject));
  } catch (error) {
    captureException(error);
  }
};

export const getUniqueExamsTaken = (subjectId: string | number) => async (
  dispatch: Function,
  getState: Function
) => {
  if (!subjectId) return [];
  const externalId = getState().auth?.learner.id;
  const gradeId = getState().auth?.learner.grade_group_id;
  try {
    const res = await firebase
      .firestore()
      .collection(examsCollectionName)
      .where('gradeId', '==', gradeId)
      .where('externalId', '==', externalId)
      .where('subjectId', '==', subjectId)
      .get();
    const arrayResult: Array<any> = !res.empty
      ? res.docs.map(value => {
          return {
            ...value.data(),
          };
        })
      : [];
    const examsExist = [...new Set(arrayResult.map((val: any) => val.examId))];
    return { examsExist, arrayResult };
  } catch (error) {
    captureException(error);
  }
};

export const getUniqueTestTaken = (
  subjectId: string | number,
  chapterId: string | number
) => async (dispatch: Function, getState: Function) => {
  if (!subjectId) return [];
  const externalId = getState().auth?.learner.id;
  const gradeId = getState().auth?.learner.grade.id;
  try {
    const res = await firebase
      .firestore()
      .collection(testsCollectionName)
      .where('gradeId', '==', gradeId)
      .where('externalId', '==', externalId)
      .where('subjectId', '==', subjectId)
      .where('chapterId', '==', chapterId)
      .limit(1)
      .get();
    const arrayResult: Array<any> = !res.empty
      ? res.docs.map(value => {
          return {
            ...value.data(),
          };
        })
      : [];
    const testsExist = [...new Set(arrayResult.map((val: any) => val.testId))];
    return { testsExist, arrayResult };
  } catch (error) {
    captureException(error);
  }
};

export const deleteExamsRecords = (id: string) => async () => {
  try {
    await firebase.firestore().collection(examsCollectionName).doc(id).delete();
    return true;
  } catch (error) {
    captureException(error);
  }
};

export const deleteTestRecords = (id: string) => async () => {
  try {
    await firebase.firestore().collection(testsCollectionName).doc(id).delete();
    return true;
  } catch (error) {
    captureException(error);
  }
};

export const setSelectedPractice = (
  subjectId: string | number,
  chapterId: string | number,
  practice_questions: any,
  arrayOfQuestIds: Array<any>,
  name: string
) => async (dispatch: Function, getState: Function) => {
  const lessonsWatched = getState().video.lessonsWatched;
  const getLessonsPracticed = await lessonsWatched?.filter((item: any) =>
    arrayOfQuestIds.includes(item.questId)
  );

  const lessons = await dispatch(
    fetchLesson({ chapterId: chapterId as number, byPassStore: true })
  );

  const lessonsData = await getLessonsPracticed?.map((val: any) => {
    const getLesson = lessons?.data?.find((lesson: ILesson) => lesson.id === val.lessonId);
    return {
      ...val,
      ...getLesson,
    };
  });
  dispatch({
    type: actionTypes.SET_SELECTED_PRACTICE,
    payload: { subjectId, chapterId, practice_questions, lessons: lessonsData, name },
  });
};

export const getPracticeSelected = (
  subjectId: string | number,
  chapterId: string | number
) => async (dispatch: Function, getState: Function) => {
  const externalId = getState().auth?.learner.id;
  const gradeId = getState().auth?.learner.grade.id;
  let final = {};
  const result = await firebase
    .firestore()
    .collection(practiceCollectionName)
    .where('externalId', '==', externalId)
    .where('gradeId', '==', gradeId)
    .where('subjectId', '==', subjectId)
    .where('chapterId', '==', chapterId)
    .limit(1)
    .get();
  const array: Array<any> = !result.empty
    ? result.docs.map((item: any, key: number) => {
        return {
          ...item.data(),
        };
      })
    : [];
  if (array.length === 0) {
    const emptyObject = {
      level: 0,
      empty: true,
      percent: 0,
      questions: 0,
      totalTime: 0,
      averageTime: 0,
      docId: null,
      practice: [],
    };
    dispatch({ type: actionTypes.SET_SELECTED_PRACTICE, payload: emptyObject });
    return emptyObject;
  }
  let totalTime = 0;
  for (var i in array[0].practice) {
    totalTime += array[0].practice[i].timeSpent;
  }
  const averageTime = totalTime / array[0].practice.length;
  const getCorrect = array[0].practice.filter((val: any) => val.accuracy === 1);
  const percent = getCorrect.length / array[0].practice.length;
  const sorted_practice = array[0].practice.sort((a: any, b: any) => b.level - a.level);
  const pastLevel = sorted_practice[0].level;
  if (pastLevel === 0) {
    final =
      array[0] && array[0].level >= 0
        ? { level: array[0].level }
        : percent < 0.6
        ? { level: 0 }
        : { level: 1 };
  } else if (pastLevel === 1) {
    final =
      array[0] && array[0].level >= 0
        ? { level: array[0].level }
        : percent < 0.6
        ? { level: 1 }
        : { level: 2 };
  } else if (pastLevel === 2) {
    final =
      array[0] && array[0].level >= 0
        ? { level: array[0].level }
        : percent < 0.6
        ? { level: 2 }
        : { level: 3 };
  }

  const finalObject = {
    ...final,
    percent,
    docId: result.docs[0].id,
    practice: array[0].practice,
    questions: array[0].practice.length,
    totalTime,
    averageTime,
  };
  await dispatch({
    type: actionTypes.SET_SELECTED_PRACTICE,
    payload: finalObject,
  });
  return finalObject;
};

export const savePracticeProgress = (
  object: any,
  array: Array<any>,
  practice: Array<any>,
  docId: string | undefined,
  level: number,
  newLevel: number
) => async (dispatch: Function, getState: Function) => {
  const externalId = getState().auth?.learner.id;
  const gradeId = getState().auth?.learner.grade.id;
  try {
    if (docId && level === 3) {
      await firebase.firestore().collection(practiceCollectionName).doc(docId).delete();
      await firebase
        .firestore()
        .collection(practiceCollectionName)
        .add({
          externalId,
          gradeId,
          subjectId: object.subjectId,
          chapterId: object.chapterId,
          level: newLevel,
          practice: array,
          createdAt: new Date().toISOString(),
        })
        .then(res => {
          res.update({
            docId: res.id,
          });
          return res.id;
        });
    } else if (docId) {
      await firebase
        .firestore()
        .collection(practiceCollectionName)
        .doc(docId)
        .update({
          level: newLevel,
          practice: [...practice, ...array],
        });
    } else {
      await firebase
        .firestore()
        .collection(practiceCollectionName)
        .add({
          externalId,
          gradeId,
          subjectId: object.subjectId,
          chapterId: object.chapterId,
          level: newLevel,
          practice: array,
        })
        .then(res => {
          res.update({
            docId: res.id,
          });
          return res.id;
        });
    }
  } catch (error) {
    captureException(error);
  }
};

export const updateAttemptedTestIds = (testId: any) => (dispatch: Function) => {
  dispatch({
    type: actionTypes.UPDATE_ATTEMPTED_TEST_IDS,
    payload: {
      testId,
    },
  });
};

export const getAllBadgesAwarded = () => async (dispatch: Function, getState: Function) => {
  const externalId = getState().auth?.learner.id;
  const gradeId = getState().auth?.learner.grade.id;
  try {
    const res = await firebase
      .firestore()
      .collection(badgesCollectionName)
      .where('externalId', '==', externalId)
      .where('gradeId', '==', gradeId)
      .get();
    if (!res.empty) {
      const badgesAwarded = res.docs.map(value => {
        return {
          ...value.data(),
        };
      });
      dispatch({
        type: actionTypes.GET_ALL_AWARDED_BADGES,
        payload: badgesAwarded,
      });
    }
  } catch (error) {
    captureException(error);
  }
};

const checkAndGetBadge = async (
  badgeKey: any,
  subjectId: any,
  gradeId: any,
  BADGES: Array<any>,
  badgesAwarded: Array<any>
) => {
  let badgeWithBadgeKeyAndGradeId = BADGES.filter((badge, _) => {
    if (badge.key === badgeKey) {
      if (subjectId) return badge.subject_id === subjectId && badge.grades.includes(gradeId);
      return badge.grades.includes(gradeId);
    }
    return false;
  });
  if (badgeWithBadgeKeyAndGradeId.length === 0) return null;
  const badgesServedBadgeIds = [...new Set(badgesAwarded.map((val: any) => val.badgeId))];
  badgeWithBadgeKeyAndGradeId = badgeWithBadgeKeyAndGradeId.filter(({ id }) => {
    return badgesServedBadgeIds.indexOf(id) === -1;
  });
  return badgeWithBadgeKeyAndGradeId[0];
};

const getCompleteQuest = async (subjectId: string | number, externalId: number, gradeId?: any) => {
  try {
    const query = gradeId
      ? firebase
          .firestore()
          .collection(questsCollectionName)
          .where('gradeId', '==', gradeId)
          .where('externalId', '==', externalId)
          .where('subjectId', '==', subjectId)
          .where('isComplete', '==', true)
      : firebase
          .firestore()
          .collection(questsCollectionName)
          .where('externalId', '==', externalId)
          .where('subjectId', '==', subjectId)
          .where('isComplete', '==', true);
    const res = await query.get();
    if (!res.empty) {
      return res.docs.length;
    } else {
      return 0;
    }
  } catch (error) {
    captureException(error);
  }
};

const getCompleteTest = async (externalId: number, gradeId: number) => {
  const res = await firebase
    .firestore()
    .collection(testsCollectionName)
    .where('gradeId', '==', gradeId)
    .where('externalId', '==', externalId)
    .get();
  if (!res.empty) {
    return res.docs.length;
  } else {
    return 0;
  }
};

const saveBadge = async (object: any, dispatch: Function) => {
  await firebase
    .firestore()
    .collection(badgesCollectionName)
    .add({
      ...object,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

  const arr = [
    {
      id: object.badgeId,
      awarded_at: new Date(),
      grade_id: object.gradeId,
      uuid: uuid(),
      entity_type: object.entity_type,
      entity_data: object.entity_data,
    },
  ];

  await dispatch(saveBadgeBackend(arr));
};

export const checkAndInsertBadge = ({
  subjectId,
  isFromTest,
  isFromLesson,
  isPerfectScore,
}: {
  subjectId: string | number;
  isFromTest: boolean;
  isFromLesson: boolean;
  isPerfectScore: any;
}) => async (dispatch: Function, getState: Function) => {
  const externalId = getState().auth?.learner.id;
  const gradeId = getState().auth?.learner.grade.id;
  const lessonsWatched = getState().video.lessonsWatched;
  const badgesAwarded = getState().video.badgesAwarded || [];
  const badges = getState().main.badges;
  const TOTAL_QUESTS = getState().home.totalQuestCount;
  const TOTAL_TESTS = getState().home.totalTestCount;
  let entity_type: BadgeSessionEntityType;

  enum BadgeSessionEntityType {
    practice_test = 'PracticeTest',
    quest = 'Quest',
    test = 'Test',
    subject = 'Subject',
    lesson = 'Lesson',
  }

  const config = {
    externalId,
    gradeId,
    subjectId,
  };
  let badge = null;
  let badgesToBeAwarded = [];

  if (isFromLesson) {
    const lessonWithoutSubject =
      lessonsWatched && lessonsWatched.length > 0
        ? lessonsWatched.filter((lessons: any) => lessons.gradeId === gradeId)
        : [];
    let completedLessonWithoutSubject = lessonWithoutSubject.length;
    if (completedLessonWithoutSubject === 1) {
      badge = await checkAndGetBadge(
        BADGE_KEY_MAP.COMPLETED_FIRST_LESSON_WITHOUT_SUBJECT,
        null,
        gradeId,
        badges,
        badgesAwarded
      );
      if (badge) {
        entity_type = BadgeSessionEntityType.lesson;
        badgesToBeAwarded.push(badge);
        await saveBadge(
          { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
          dispatch
        );
      }
    }
    const lessonWithSubject =
      lessonsWatched && lessonsWatched.length > 0
        ? lessonsWatched.filter(
            (lessons: any) => lessons.gradeId === gradeId && lessons.subjectId === subjectId
          )
        : [];
    let completedLessonWithSubject = lessonWithSubject.length;
    if (completedLessonWithSubject === 1) {
      badge = await checkAndGetBadge(
        BADGE_KEY_MAP.COMPLETED_FIRST_LESSON_WITH_SUBJECT,
        subjectId,
        gradeId,
        badges,
        badgesAwarded
      );
      if (badge) {
        entity_type = BadgeSessionEntityType.lesson;
        badgesToBeAwarded.push(badge);
        await saveBadge(
          { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
          dispatch
        );
      }
    }
    let questsCompleted = await getCompleteQuest(subjectId, externalId, null);
    switch (questsCompleted) {
      case 1:
        badge = await checkAndGetBadge(
          BADGE_KEY_MAP.COMPLETED_1_QUEST_EN,
          subjectId,
          gradeId,
          badges,
          badgesAwarded
        );
        break;
      case 3:
        badge = await checkAndGetBadge(
          BADGE_KEY_MAP.COMPLETED_3_QUEST_EN,
          subjectId,
          gradeId,
          badges,
          badgesAwarded
        );
        break;
      case 5:
        badge = await checkAndGetBadge(
          BADGE_KEY_MAP.COMPLETED_5_QUEST_EN,
          subjectId,
          gradeId,
          badges,
          badgesAwarded
        );
        break;
      case 10:
        badge = await checkAndGetBadge(
          BADGE_KEY_MAP.COMPLETED_10_QUEST_EN,
          subjectId,
          gradeId,
          badges,
          badgesAwarded
        );
        break;
      case 25:
        badge = await checkAndGetBadge(
          BADGE_KEY_MAP.COMPLETED_25_QUEST_EN,
          subjectId,
          gradeId,
          badges,
          badgesAwarded
        );
        break;
      default:
        badge = null;
    }
    if (badge) {
      entity_type = BadgeSessionEntityType.quest;
      badgesToBeAwarded.push(badge);
      await saveBadge(
        { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
        dispatch
      );
    }

    if (questsCompleted === TOTAL_QUESTS) {
      badge = await checkAndGetBadge(
        BADGE_KEY_MAP.COMPLETED_ALL_QUEST_EN,
        subjectId,
        gradeId,
        badges,
        badgesAwarded
      );

      if (badge) {
        entity_type = BadgeSessionEntityType.quest;
        badgesToBeAwarded.push(badge);
        await saveBadge(
          { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
          dispatch
        );
      }
    }

    questsCompleted = await getCompleteQuest(subjectId, externalId, gradeId);
    if (questsCompleted === TOTAL_QUESTS) {
      badge = await checkAndGetBadge(
        BADGE_KEY_MAP.COMPLETED_SS_1,
        subjectId,
        gradeId,
        badges,
        badgesAwarded
      );
      if (badge) {
        entity_type = BadgeSessionEntityType.subject;
        badgesToBeAwarded.push(badge);
        await saveBadge(
          { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
          dispatch
        );
      }
      badge = await checkAndGetBadge(
        BADGE_KEY_MAP.COMPLETED_SS_2,
        subjectId,
        gradeId,
        badges,
        badgesAwarded
      );
      if (badge) {
        entity_type = BadgeSessionEntityType.subject;
        badgesToBeAwarded.push(badge);
        await saveBadge(
          { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
          dispatch
        );
      }
      badge = await checkAndGetBadge(
        BADGE_KEY_MAP.COMPLETED_SS_3,
        subjectId,
        gradeId,
        badges,
        badgesAwarded
      );
      if (badge) {
        entity_type = BadgeSessionEntityType.subject;
        badgesToBeAwarded.push(badge);
        await saveBadge(
          { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
          dispatch
        );
      }
      badge = await checkAndGetBadge(
        BADGE_KEY_MAP.COMPLETED_JSS_1,
        subjectId,
        gradeId,
        badges,
        badgesAwarded
      );
      if (badge) {
        entity_type = BadgeSessionEntityType.subject;
        badgesToBeAwarded.push(badge);
        await saveBadge(
          { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
          dispatch
        );
      }
      badge = await checkAndGetBadge(
        BADGE_KEY_MAP.COMPLETED_JSS_2,
        subjectId,
        gradeId,
        badges,
        badgesAwarded
      );
      if (badge) {
        entity_type = BadgeSessionEntityType.subject;
        badgesToBeAwarded.push(badge);
        await saveBadge(
          { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
          dispatch
        );
      }
      badge = await checkAndGetBadge(
        BADGE_KEY_MAP.COMPLETED_JSS_3,
        subjectId,
        gradeId,
        badges,
        badgesAwarded
      );
      if (badge) {
        entity_type = BadgeSessionEntityType.subject;
        badgesToBeAwarded.push(badge);
        await saveBadge(
          { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
          dispatch
        );
      }
    }
  } else if (isFromTest) {
    if (isPerfectScore) {
      badge = await checkAndGetBadge(
        BADGE_KEY_MAP.PERFECT_SCORE_PRACTICE_WITH_SUBJECT,
        subjectId,
        gradeId,
        badges,
        badgesAwarded
      );
      if (badge) {
        entity_type = BadgeSessionEntityType.test;
        badgesToBeAwarded.push(badge);
        await saveBadge(
          { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
          dispatch
        );
      }
    }

    let testsCompleted = await getCompleteTest(externalId, gradeId);

    switch (testsCompleted) {
      case 1:
        badge = await checkAndGetBadge(
          BADGE_KEY_MAP.COMPLETED_1_PRACTICE_TEST_ANY_SUBJECT,
          null,
          gradeId,
          badges,
          badgesAwarded
        );
        break;
      case 3:
        badge = await checkAndGetBadge(
          BADGE_KEY_MAP.COMPLETED_3_PRACTICE_TEST_ANY_SUBJECT,
          null,
          gradeId,
          badges,
          badgesAwarded
        );
        break;
      case 5:
        badge = await checkAndGetBadge(
          BADGE_KEY_MAP.COMPLETED_5_PRACTICE_TEST_ANY_SUBJECT,
          null,
          gradeId,
          badges,
          badgesAwarded
        );
        break;
      case 10:
        badge = await checkAndGetBadge(
          BADGE_KEY_MAP.COMPLETED_10_PRACTICE_TEST_ANY_SUBJECT,
          null,
          gradeId,
          badges,
          badgesAwarded
        );
        break;
      case 25:
        badge = await checkAndGetBadge(
          BADGE_KEY_MAP.COMPLETED_25_PRACTICE_TEST_ANY_SUBJECT,
          null,
          gradeId,
          badges,
          badgesAwarded
        );
        break;
      default:
        badge = null;
    }

    if (badge) {
      entity_type = BadgeSessionEntityType.practice_test;
      badgesToBeAwarded.push(badge);
      await saveBadge(
        { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
        dispatch
      );
    }

    if (testsCompleted === TOTAL_TESTS) {
      badge = await checkAndGetBadge(
        BADGE_KEY_MAP.COMPLETED_ALL_PRACTICE_TEST_ANY_SUBJECT,
        0,
        gradeId,
        badges,
        badgesAwarded
      );
      if (badge) {
        entity_type = BadgeSessionEntityType.practice_test;
        badgesToBeAwarded.push(badge);
        await saveBadge(
          { ...config, entity_type, entity_data: badge.grades, badgeId: badge.id },
          dispatch
        );
      }
    }
  }
  return badgesToBeAwarded;
};

export const setSelectedAnalysisSubjectId = (subjectId: number | null) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch({ type: actionTypes.SET_SELECTED_ANALYSIS_SUBJECT_ID, payload: subjectId });
  return subjectId;
};

export const setSelectedAnalysis = (subjectId: number | null) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch({ type: actionTypes.SET_SELECTED_ANALYSIS, payload: subjectId });
  return subjectId;
};

export const setProgressAnalysis = (subjectId: number | null) => async (
  dispatch: Function,
  getState: () => RootState
) => {
  const { theme } = getState().content;
  try {
    if (subjectId) {
      const subjects = getState().home.subjects;
      const subject = subjects.find((item: any) => item.subjectId === subjectId);
      const subjectDisplayName = subject ? subject.displayName : 'Subject';
      const collection = [];
      const charData = [];
      const lessonsWatched = getState().video.lessonsWatched.filter(
        (val: any) => val.subjectId === subjectId
      );
      const lessonsTotal = getState().home.lessonsTotal.filter(
        (val: any) => val.subjectId === subjectId
      );
      const learnedTime = lessonsWatched.reduce((a: any, b: any) => a + (b.timeSpent || 0), 0);
      const learnedTimeInMins =
        learnedTime > 60 ? parseInt((learnedTime / 60 + 0.5).toFixed(1)) : 1;
      const ensureArray = [0, 0.1, 0.5, 0.85, 1];
      const theme_key = subject ? subject.subject_theme.key : 'english_english';
      const limit = 5;
      const yLimit = learnedTime > 360 ? parseInt((learnedTime / 60 + 1).toFixed(0)) : 360;
      const timeArray = timeIntervals(learnedTime, limit);
      const defaultStartDate = moment(new Date())
        .clone()
        .startOf('day')
        .subtract(4, 'days')
        .toDate();
      const defaultEndDate = moment(new Date()).clone().startOf('day').toDate();
      const xbreakPoints = dateIntervals(defaultStartDate, defaultEndDate, limit);
      const ybreakPoints = timeIntervals(yLimit, limit);
      const dateToUse =
        lessonsWatched &&
        lessonsWatched.length > 0 &&
        lessonsWatched[lessonsWatched.length - 1].updatedAt &&
        lessonsWatched[lessonsWatched.length - 1].updatedAt.seconds
          ? new Date(lessonsWatched[lessonsWatched.length - 1].updatedAt.seconds * 1000)
          : lessonsWatched &&
            lessonsWatched.length > 0 &&
            lessonsWatched[lessonsWatched.length - 1].updatedAt
          ? new Date(lessonsWatched[lessonsWatched.length - 1].updatedAt)
          : new Date();
      const startMoment = moment(dateToUse)
        .clone()
        .startOf('day')
        .utc()
        .subtract(limit - 1, 'days')
        .toDate();
      const endMoment = moment(dateToUse).clone().startOf('day').toDate();
      const dateMoment = dateIntervals(startMoment, endMoment, limit);
      for (let i = 0; i < limit; i++) {
        if (lessonsWatched && lessonsWatched.length > 0) {
          collection.push({
            x: dateMoment[i],
            y: timeArray[i],
          });
          charData.push({
            x: dateMoment[i],
            y: learnedTimeInMins * ensureArray[i],
          });
        } else {
          collection.push({
            x: xbreakPoints[i],
            y: ybreakPoints[i],
          });
        }
      }
      const progressAnalysis = {
        lessonNo: lessonsWatched.length,
        total: lessonsTotal.length,
        learnedTime: learnedTime,
        subjectDisplayName: subjectDisplayName,
        subjectId,
        collection,
        theme: theme[theme_key],
        dateAvailable: lessonsWatched && lessonsWatched.length > 0 ? true : false,
        charData: charData,
      };
      dispatch({ type: actionTypes.SET_PROGRESS_ANALYSIS, payload: progressAnalysis });
    } else {
      const collection = [];
      const charData = [];
      const lessonsWatched = getState().video.lessonsWatched;
      const lessonsTotal = getState().home.lessonsTotal;
      const learnedTime = lessonsWatched.reduce((a: any, b: any) => a + (b.timeSpent || 0), 0);
      const subjectDisplayName = 'All Subjects';
      const theme = getState().theme;
      const learnedTimeInMins =
        learnedTime > 60 ? parseInt((learnedTime / 60 + 0.5).toFixed(1)) : 1;
      const ensureArray = [0, 0.1, 0.5, 0.85, 1];
      const limit = 5;
      const yLimit = learnedTime > 360 ? parseInt((learnedTime / 60).toFixed(0)) : 360;
      const timeArray = timeIntervals(learnedTime, limit);
      const defaultStartDate = moment(new Date())
        .clone()
        .startOf('day')
        .subtract(4, 'days')
        .toDate();
      const defaultEndDate = moment(new Date()).clone().startOf('day').toDate();
      const xbreakPoints = dateIntervals(defaultStartDate, defaultEndDate, limit);
      const ybreakPoints = timeIntervals(yLimit, limit);
      const dateToUse =
        lessonsWatched &&
        lessonsWatched.length > 0 &&
        lessonsWatched[lessonsWatched.length - 1].updatedAt &&
        lessonsWatched[lessonsWatched.length - 1].updatedAt.seconds
          ? new Date(lessonsWatched[lessonsWatched.length - 1].updatedAt.seconds * 1000)
          : lessonsWatched &&
            lessonsWatched.length > 0 &&
            lessonsWatched[lessonsWatched.length - 1].updatedAt
          ? new Date(lessonsWatched[lessonsWatched.length - 1].updatedAt)
          : new Date();
      const startMoment = moment(dateToUse)
        .clone()
        .startOf('day')
        .utc()
        .subtract(limit - 1, 'days')
        .toDate();
      const endMoment = moment(dateToUse).clone().startOf('day').toDate();
      const dateMoment = dateIntervals(startMoment, endMoment, limit);
      for (let i = 0; i < limit; i++) {
        if (lessonsWatched && lessonsWatched.length > 0) {
          collection.push({
            x: dateMoment[i],
            y: timeArray[i],
          });
          charData.push({
            x: dateMoment[i],
            y: learnedTimeInMins * ensureArray[i],
          });
        } else {
          collection.push({
            x: xbreakPoints[i],
            y: ybreakPoints[i],
          });
        }
      }
      const progressAnalysis = {
        lessonNo: lessonsWatched.length,
        total: lessonsTotal.length,
        learnedTime: learnedTime,
        subjectDisplayName: subjectDisplayName,
        subjectId,
        collection,
        theme,
        dateAvailable: lessonsWatched && lessonsWatched.length > 0 ? true : false,
        charData: charData,
      };
      dispatch({ type: actionTypes.SET_PROGRESS_ANALYSIS, payload: progressAnalysis });
    }
  } catch (error) {
    captureException(error);
  }
};

export const getAllTests = () => async (dispatch: Function, getState: Function) => {
  const externalId = getState().auth?.learner.id;
  const gradeId = getState().auth?.learner.grade.id;
  const res = await firebase
    .firestore()
    .collection(testsCollectionName)
    .where('externalId', '==', externalId)
    .where('gradeId', '==', gradeId)
    .get();
  const result = !res.empty
    ? res.docs.map((val: any) => {
        return {
          ...val.data(),
        };
      })
    : [];
  dispatch({
    type: actionTypes.GET_ALL_TESTS,
    payload: result,
  });
};

export const setPerformanceAnalysis = (subjectId: number | null) => (
  dispatch: Function,
  getState: () => RootState
) => {
  const Theme = getState().content.theme;
  const allTests: any = [];
  getState().video.allTests.forEach((val: any) => {
    allTests.push(...val.tests);
  });
  const testsDone = getState().video.allTests;
  const totalTestCount = getState().home.totalTestCount;
  const theme = getState().theme;
  const subjects = getState().home.subjects;
  const subjectsWithPerformance = subjects.map((each: any) => {
    const subjectAllTests = allTests.filter((item: any) => item.subjectId === each.subjectId);
    const questionsCorrect = subjectAllTests.filter((item: any) => item.accuracy === 1);
    const easyQuestions = subjectAllTests.filter((item: any) => item.difficulty === 'Easy');
    const mediumQuestions = subjectAllTests.filter(
      (item: any) => item.difficulty === 'Intermediate'
    );
    const hardQuestions = subjectAllTests.filter((item: any) => item.difficulty === 'Hard');
    const correctEasy = easyQuestions.filter((item: any) => item.accuracy === 1);
    const correctMedium = mediumQuestions.filter((item: any) => item.accuracy === 1);
    const correctHard = hardQuestions.filter((item: any) => item.accuracy === 1);
    const easyPercent =
      (correctEasy.length / easyQuestions.length) * 100
        ? (correctEasy.length / easyQuestions.length) * 100
        : 0;
    const mediumPercent =
      (correctMedium.length / mediumQuestions.length) * 100
        ? (correctMedium.length / mediumQuestions.length) * 100
        : 0;
    const hardPercent =
      (correctHard.length / hardQuestions.length) * 100
        ? (correctHard.length / hardQuestions.length) * 100
        : 0;
    const averagePerformance = (questionsCorrect.length / subjectAllTests.length) * 100;
    return {
      averagePerformance: averagePerformance ? Math.round(averagePerformance) : 0,
      easyPercent: Math.round(easyPercent),
      mediumPercent: Math.round(mediumPercent),
      hardPercent: Math.round(hardPercent),
      ...each,
    };
  });

  if (subjectId) {
    const subjectAllTests = allTests.filter((item: any) => item.subjectId === subjectId);
    const subjectTestsDone = testsDone.filter((item: any) => item.subjectId === subjectId);
    const subject = subjects.find((item: any) => item.subjectId === subjectId);
    const subjectDisplayName = subject ? subject.displayName : '';
    const theme_key = subject ? subject.subject_theme.key : 'english_english';
    const testCount = subject ? subject.testCount : 0;
    const questionsCorrect = subjectAllTests.filter((item: any) => item.accuracy === 1);
    const testsAttempted = subjectTestsDone.length;
    const learnedTime = subjectAllTests.reduce((a: any, b: any) => a + (b.timeSpent || 0), 0);
    const averageTime = learnedTime / subjectAllTests.length;
    const perf = subjectsWithPerformance.find((e: any) => e.subjectId === subjectId);
    const subjectAveragePerformance = (questionsCorrect.length / subjectAllTests.length) * 100;

    const performanceAnalysis = {
      questionsCorrect: questionsCorrect.length,
      testsAttempted: testsAttempted >= testCount ? testCount : testsAttempted,
      averageTime: averageTime ? averageTime : 0,
      subjectDisplayName: subjectDisplayName,
      theme: Theme[theme_key ? theme_key : 'default'],
      testCount: testCount,
      averagePerformance: subjectAveragePerformance ? Math.round(subjectAveragePerformance) : 0,
      easyPercent: perf ? Math.round(perf.easyPercent) : 0,
      mediumPercent: perf ? Math.round(perf.mediumPercent) : 0,
      hardPercent: perf ? Math.round(perf.hardPercent) : 0,
      subjectsWithPerformance: subjectsWithPerformance,
    };
    dispatch({ type: actionTypes.SET_PERFORMANCE_ANALYSIS, payload: performanceAnalysis });
  } else {
    const questionsCorrect = allTests.filter((item: any) => item.accuracy === 1);
    const testsAttempted = testsDone.length;
    let divisibleNull = 0;
    let totalPerfNull = 0;
    const learnedTime = allTests.reduce((a: any, b: any) => a + (b.timeSpent || 0), 0);
    const averageTime = learnedTime / allTests.length;

    subjectsWithPerformance.forEach((value: any) => {
      if (value.averagePerformance > 0) {
        divisibleNull += 1;
      }
      totalPerfNull += value.averagePerformance;
    });
    const totalAveragePerformance = totalPerfNull / divisibleNull;
    const performanceAnalysis = {
      questionsCorrect: questionsCorrect.length,
      testsAttempted: testsAttempted >= totalTestCount ? totalTestCount : testsAttempted,
      averageTime: averageTime ? averageTime : 0,
      subjectDisplayName: 'All Subjects',
      theme: theme,
      testCount: totalTestCount,
      subjectsWithPerformance: subjectsWithPerformance,
      totalAveragePerformance: totalAveragePerformance ? Math.round(totalAveragePerformance) : 0,
    };
    dispatch({ type: actionTypes.SET_PERFORMANCE_ANALYSIS, payload: performanceAnalysis });
  }
};

export const setAchievementAnalysis = (subjectId: number | null) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch({ type: actionTypes.SET_SELECTED_ANALYSIS, payload: subjectId });
  return subjectId;
};

export const lessonPass = (
  lesson_id: number,
  history?: any,
  skipToggleDispatch?: boolean,
  chapterTab?: number
): ThunkResult<any> => async (
  dispatch: Function,
  getState: () => RootState
): Promise<reduxResponse> => {
  try {
    const checkLessonPass = await axios.post(`learners/lesson/passes`, { lesson_id });
    const { status, message, authorize_play, passes } = checkLessonPass.data.data;

    if (authorize_play) {
      dispatch({ type: actionTypes.LESSON_PASSES, payload: { authorize_play, passes } });
      if (history) {
        history.push({
          pathname: urls.VideoPageUrl,
          state: {
            chapterTab: chapterTab ?? 0,
          },
        });
      }
    } else {
      trackVideoCapReached();

      if (!skipToggleDispatch) {
        dispatch({ type: actionTypes.TOGGLE_LESSON_PASSES, payload: true });
      }
    }

    return { status, message, data: { authorize_play, passes } };
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const toggleLimitedPass = (limit: boolean): ThunkResult<void> => (
  dispatch: Function,
  getState: () => RootState
): void => {
  dispatch({ type: actionTypes.TOGGLE_LESSON_PASSES, payload: limit });
};

export const saveLessonsBackend = (payload: Array<any>) => async (
  dispatch: Function,
  getState: () => RootState
) => {
  try {
    const response = await axios.post(`progress/lessons`, { lessons: payload });
    logMessage('saveLessonsBackend', response);
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const saveTestsBackend = (payload: Array<any>) => async (
  dispatch: Function,
  getState: () => RootState
) => {
  try {
    const response = await axios.post(`progress/tests`, { tests: payload });
    logMessage('saveTestsBackend', response);
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const saveExamsBackend = (payload: Array<any>) => async (
  dispatch: Function,
  getState: () => RootState
) => {
  try {
    const response = await axios.post(`progress/exams`, { exams: payload });
    logMessage('saveExamsBackend', response);
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};

export const saveBadgeBackend = (payload: Array<any>) => async (
  dispatch: Function,
  getState: () => RootState
) => {
  try {
    const response = await axios.post(`progress/badges`, { badges: payload });
    logMessage('saveBadgesBackend', response);
  } catch (error) {
    captureException(error);
    const { status, message } = catchHelper(error, dispatch);
    return { status, message };
  }
};
