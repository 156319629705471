export const HOME_INIT = 'HOME_INIT';
export const HOME_FAIL = 'HOME_FAIL';
export const GRADE_CONTENT = 'GRADE_CONTENT';
export const GRADE_BY_COUNTRY = 'GRADE_BY_COUNTRY';
export const ERROR_CONTENT = 'ERROR_CONTENT';
export const CLOSE_ERROR = 'CLOSE_ERROR';
export const LOG_OUT = 'LOG_OUT';
export const RETRY_CONTENT = 'RETRY_CONTENT';
export const SELECT_SUBJECT = 'SELECT_SUBJECT';
export const SELECT_EXAM_SUBJECT = 'SELECT_EXAM_SUBJECT';
export const CLOSE_SELECT_EXAM = 'CLOSE_SELECT_EXAM';
export const SELECT_EXAMS = 'SELECT_EXAMS';
export const SELECT_TESTS = 'SELECT_TESTS';
export const SAVE_LESSONS = 'SAVE_LESSONS';
export const SAVE_SEARCH_INDEX = 'SAVE_SEARCH_INDEX';
export const SAVE_SEARCH_HISTORY = 'SAVE_SEARCH_HISTORY';
export const UPDATE_ASSET_LOAD = 'UPDATE_ASSET_LOAD';
export const SET_DONGLE_MODAL = 'SET_DONGLE_MODAL';
export const SELECT_CHAPTER_TESTS = 'SELECT_CHAPTER_TESTS';
export const UPDATE_ATTEMPTED_TEST_IDS = 'UPDATE_ATTEMPTED_TEST_IDS';
export const SET_TEST_AND_QUEST_COUNT = 'SET_TEST_AND_QUEST_COUNT';
export const SELECT_GRADE = 'SELECT_GRADE';
export const GET_DASHBOARD_BANNERS = 'GET_DASHBOARD_BANNERS';
export const GET_MOCK_EXAMS = 'GET_MOCK_EXAMS';
export const GET_ALL_EXAMS = 'GET_ALL_EXAMS';
export const GET_AVATARS = 'GET_AVATARS';
