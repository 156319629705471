import React, { FunctionComponent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import GoBackIcon from 'Components/Icons/General/Goback';

interface PageHeaderProps extends RouteComponentProps {
  heading: string;
  back?: () => void;
  style?: React.CSSProperties;
  right?: React.ReactChild;
}

const PageHeader = ({ heading, back, style, history, right }: PageHeaderProps) => {
  return (
    <div className="PageHeader">
      <div className="PageHeader -body">
        <GoBackIcon onClick={back ? back : () => history.goBack()} />
        <div className="PageHeader -heading" style={style}>
          {heading}
        </div>
        {right ? <div className="right">{right}</div> : null}
      </div>
    </div>
  );
};

export default withRouter<PageHeaderProps, FunctionComponent<PageHeaderProps>>(PageHeader);
