export const BADGE_KEY_MAP = {
  COMPLETED_SS_1: 'completed_ss_1__EN',
  COMPLETED_SS_2: 'completed_ss_2__EN',
  COMPLETED_SS_3: 'completed_ss_3__EN',
  COMPLETED_JSS_1: 'completed_grade_7__EN',
  COMPLETED_JSS_2: 'completed_grade_8__EN',
  COMPLETED_JSS_3: 'completed_grade_9__EN',
  COMPLETED_1_QUEST_EN: 'completed_1_quest_EN',
  COMPLETED_3_QUEST_EN: 'completed_3_quests_EN',
  COMPLETED_5_QUEST_EN: 'completed_5_quests_EN',
  COMPLETED_10_QUEST_EN: 'completed_10_quests_EN',
  COMPLETED_25_QUEST_EN: 'completed_25_quests_EN',
  COMPLETED_ALL_QUEST_EN: 'completed_all_quests_in_that_subject_EN',
  COMPLETED_FIRST_LESSON_WITH_SUBJECT: 'completed_1st_lesson_in__EN',
  COMPLETED_FIRST_LESSON_WITHOUT_SUBJECT: 'completed_first_lesson_EN',
  PERFECT_SCORE_PRACTICE_WITH_SUBJECT: 'perfect_score_in_a_practice_test_EN',
  COMPLETED_1_PRACTICE_TEST_ANY_SUBJECT: 'completed_1_practice_test__any_subject_EN',
  COMPLETED_3_PRACTICE_TEST_ANY_SUBJECT: 'completed_3_practice_tests__any_subject_EN',
  COMPLETED_5_PRACTICE_TEST_ANY_SUBJECT: 'completed_5_practice_tests__any_subject_EN',
  COMPLETED_10_PRACTICE_TEST_ANY_SUBJECT: 'completed_10_practice_tests__any_subject_EN',
  COMPLETED_25_PRACTICE_TEST_ANY_SUBJECT: 'completed_25_practice_tests__any_subject_EN',
  COMPLETED_ALL_PRACTICE_TEST_ANY_SUBJECT: 'completed_all_practice_tests__any_subject_EN',
};
