import { enableTrackingLibraries } from 'Utils/constants';
import { TRACKING_LESSON_PROGRESS, TRACKING_LESSON_START } from './constants';
import { trackFirebaseEvent } from './firebase';
import { trackReactPixelEvent } from './reactPixel';
import { TrackLessonPlayTypes, TrackLessonTypes } from './types';

export const trackLessonStart = (eventParams: TrackLessonPlayTypes): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_LESSON_START, eventParams);
    trackReactPixelEvent(TRACKING_LESSON_START, eventParams);
  }
};

export const trackLessonStarted = (eventParams: TrackLessonTypes): void => {};

export const trackLessonProgess = (eventParams: TrackLessonPlayTypes): void => {
  if (enableTrackingLibraries) {
    trackFirebaseEvent(TRACKING_LESSON_PROGRESS, eventParams);
    trackReactPixelEvent(TRACKING_LESSON_PROGRESS, eventParams);
  }
};

export const trackLessonUnfinished = (eventParams: TrackLessonTypes): void => {};
