import generateUniqueId from 'generate-unique-id';

export const generateCorrelationId = () => {
  const id = generateUniqueId({
    length: 36,
    useLetters: true,
    useNumbers: true,
    includeSymbols: ['-'],
  });
  return id;
};
